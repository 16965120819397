/* es-lint-disable */

import { FC, useState } from "react";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Typography } from "@mui/material";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  TimelineItem,
  TimelineItemEntityType,
  TimelineItemLike,
  TimelineItemType,
  useLikeTimelineItemMutation,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { addTimezoneToUtcDate } from "shared/helpers";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { WrappedPreview } from "pages/Wrapped/WrappedPreview";
import { TimelineViewItemIcon } from "./TimelineViewIcon";
import { TimelineViewReplyContainer } from "./TimelineViewReplyContainer";
import { TimelineMedia } from "./TimelineMedia";
import { TimelineWorkoutPreview } from "./AdminTimeline/TimelineWorkoutPreview";
import { getTimelineLikeStatement } from "./utils";

// Define the props interface for TimelineViewItemCard
interface TimelineViewItemCardProps {
  timelineItem: TimelineItem;
  isClientView?: boolean;
  onReply: (timelineItem: TimelineItem) => void;
  timelineUserId: number;
}

const TimelineViewItemCard: FC<TimelineViewItemCardProps> = ({
  timelineItem,
  isClientView = false,
  onReply,
  timelineUserId,
}) => {
  const userId = useTypedSelector(userSelectors.userId);
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  dayjs.extend(relativeTime);

  const [showWorkoutPreview, setShowWorkoutPreview] = useState(false);
  const [createTimelineItemLike] = useLikeTimelineItemMutation();
  const [optimisticLike, setOptimisticLike] = useState<boolean | undefined>(
    undefined
  );

  const serverLikedStatus = timelineItem.timelineItemLikes?.some(
    (like: TimelineItemLike) => like.userId === userId
  );

  // If we have an optimistic update, use it, otherwise use server state
  const isLiked =
    optimisticLike !== undefined ? optimisticLike : serverLikedStatus;

  const onClickTogglePreview = () => {
    setShowWorkoutPreview((prev) => !prev);
  };

  const onLike = async () => {
    // Toggle based on current like status
    setOptimisticLike(!isLiked);

    try {
      await createTimelineItemLike({
        timelineItemId: timelineItem.id,
      }).unwrap();
    } catch (error: any) {
      // Revert to previous state on error
      setOptimisticLike(isLiked);
      console.error("Error liking timeline item", error);
    }
  };

  const sanitisedTimelineItemContent = DOMPurify.sanitize(timelineItem.content);

  // Create optimistic likes array for the like statement
  const optimisticTimelineItemLikes = [
    ...(timelineItem.timelineItemLikes || []),
  ];

  if (isLiked && !serverLikedStatus) {
    optimisticTimelineItemLikes.push({ userId: userId!! } as TimelineItemLike);
  } else if (!isLiked && serverLikedStatus) {
    const index = optimisticTimelineItemLikes.findIndex(
      (like) => like.userId === userId
    );
    if (index !== -1) {
      optimisticTimelineItemLikes.splice(index, 1);
    }
  }

  // Determine if this is a wrapped timeline item
  const isWrapped = timelineItem.timelineItemType === "Wrapped";

  return (
    <div style={{ display: "flex" }}>
      <Box
        sx={
          isClientView
            ? sharedStyles.containers.timelineCard.client
            : sharedStyles.containers.timelineCard.admin
        }
      >
        <TimelineViewItemIcon
          timelineItemType={timelineItem.timelineItemType}
          variant="primary"
        />
      </Box>
      <Card
        sx={{
          width: "100%",
          minHeight: "94px",
          position: "relative",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          boxShadow: "none",
          background: isClientView ? `${Colors.gray[200]}` : "white",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "&:last-child": {
              paddingBottom: 2,
            },
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                width: "90%",
              }}
            >
              <Typography sx={sharedStyles.body.timeline.cardTitle}>
                {timelineItem.title}
              </Typography>

              {(() => {
                if (isWrapped) {
                  return (
                    <>
                      <Typography sx={sharedStyles.body.timeline.cardBody}>
                        {timelineItem.content}
                      </Typography>
                      <WrappedPreview mediaUrl={timelineItem.mediaUrl} />
                    </>
                  );
                }
                if (timelineItem.timelineItemType === TimelineItemType.Media) {
                  return <TimelineMedia timelineItem={timelineItem} />;
                }
                return (
                  <Typography
                    sx={sharedStyles.body.timeline.cardBody}
                    dangerouslySetInnerHTML={{
                      __html: sanitisedTimelineItemContent,
                    }}
                  />
                );
              })()}
            </Box>
            {isAdminOrCoach && (
              <Typography sx={sharedStyles.body.timeline.cardBody}>
                {addTimezoneToUtcDate(timelineItem.dateTime).fromNow()}
              </Typography>
            )}
          </Box>
          {timelineItem?.timelineItemChildren && (
            <TimelineViewReplyContainer
              timelineItems={timelineItem?.timelineItemChildren}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: `${Colors.blue[1200]}`,
                  lineHeight: "20px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "2px",
                }}
                onClick={onLike}
              >
                <OutlinedIcon
                  iconName={isLiked ? "favorite" : "favorite_border"}
                  style={{
                    color: `${Colors.blue[1200]}`,
                    fontSize: "20px",
                  }}
                />{" "}
                Like
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: `${Colors.blue[1200]}`,
                  lineHeight: "20px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "2px",
                }}
                onClick={() => onReply(timelineItem)}
              >
                <OutlinedIcon
                  iconName="reply"
                  style={{
                    color: `${Colors.blue[1200]}`,
                    fontSize: "20px",
                  }}
                />{" "}
                Reply
              </Typography>
            </Box>
            {!isAdminOrCoach && (
              <Typography sx={sharedStyles.body.timeline.cardBody}>
                {addTimezoneToUtcDate(timelineItem.dateTime).fromNow()}
              </Typography>
            )}
            {isAdminOrCoach &&
              timelineItem.timelineItemType ===
                TimelineItemType.WorkoutProgramClientFeedback &&
              timelineItem.entityType !== TimelineItemEntityType.Workout && (
                <LoadingButton
                  type="button"
                  onClick={onClickTogglePreview}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  View Session
                </LoadingButton>
              )}
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              color: `${Colors.gray[700]}`,
              lineHeight: "20px",
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            {getTimelineLikeStatement(optimisticTimelineItemLikes, userId!!)}
          </Typography>
        </CardContent>

        {showWorkoutPreview && (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              "&:last-child": {
                paddingBottom: 2,
              },
              gap: 1,
            }}
          >
            <TimelineWorkoutPreview
              selectedUserId={timelineUserId}
              timelineItemId={timelineItem.id}
              showWorkoutPreview={showWorkoutPreview}
            />
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export { TimelineViewItemCard };
