import { Icon, Typography, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClientWhoRequireANewProgram } from "../../../../shared/api";
import { Routes } from "../../../../shared/routers";
import { Colors } from "../../../../shared/themes";
import { IdenticonAvatar } from "../../../../shared/ui/IdenticonAvatar";

interface RequiredProgramUserProps {
  item: ClientWhoRequireANewProgram;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const RequiredProgramUser: FC<RequiredProgramUserProps> = ({
  item,
  getUserIdForWorkoutCreation,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    getUserIdForWorkoutCreation(item.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          mb: 1,
          borderRadius: 1,
          "&:hover": {
            bgcolor: Colors.gray[50],
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <IdenticonAvatar
            seedValue={item.id.toString()}
            profileImageUrl={item.profileImageUrl}
          />
          <Box
            sx={{
              ml: 1,
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "14px",
                color: Colors.blue[1300],
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.firstName} {item.lastName}
            </Typography>
            {item.percentageCompleted !== null && (
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  color: Colors.gray[600],
                }}
              >
                {item.percentageCompleted}% Completed
              </Typography>
            )}
          </Box>
        </Box>

        <Icon
          onClick={handleClick}
          color="primary"
          sx={{
            cursor: "pointer",
            ml: 2,
          }}
        >
          control_point
        </Icon>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{
          mt: "10px",
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #EEEEEE",
          },
          "& .MuiList-root": { padding: "0px" },
        }}
      >
        <Link style={{ textDecoration: "none" }} to={`${Routes.programs.url}`}>
          <MenuItem
            onClick={handleCloseMenu}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              border: "1px solid #EEEEEE",
              color: Colors.blue[1300],
            }}
          >
            {t("your-schedule.create-new-program")}
          </MenuItem>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={`${Routes.profile.url}/${item.id}/2`}
        >
          <MenuItem
            onClick={handleCloseMenu}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              border: "1px solid #EEEEEE",
              color: Colors.blue[1300],
            }}
          >
            {t("your-schedule.view-profile")}
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export { RequiredProgramUser };
