import {
  Icon,
  IconButton,
  Box,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import Image from "mui-image";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { Colors, Images } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import {
  useGetSubscriptionStatusQuery,
  useCheckOrganisationHasSubscriptionQuery,
} from "shared/api";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { PricingPage } from "../PricingPage";

dayjs.extend(relativeTime);

interface HeaderProps {
  toggleSidebar?: () => void;
}

const Header: FC<HeaderProps> = ({ toggleSidebar }) => {
  const userId = useTypedSelector(userSelectors.userId);
  const userProfileImageUrl = useTypedSelector(
    userSelectors.userProfileImageUrl
  );
  const isAdmin = useTypedSelector(userSelectors.isAdmin);

  const { data: subscriptionStatus, isLoading: isSubscriptionStatusLoading } =
    useGetSubscriptionStatusQuery();

  const {
    data: organisationHasSubscription,
    isLoading: isOrganisationHasSubscriptionLoading,
  } = useCheckOrganisationHasSubscriptionQuery();

  if (isSubscriptionStatusLoading) {
    return null;
  }

  const dueDate = subscriptionStatus?.dueDate
    ? dayjs(subscriptionStatus.dueDate).format("MMMM D, YYYY")
    : null;

  const lockOutDate = subscriptionStatus?.lockOutDate
    ? dayjs(subscriptionStatus.lockOutDate).format("MMMM D, YYYY")
    : null;

  const daysUntilLockOut = subscriptionStatus?.lockOutDate
    ? dayjs(subscriptionStatus.lockOutDate).diff(dayjs(), "day")
    : null;

  return (
    <>
      {!organisationHasSubscription &&
        !isOrganisationHasSubscriptionLoading &&
        isAdmin && <PricingPage />}
      {subscriptionStatus?.overdue && !subscriptionStatus?.lockedOut && (
        <Alert
          severity="error"
          sx={{ position: "fixed", top: 0, width: "100%", zIndex: 10000 }}
          action={
            <Button
              color="inherit"
              size="small"
              href="https://billing.stripe.com/p/login/28obKofxOcorbwAcMM"
              target="_blank"
              rel="noopener noreferrer"
            >
              Update Payment
            </Button>
          }
        >
          Your subscription is overdue. Please update your payment information.
          {dueDate && (
            <Typography variant="body2">Due Date: {dueDate}</Typography>
          )}
          {daysUntilLockOut !== null && daysUntilLockOut > 0 && (
            <Typography variant="body2">
              Your account will be locked out in {daysUntilLockOut}{" "}
              {daysUntilLockOut === 1 ? "day" : "days"}.
            </Typography>
          )}
        </Alert>
      )}

      {subscriptionStatus?.overdue && subscriptionStatus?.lockedOut && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.8)",
            zIndex: 10000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            textAlign: "center",
            padding: 2,
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Your account is locked due to an overdue subscription. Please
              update your payment information.
              {lockOutDate && (
                <Typography variant="body2">
                  Lock Out Date: {lockOutDate}
                </Typography>
              )}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="https://billing.stripe.com/p/login/28obKofxOcorbwAcMM"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textTransform: "none" }}
            >
              Update Payment
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "calc(58px + env(safe-area-inset-top))",
          bgcolor: `${Colors.gray[100]}`,
          zIndex: 9999,
          position: "fixed",
          top:
            subscriptionStatus?.overdue && !subscriptionStatus?.lockedOut
              ? "40px"
              : "0px",
          left: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: `calc(8px + env(safe-area-inset-top)) 24px 8px 24px`,
          borderBottom: `1px solid ${Colors.gray[400]}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "58px",
          }}
        >
          <IconButton onClick={toggleSidebar}>
            <Icon
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              menu
            </Icon>
          </IconButton>

          <Image src={Images.Logo} duration={250} width="108px" height="34px" />

          <Link
            style={{ textDecoration: "none" }}
            to={`${Routes.profile.url}/${userId}`}
          >
            <IdenticonAvatar
              seedValue={userId?.toString() ?? ""}
              profileImageUrl={userProfileImageUrl}
            />
          </Link>
        </Box>
      </Box>
    </>
  );
};

export { Header };
