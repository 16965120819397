import { Icon, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse } from "../../../../../shared/api";
import { Colors } from "../../../../../shared/themes";
import { RequiredProgramUser } from "../RequiredProgramUser";

interface RequireNewProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  usersData: DashboardResponse;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const RequireNewProgramModal: FC<RequireNewProgramModalProps> = ({
  handleCloseModal,
  isOpenModal,
  usersData,
  getUserIdForWorkoutCreation,
}) => {
  const { t } = useTranslation();
  const { clientsWhoRequireANewProgram } = usersData;

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: { xs: "flex-end", md: "center" },
          justifyContent: "center",
          p: { xs: 0, md: 3 },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "450px" },
            maxHeight: { xs: "90vh", md: "80vh" },
            bgcolor: "background.paper",
            borderRadius: { xs: "16px 16px 0 0", md: "8px" },
            p: 0,
            position: "relative",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              p: "16px 24px",
              position: "sticky",
              top: 0,
              bgcolor: "background.paper",
              borderBottom: `1px solid ${Colors.gray[200]}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: { xs: "16px", md: "18px" },
                color: Colors.blue[1300],
              }}
            >
              {t("your-schedule.programs-needed")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{
                cursor: "pointer",
                color: Colors.gray[600],
              }}
            >
              close_icon
            </Icon>
          </Box>

          <Box sx={{ p: "16px 24px" }}>
            {clientsWhoRequireANewProgram.length !== 0 ? (
              clientsWhoRequireANewProgram.map((item) => (
                <RequiredProgramUser
                  key={item.id}
                  item={item}
                  getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                />
              ))
            ) : (
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: Colors.blue[1300],
                  textAlign: "center",
                  py: 2,
                }}
              >
                {t("your-schedule.users-don't-need-programs")}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { RequireNewProgramModal };
