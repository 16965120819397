import posthog from "posthog-js";
import Intercom from "@intercom/messenger-js-sdk";
import { isDev } from "shared/helpers";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { UserRole } from "shared/api";

export const useAnalytics = () => {
  const currentUser = useTypedSelector(userSelectors.all);

  const startSession = () => {
    if (!isDev) {
      posthog.identify(`${currentUser.id}`, {
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: `${currentUser.email}`,
      });

      if (currentUser.userRole !== UserRole.Athlete) {
        Intercom({
          app_id: process.env.REACT_APP_INTERCOM_APP_ID!,
          user_id: currentUser.id?.toString(),
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: `${currentUser.email}`,
        });
      }
    }
  };

  return {
    startSession,
  };
};
