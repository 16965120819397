import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface WhatIsReadinessCardProps {
  setDialogOpen: (open: boolean) => void;
}

export const WhatIsReadinessCard: React.FC<WhatIsReadinessCardProps> = ({
  setDialogOpen,
}) => {
  return (
    <Box
      sx={{
        mt: 3,
        p: 2,
        borderRadius: 2,
        backgroundColor: "#f8f9ff",
        border: "1px solid rgba(108, 92, 231, 0.1)",
        boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => setDialogOpen(true)}
    >
      <Box>
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          What is Readiness?
        </Typography>
        <Typography variant="body2">
          Discover the science behind Readiness and how it measures your
          performance.
        </Typography>
      </Box>
      <Box>
        <ArrowForwardIosIcon fontSize="small" />
      </Box>
    </Box>
  );
};
