/* eslint-disable no-nested-ternary */
import {
  Box,
  Typography,
  Grid,
  Button,
  Skeleton,
  Icon,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import WalletIcon from "@mui/icons-material/Wallet";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { setCurrentWorkout } from "shared/stores/workout";
import { userSelectors } from "shared/stores/user";
import { AnyType } from "shared/helpers";
import { AthletePoints } from "pages/Leaderboard/AthletePoints";
import {
  useGetMostRecentHealthSummaryForUserQuery,
  useGetCurrentActiveChallengeQuery,
  useGetWellbeingDashboardForUserQuery,
  WellbeingDashboardRequest,
  useGetUserOnboardingCompletedQuery,
} from "shared/api";
import { getGraphicSvg } from "shared/themes";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Routes } from "shared/routers";
import { useNavigate } from "react-router-dom";
import { Device } from "@capacitor/device";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SahhaService from "SahhaService";
import { Sahha } from "sahha-capacitor";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import PersonIcon from "@mui/icons-material/Person";
import { ActivityCard } from "./ActivityCard";
import { StepsDetailCard } from "./ActivityDetailsCard";
import {
  determinePerformanceLevel,
  getFriendlyNameOfState,
  getGradientForScore,
} from "./formatters";
import { SleepDetailsCard } from "./SleepDetailsCard";
import { ReadinessDetailsCard } from "./ReadinessDetailsCard";
import { HealthPermissionsDialog } from "./HealthPermissionsDialog";
import { EmployeeChallengeContainer } from "./EmployeeChallengeContainer";
import { SleepCard } from "./SleepCard";

dayjs.extend(utc);
dayjs.extend(timezone);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MOBILE_APP_MESSAGE =
  "Health data cannot be retrieved. Authorisation is required to access this feature.";
const WEB_APP_MESSAGE =
  "To access health data insights, you must use the FitFocus mobile app.";

const AthleteDashboard: FC = () => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(userSelectors.all);
  const { data: activeChallenge, isLoading: activeChallengeLoading } =
    useGetCurrentActiveChallengeQuery();
  const [isMobileApp, setIsMobileApp] = useState(false);

  const { data: onboardingCompleted, isLoading: onboardingLoading } =
    useGetUserOnboardingCompletedQuery();

  const handleCompleteOnboarding = async () => {
    if (onboardingCompleted && onboardingCompleted.onboardingUrl) {
      window.open(onboardingCompleted.onboardingUrl, "_blank");
    }
  };

  const { data: activityInsights, isLoading: activityHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "activity",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
        refetchOnMountOrArgChange: true,
      }
    );
  const { data: readinessInsights, isLoading: readinessHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "readiness",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: sleepInsights, isLoading: sleepHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "sleep",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
        refetchOnMountOrArgChange: true,
      }
    );

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);
      return;
    }
    setIsMobileApp(false);
  };

  const [showStepsDetail, setShowStepsDetail] = useState(false);
  const [showSleepDetail, setShowSleepDetail] = useState(false);
  const [showReadinessDetail, setShowReadinessDetail] = useState(false);
  const [showWellbeingDetail, setShowWellbeingDetail] = useState(false);
  const [sensorStatus, setSensorStatus] = useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const [isApiKeyAuthorised, setIsApiKeyAuthorised] = useState(false);
  const [showHealthPermissions, setShowHealthPermissions] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const profileUrl = `${Routes.profile.url}/${user.id}`;
  const navigate = useNavigate();

  const handleNavigateToProfileUrl = () => {
    navigate(profileUrl);
  };

  const [dailyDeviceStats, setDailyDeviceStats] = useState<{
    steps: number;
    activeCalories: number;
  }>({
    steps: 0,
    activeCalories: 0,
  });

  const todayStepsPerformance = determinePerformanceLevel(
    dailyDeviceStats?.steps ?? 0,
    10000,
    "activity"
  );

  const caloriesBurnedPerformance = determinePerformanceLevel(
    dailyDeviceStats?.activeCalories ?? 0,
    500,
    "activity"
  );

  useEffect(() => {
    const checkIsApiKeyAuthorised = async () => {
      setIsApiKeyAuthorised(
        !!activityInsights?.isAuthorised &&
          !!readinessInsights?.isAuthorised &&
          !!sleepInsights?.isAuthorised
      );
    };

    const checkSensorStatus = async () => {
      const sahhaService = SahhaService.getInstance();
      const status = await sahhaService.getSahhaSensorStatus();
      setSensorStatus(status === "enabled");
    };

    const checkAuthStatus = async () => {
      const status = await Sahha.isAuthenticated();
      setAuthStatus(status.success);
    };

    checkSensorStatus();
    checkIsApiKeyAuthorised();
    checkDeviceIsMobileApp();
    checkAuthStatus();
  }, [activityInsights, readinessInsights, sleepInsights]);

  const displayActivityCards = authStatus && isApiKeyAuthorised && sensorStatus;

  useEffect(() => {
    const fetchDailyStats = async () => {
      if (displayActivityCards) {
        const sahhaService = SahhaService.getInstance();
        const steps = await sahhaService.getStepStats();
        const activeCalories = await sahhaService.getActiveCaloriesStats();
        const biomarkers =
          await sahhaService.getStepsAndActiveEnergyBurnedBiomarkers();

        setDailyDeviceStats({
          steps: steps?.value || biomarkers?.steps,
          activeCalories:
            activeCalories?.value || biomarkers?.activeEnergyBurned,
        });
      }
    };

    fetchDailyStats();
  }, [displayActivityCards]);

  const handleShowActivityDetails = () => {
    if (!displayActivityCards) {
      return;
    }
    setShowStepsDetail(true);
  };

  const handleShowSleepDetails = () => {
    if (!displayActivityCards) {
      return;
    }
    setShowSleepDetail(true);
  };

  const handleShowReadinessDetails = () => {
    if (!displayActivityCards) {
      return;
    }
    setShowReadinessDetail(true);
  };

  const isApiLoading =
    sleepHealthDataLoading ||
    readinessHealthDataLoading ||
    activityHealthDataLoading;

  const currentDate = dayjs().format("dddd, MMMM D");
  const getTimeBasedGreeting = () => {
    const hour = dayjs().local().hour();
    if (hour >= 5 && hour < 12) return { text: "morning", emoji: "🌞" };
    if (hour >= 12 && hour < 17) return { text: "afternoon", emoji: "🌤️" };
    return { text: "evening", emoji: "🌙" };
  };

  const { text: greetingTime, emoji } = getTimeBasedGreeting();
  const greeting = user?.firstName
    ? `Good ${greetingTime} ${user.firstName} ${emoji} `
    : `Good ${greetingTime}!`;

  const [showLeaderboard, setShowLeaderboard] = useState(false);

  const handleOpenLeaderboard = () => {
    setShowLeaderboard(true);
  };

  const { data: wellbeingData, isLoading: wellbeingDataLoading } =
    useGetWellbeingDashboardForUserQuery({
      userId: user?.id,
      dateFrequency: "daily",
    } as WellbeingDashboardRequest);

  const nextWorkout = wellbeingData?.remainingWorkouts?.[0];

  const handleContinueWorkout = () => {
    if (nextWorkout) {
      dispatch(setCurrentWorkout(nextWorkout as AnyType));
      navigate(`${Routes.workouts.url}/${nextWorkout.id}`);
    }
  };

  const [challengeDialogOpen, setChallengeDialogOpen] = useState(false);

  const handleCloseChallengeDialog = () => {
    setChallengeDialogOpen(false);
  };

  const handleCloseHealthPermissions = () => {
    setShowHealthPermissions(false);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        pb: "80px",
      }}
    >
      {/* Gradient Header */}
      <Box
        sx={{
          width: "100%",
          color: "#fff",
          padding: "24px 16px",
          background: `linear-gradient(
                126.67deg,
                #8B44AD 0%,
                #6C5CE7 50.52%,
                #4834D4 100%
              )`,
        }}
      >
        <Box
          sx={{
            height: 40,
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            mb: 3,
            pt: 6.5,
          }}
        >
          <IconButton
            aria-label="points"
            onClick={handleOpenLeaderboard}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.15)",
                borderRadius: "50%",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <WalletIcon />
            </Box>
          </IconButton>

          <IconButton
            aria-label="profile"
            onClick={handleNavigateToProfileUrl}
            sx={{
              padding: 0,
            }}
          >
            {user?.profileImageUrl ? (
              <IdenticonAvatar
                sizeValue={40}
                seedValue={user.id?.toString()}
                profileImageUrl={user?.profileImageUrl || ""}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: "rgba(255,255,255,0.15)",
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <PersonIcon />
              </Box>
            )}
          </IconButton>
        </Box>
        <Typography variant="body2" sx={{ opacity: 0.9, mb: 1 }}>
          {currentDate}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {greeting}
        </Typography>
        {nextWorkout && (
          <Box
            sx={{
              mt: 4,
              p: 2,
              borderRadius: 2,
              backgroundColor: "rgba(255,255,255,0.15)",
              backdropFilter: "blur(8px)",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
              Next workout
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: "#fff" }}>
              Week {nextWorkout.weekNumber} - Session {nextWorkout.dayNumber}
              {nextWorkout.title && ` - ${nextWorkout.title}`}
            </Typography>

            <Button
              variant="contained"
              onClick={handleContinueWorkout}
              fullWidth
              sx={{
                borderRadius: 2,
                textTransform: "none",
                backgroundColor: "#fff",
                color: "#241465",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              Start workout
            </Button>
          </Box>
        )}
        {readinessHealthDataLoading ? (
          <Box
            sx={{
              mt: 4,
              p: 2,
              borderRadius: 2,
              backgroundColor: "rgba(255,255,255,0.15)",
              backdropFilter: "blur(8px)",
            }}
          >
            <Skeleton
              variant="text"
              width={140}
              height={32}
              sx={{ bgcolor: "rgba(255,255,255,0.2)" }}
            />
            <Skeleton
              variant="text"
              width={200}
              height={24}
              sx={{ mb: 2, bgcolor: "rgba(255,255,255,0.2)" }}
            />
            <Skeleton
              variant="rectangular"
              height={36}
              sx={{ borderRadius: 2, bgcolor: "rgba(255,255,255,0.2)" }}
            />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }} onClick={handleShowReadinessDetails}>
            {readinessInsights && readinessInsights.overallScore ? (
              <ActivityCard
                title="Daily readiness"
                value={Math.floor(readinessInsights.overallScore ?? 0)}
                target={100}
                gradient={getGradientForScore(readinessInsights.overallState)}
                overrideText={`${Math.floor(
                  readinessInsights.overallScore ?? 0
                )} - ${getFriendlyNameOfState(readinessInsights.overallState)}`}
              />
            ) : null}
          </Box>
        )}
        {onboardingCompleted &&
          !onboardingCompleted.isOnboardingCompleted &&
          !onboardingLoading && (
            <Box
              sx={{
                mt: 4,
                p: 2,
                borderRadius: 2,
                backgroundColor: "rgba(255,255,255,0.15)",
                backdropFilter: "blur(8px)",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                You have not completed onboarding
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, color: "#fff" }}>
                The status of your onboarding can take up to 24 hours to update.
                Please ignore this message if you have recently completed
                onboarding.
              </Typography>
              <Button
                variant="contained"
                onClick={handleCompleteOnboarding}
                fullWidth
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  backgroundColor: "#fff",
                  color: "#241465",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                Complete onboarding
              </Button>
            </Box>
          )}
      </Box>

      {!activeChallengeLoading && activeChallenge && (
        <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Active challenge</Typography>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                backgroundColor: "rgba(255,255,255,0.9)",
                backdropFilter: "blur(8px)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "left",
              }}
              onClick={() => setChallengeDialogOpen(true)}
            >
              <Box sx={{ width: "50%" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {activeChallenge.activeChallenge.name}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1, color: "gray" }}>
                  {`${dayjs(activeChallenge.activeChallenge.startDate).format(
                    "MMM D, YYYY"
                  )} - ${dayjs(activeChallenge.activeChallenge.endDate).format(
                    "MMM D, YYYY"
                  )}`}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {activeChallenge.activeChallenge.shortDescription}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                {activeChallenge.userRank && (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "#6C5CE7",
                    }}
                  >
                    {`${activeChallenge.userRank}${
                      activeChallenge.userRank % 10 === 1 &&
                      activeChallenge.userRank % 100 !== 11
                        ? "st"
                        : activeChallenge.userRank % 10 === 2 &&
                          activeChallenge.userRank % 100 !== 12
                        ? "nd"
                        : activeChallenge.userRank % 10 === 3 &&
                          activeChallenge.userRank % 100 !== 13
                        ? "rd"
                        : "th"
                    } / ${activeChallenge.totalParticipants?.toLocaleString()}`}
                  </Typography>
                )}
                {activeChallenge.userScore && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {activeChallenge.userScore.toLocaleString()}{" "}
                    {activeChallenge.challengeTargetType.toLocaleLowerCase()}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Health Cards */}
      {!showStepsDetail &&
        !showSleepDetail &&
        !showReadinessDetail &&
        !showWellbeingDetail && (
          <>
            {displayActivityCards && !isApiLoading && (
              <Grid container spacing={2} sx={{ mt: 2, px: 2, mb: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">Your day in review</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={handleShowActivityDetails}>
                    <ActivityCard
                      title="Steps"
                      value={dailyDeviceStats.steps}
                      target={10000}
                      gradient={getGradientForScore(todayStepsPerformance)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={handleShowActivityDetails}>
                    <ActivityCard
                      title="Active calories burned"
                      value={dailyDeviceStats.activeCalories}
                      target={500}
                      unit="kcal"
                      gradient={getGradientForScore(caloriesBurnedPerformance)}
                    />
                  </Box>
                </Grid>
                {sleepInsights && sleepInsights.overallState && (
                  <>
                    <Grid item xs={6}>
                      <Box onClick={handleShowSleepDetails}>
                        <ActivityCard
                          title="Daily sleep"
                          value={sleepInsights?.overallScore ?? 0}
                          target={100}
                          gradient={getGradientForScore(
                            sleepInsights?.overallState ?? ""
                          )}
                          overrideText={`${Math.floor(
                            sleepInsights?.overallScore ?? 0
                          )} - ${getFriendlyNameOfState(
                            sleepInsights?.overallState ?? ""
                          )}`}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box onClick={handleShowSleepDetails}>
                        <SleepCard
                          title="Sleep duration"
                          value={Math.floor(
                            sleepInsights?.factors?.find(
                              (factor) => factor.name === "sleep_duration"
                            )?.value ?? 0
                          )}
                          target={Math.floor(
                            sleepInsights?.factors?.find(
                              (factor) => factor.name === "sleep_duration"
                            )?.goal ?? 0
                          )}
                          gradient={getGradientForScore(
                            sleepInsights?.factors?.find(
                              (factor) => factor.name === "sleep_duration"
                            )?.state ?? ""
                          )}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {isApiLoading && (
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={118} />
              </Grid>
            )}

            {(!displayActivityCards || !isApiKeyAuthorised) &&
              !isApiLoading && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    mb: 1,
                    mt: 2,
                    p: 3,
                    mx: 2,
                    borderRadius: 2,
                    backdropFilter: "blur(8px)",
                  }}
                >
                  <Box
                    component="img"
                    src={getGraphicSvg("thinking")}
                    alt="Thinking"
                    sx={{ height: "80px", mb: 2, opacity: 0.9 }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ mb: 2, opacity: 0.9, fontSize: "0.9rem" }}
                  >
                    {isMobileApp ? MOBILE_APP_MESSAGE : WEB_APP_MESSAGE}
                  </Typography>
                  {isMobileApp && (
                    <Button
                      variant="contained"
                      onClick={() => setShowHealthPermissions(true)}
                      startIcon={<Icon>settings</Icon>}
                      fullWidth
                      sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        backgroundColor: "#fff",
                        color: "#241465",
                        "&:hover": {
                          backgroundColor: "#f1f1f1",
                        },
                      }}
                    >
                      Configure Health Permissions
                    </Button>
                  )}
                </Box>
              )}
          </>
        )}

      {/* Modals */}
      <StepsDetailCard
        insights={activityInsights ?? null}
        open={showStepsDetail}
        onClose={() => setShowStepsDetail(false)}
        dailyDeviceStats={dailyDeviceStats}
      />
      <SleepDetailsCard
        sleepInsights={sleepInsights ?? null}
        open={showSleepDetail}
        onClose={() => setShowSleepDetail(false)}
      />
      <ReadinessDetailsCard
        readinessInsights={readinessInsights ?? null}
        open={showReadinessDetail}
        onClose={() => setShowReadinessDetail(false)}
      />
      <HealthPermissionsDialog
        isMobileApp={isMobileApp}
        open={showHealthPermissions}
        onClose={handleCloseHealthPermissions}
      />
      <AthletePoints
        open={showLeaderboard}
        onClose={() => setShowLeaderboard(false)}
      />
      {activeChallenge && (
        <EmployeeChallengeContainer
          open={challengeDialogOpen}
          onClose={handleCloseChallengeDialog}
          activeChallenge={activeChallenge}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{ width: "100%" }}
        >
          On your first time syncing health data, you may experience a brief
          delay while the data syncs.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export { AthleteDashboard };
