import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { HealthSummaryResponse } from "shared/api/healthScores/models";
import { ActivityCard } from "./ActivityCard";
import {
  determinePerformanceLevel,
  getGradientForScore,
  getFriendlyNameOfState,
} from "./formatters";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface WellbeingDetailsCardProps {
  wellbeingInsights: HealthSummaryResponse | null;
  mentalWellbeingInsights: HealthSummaryResponse | null;
  open: boolean;
  onClose: () => void;
}

export const WellbeingDetailsCard: React.FC<WellbeingDetailsCardProps> = ({
  wellbeingInsights,
  mentalWellbeingInsights,
  open,
  onClose,
}) => {
  if (!wellbeingInsights || !mentalWellbeingInsights) return null;

  const averageWellbeingScore =
    ((wellbeingInsights.overallScore ?? 0) +
      (mentalWellbeingInsights.overallScore ?? 0)) /
    2;

  const overallScorePerformance = determinePerformanceLevel(
    averageWellbeingScore,
    100,
    "wellbeing"
  );

  const circadianAlignment = mentalWellbeingInsights?.factors.find(
    (factor) => factor.name === "circadian_alignment"
  );

  const activityRegularity = mentalWellbeingInsights?.factors.find(
    (factor) => factor.name === "activity_regularity"
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            Wellbeing details
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActivityCard
              title="Daily wellbeing"
              value={Math.floor(averageWellbeingScore)}
              target={100}
              unit=""
              gradient={getGradientForScore(overallScorePerformance)}
              overrideText={`${Math.floor(
                averageWellbeingScore
              )} - ${getFriendlyNameOfState(wellbeingInsights.overallState)}`}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Circadian alignment"
              value={Math.floor(circadianAlignment?.value ?? 0)}
              target={Math.floor(circadianAlignment?.goal ?? 0)}
              gradient={getGradientForScore(circadianAlignment?.state ?? "")}
              overrideText={getFriendlyNameOfState(
                circadianAlignment?.state ?? ""
              )}
              removeBottomText
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Activity regularity"
              value={Math.floor(activityRegularity?.value ?? 0)}
              target={Math.floor(activityRegularity?.goal ?? 0)}
              gradient={getGradientForScore(activityRegularity?.state ?? "")}
              overrideText={getFriendlyNameOfState(
                activityRegularity?.state ?? ""
              )}
              removeBottomText
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: "#f8f9ff",
            border: "1px solid rgba(108, 92, 231, 0.1)",
            boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#6C5CE7",
              mb: 2,
              fontWeight: 600,
              fontSize: "1.1rem",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LightbulbOutlinedIcon sx={{ fontSize: "1.2rem" }} />
            Insight
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.95rem",
              color: "#444",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            {wellbeingInsights.overallInsight}
          </Typography>
          <Link
            href="https://learn.sahha.ai/a-32-wellbeing-score-explained"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#6C5CE7",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "0.85rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Learn more about wellbeing scores by clicking here. Powered by
            Sahha.ai
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
