import dayjs from "dayjs";
import React, { useState, SyntheticEvent } from "react";
import {
  Box,
  Typography,
  Paper,
  Tab,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  TableContainer,
  TableHead,
  Table,
  TablePagination,
  Grid,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  useGetAthleteLeaderboardQuery,
  ChallengeLeaderboardItem,
  AthleteLeaderboardRequestParams,
} from "shared/api";
import { ReadinessDetailPage } from "pages/Dashboard/AthleteDashboard/ReadinessDetailPage";
import { WhatIsReadinessCard } from "pages/Dashboard/AthleteDashboard/WhatIsReadinessCard";

interface AthleteLeaderboardProps {
  userId: number;
}

export const AthleteLeaderboard: React.FC<AthleteLeaderboardProps> = ({
  userId,
}) => {
  const [tabValue, setTabValue] = useState("steps");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateFilter, setDateFilter] = useState("lastWeek"); // Default filter

  const [openReadinessDialog, setOpenReadinessDialog] = useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "days").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    data: leaderboardData,
    isLoading,
    refetch,
  } = useGetAthleteLeaderboardQuery({
    leaderboardType: tabValue,
    startDate,
    endDate,
  } as AthleteLeaderboardRequestParams);

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string
  ) => {
    if (newFilter !== null) {
      setDateFilter(newFilter);

      if (newFilter === "lastWeek") {
        setStartDate(dayjs().subtract(7, "days").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
      } else if (newFilter === "last30Days") {
        setStartDate(dayjs().subtract(30, "days").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
      }

      refetch();
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!leaderboardData || leaderboardData.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={4} align="center">
            No leaderboard data available.
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  const paginatedData = leaderboardData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const formattedTabValueForTable = (tabValue: string) => {
    switch (tabValue) {
      case "steps":
        return "Steps";
      case "activeCalories":
        return "Calories";
      case "sleepQuality":
        return "Sleep";
      case "readiness":
        return "Readiness";
      default:
        return tabValue;
    }
  };

  return (
    <Box sx={{ p: { xs: 0, sm: 3 } }}>
      <Paper elevation={0} sx={{ borderRadius: 2, mb: 3, p: 2 }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            aria-label="Leaderboard Tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              maxWidth: "100%",
              ".MuiTabs-scroller": {
                overflowX: "auto !important",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
              },
            }}
          >
            <Tab label="Steps" value="steps" sx={{ textTransform: "none" }} />
            <Tab
              label="Calories"
              value="activeCalories"
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Readiness"
              value="readiness"
              sx={{ textTransform: "none" }}
            />
          </TabList>

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="body2" color="textSecondary">
              {tabValue === "steps" &&
                "Total steps walked or ran in the selected timeframe."}
              {tabValue === "activeCalories" &&
                "Total active calories burned in the selected timeframe."}
              {tabValue === "sleepQuality" &&
                "Average sleep quality score for the selected timeframe."}
              {tabValue === "readiness" &&
                "Average readiness score for the selected timeframe."}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              Data can take up to 72 hours to sync.
            </Typography>
          </Box>

          {/* Modern Filter UI (Segmented Control) */}
          <Box sx={{ display: "flex", justifyContent: "left" }}>
            <ToggleButtonGroup
              value={dateFilter}
              exclusive
              onChange={handleFilterChange}
              aria-label="date filter"
              sx={{ borderRadius: 2, textTransform: "none" }}
            >
              <ToggleButton
                value="lastWeek"
                sx={{
                  textTransform: "none",
                  backgroundColor:
                    dateFilter === "lastWeek" ? "blue" : "transparent",
                  color: dateFilter === "lastWeek" ? "white" : "black",
                  "&.Mui-selected": {
                    backgroundColor: "blue",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "blue",
                    },
                  },
                }}
              >
                Last week
              </ToggleButton>
              <ToggleButton
                value="last30Days"
                sx={{
                  textTransform: "none",
                  backgroundColor:
                    dateFilter === "last30Days" ? "blue" : "transparent",
                  color: dateFilter === "last30Days" ? "white" : "black",
                  "&.Mui-selected": {
                    backgroundColor: "blue",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "blue",
                    },
                  },
                }}
              >
                Last 30 days
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {tabValue === "readiness" && (
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <WhatIsReadinessCard setDialogOpen={setOpenReadinessDialog} />
              </Grid>
            </Grid>
          )}

          <TabPanel value={tabValue} sx={{ p: 0 }}>
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rank</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>
                        {formattedTabValueForTable(tabValue)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map(
                      (participant: ChallengeLeaderboardItem, index) => (
                        <TableRow key={participant.userId}>
                          <TableCell>
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Avatar
                                src={participant.profileImageUrl}
                                sx={{ mr: 2 }}
                              />
                              {participant.firstName} {participant.lastName}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {participant.value?.toLocaleString() ?? 0}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={leaderboardData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}
          </TabPanel>
        </TabContext>
        {tabValue === "readiness" && (
          <ReadinessDetailPage
            open={openReadinessDialog}
            onClose={() => setOpenReadinessDialog(false)}
          />
        )}
      </Paper>
    </Box>
  );
};
