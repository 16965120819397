/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Note, useAddNoteMutation, useGetNotesForUserQuery } from "shared/api";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { UserProfileNoteTextEditor } from "./UserProfileNoteTextEditor";
import { NoteType } from "./UserProfileNotes";

dayjs.extend(utc);

type NotesModalProps = {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preSelectedUserId: number;
  noteType: NoteType;
  preSelectedNoteId?: number;
};

const NotesModal: FC<NotesModalProps> = ({
  isOpenModal,
  handleCloseModal,
  preSelectedUserId,
  noteType,
  preSelectedNoteId = null,
}) => {
  const { t } = useTranslation();

  // Fetch notes
  const {
    data: notesData,
    isLoading,
    error,
    refetch,
  } = useGetNotesForUserQuery({
    count: 10, // Fetch all notes
    page: 1,
    userId: preSelectedUserId,
    orderByDescending: true,
    noteCategory: noteType,
  });

  const [addUserNote, { isLoading: isLoadingAdd }] = useAddNoteMutation();

  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(
    preSelectedNoteId
  );

  // Add this effect to update selectedNoteId when preSelectedNoteId changes
  useEffect(() => {
    setSelectedNoteId(preSelectedNoteId);
  }, [preSelectedNoteId]);

  const handleSelectNote = (noteId: number) => {
    if (selectedNoteId === noteId) {
      return;
    }
    setSelectedNoteId((prevSelectedNoteId) =>
      prevSelectedNoteId === noteId ? null : noteId
    );
  };

  const handleNewNote = async () => {
    setSelectedNoteId(null);
    // Optionally, open the editor in create mode

    const newUserNote = await addUserNote({
      title: `New ${noteType} Note`,
      dateTime: dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      userId: preSelectedUserId,
      noteType,
    }).unwrap();

    if (newUserNote) {
      const updatedNotes = await refetch().unwrap();
      const createdNote = updatedNotes.find((n) => n.id === newUserNote.id);
      setSelectedNoteId(createdNote?.id || newUserNote?.id);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "70vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          outline: "none",
        }}
      >
        {/* Side Menu */}
        <Box
          sx={{
            width: "30%",
            borderRight: "1px solid #ccc",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{t("Notes")}</Typography>
            <Button
              variant="text"
              onClick={handleNewNote}
              startIcon={<AddIcon />}
              sx={{
                textTransform: "none",
              }}
            >
              {t("New Note")}
            </Button>
          </Box>
          <Divider />
          {isLoading ? (
            <Typography sx={{ p: 2 }}>{t("Loading...")}</Typography>
          ) : error ? (
            <Typography sx={{ p: 2 }}>{t("Error loading notes.")}</Typography>
          ) : notesData && notesData.length > 0 ? (
            <List>
              {notesData.map((note: Note) => (
                <ListItem
                  button
                  key={note.id}
                  selected={note.id === selectedNoteId}
                  onClick={() => handleSelectNote(note?.id)}
                >
                  <ListItemText
                    primary={note.title}
                    secondary={dayjs(note.dateTime).format("D MMM YYYY")}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ p: 2 }}>
              Add a new note to get started.
            </Typography>
          )}
        </Box>

        {/* Main Content Area */}
        <Box
          sx={{
            width: "70%",
            p: 2,
            overflowY: "auto",
          }}
        >
          {selectedNoteId ? (
            // Display the selected note using UserNoteTextEditor
            <UserProfileNoteTextEditor
              key={selectedNoteId} // Added key prop
              userNoteId={selectedNoteId}
            />
          ) : (
            <Typography variant="body1">
              {t("Select a note to view details or create a new one.")}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default NotesModal;
