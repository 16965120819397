import React, { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography, Link } from "@mui/material";
import { useGetMostRecentHealthSummaryForUserQuery } from "shared/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  determinePerformanceLevel,
  getFriendlyNameOfState,
  getGradientForScore,
} from "../AthleteDashboard/formatters";
import { ActivityCard } from "../AthleteDashboard/ActivityCard";
import { StepsDetailCard } from "../AthleteDashboard/ActivityDetailsCard";
import { SleepDetailsCard } from "../AthleteDashboard/SleepDetailsCard";
import { ReadinessDetailsCard } from "../AthleteDashboard/ReadinessDetailsCard";
import { WellbeingDetailsCard } from "../AthleteDashboard/WellbeingDetailsCard";

dayjs.extend(utc);
dayjs.extend(timezone);

type UserProfileHealthMetricsProps = {
  userId: number;
};

const HealthMetricsLoadingSkeleton: React.FC = () => (
  <Box sx={{ px: 2, mt: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
      </Grid>
    </Grid>
  </Box>
);

export const UserProfileHealthMetrics: React.FC<
  UserProfileHealthMetricsProps
> = ({ userId }) => {
  const { data: activityInsights, isLoading: activityHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "activity",
        userId,
      },
      {
        skip: !userId,
      }
    );

  const { data: sleepInsights, isLoading: sleepHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "sleep",
        userId,
      },
      {
        skip: !userId,
      }
    );

  const { data: readinessInsights, isLoading: readinessHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "readiness",
        userId,
      },
      {
        skip: !userId,
      }
    );

  const { data: wellbeingInsights, isLoading: wellbeingHealthDataLoading } =
    useGetMostRecentHealthSummaryForUserQuery(
      {
        type: "wellbeing",
        userId,
      },
      {
        skip: !userId,
      }
    );

  const {
    data: mentalWellbeingInsights,
    isLoading: mentalWellbeingHealthDataLoading,
  } = useGetMostRecentHealthSummaryForUserQuery(
    {
      type: "mental_wellbeing",
      userId,
    },
    {
      skip: !userId,
    }
  );

  const [sahhaAuthorised, setSahhaAuthorised] = useState(false);
  const [showStepsDetail, setShowStepsDetail] = useState(false);
  const [showSleepDetail, setShowSleepDetail] = useState(false);
  const [showReadinessDetail, setShowReadinessDetail] = useState(false);
  const [showWellbeingDetail, setShowWellbeingDetail] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const isApiLoading =
    sleepHealthDataLoading ||
    readinessHealthDataLoading ||
    activityHealthDataLoading ||
    wellbeingHealthDataLoading ||
    mentalWellbeingHealthDataLoading;

  useEffect(() => {
    const checkSahhaAuth = async () => {
      setSahhaAuthorised(
        !!activityInsights?.isAuthorised &&
          !!readinessInsights?.isAuthorised &&
          !!sleepInsights?.isAuthorised &&
          !!wellbeingInsights?.isAuthorised &&
          !!mentalWellbeingInsights?.isAuthorised
      );
      setIsInitializing(false);
    };

    if (!isApiLoading) {
      checkSahhaAuth();
    }
  }, [
    activityInsights,
    readinessInsights,
    sleepInsights,
    wellbeingInsights,
    mentalWellbeingInsights,
    isApiLoading,
  ]);

  const activeCalories = activityInsights?.factors.find(
    (factor) => factor.name === "active_calories"
  );

  const steps = activityInsights?.factors.find(
    (factor) => factor.name === "steps"
  );

  const caloriesBurnedPerformance = determinePerformanceLevel(
    activeCalories?.value ?? 0,
    activeCalories?.goal ?? 0,
    "activity"
  );

  const todayStepsPerformance = determinePerformanceLevel(
    steps?.value ?? 0,
    steps?.goal ?? 0,
    "activity"
  );

  const averageWellbeingScore =
    ((wellbeingInsights?.overallScore ?? 0) +
      (mentalWellbeingInsights?.overallScore ?? 0)) /
    2;

  const overallWellbeingScorePerformance = determinePerformanceLevel(
    averageWellbeingScore,
    100,
    "wellbeing"
  );

  let overallWellbeingScoreState = "";
  if (averageWellbeingScore <= 50) {
    overallWellbeingScoreState = "minimal";
  } else if (averageWellbeingScore <= 65) {
    overallWellbeingScoreState = "low";
  } else if (averageWellbeingScore <= 80) {
    overallWellbeingScoreState = "medium";
  } else {
    overallWellbeingScoreState = "high";
  }

  const handleShowActivityDetails = () => {
    if (!sahhaAuthorised) return;
    setShowStepsDetail(true);
  };

  const handleShowSleepDetails = () => {
    if (!sahhaAuthorised) return;
    setShowSleepDetail(true);
  };

  const handleShowReadinessDetails = () => {
    if (!sahhaAuthorised) return;
    setShowReadinessDetail(true);
  };

  const handleShowWellbeingDetails = () => {
    if (!sahhaAuthorised) return;
    setShowWellbeingDetail(true);
  };

  if (isApiLoading || isInitializing) {
    return <HealthMetricsLoadingSkeleton />;
  }

  if (!sahhaAuthorised && !isApiLoading && !isInitializing) {
    return (
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="body1" color="text.secondary">
          Health data is not available. Please ensure health permissions are
          enabled.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {!showStepsDetail &&
        !showSleepDetail &&
        !showReadinessDetail &&
        !showWellbeingDetail && (
          <Grid container spacing={2} sx={{ mt: 2, px: 2, mb: 2 }}>
            {readinessInsights && readinessInsights.overallState && (
              <Grid item xs={12}>
                <Box onClick={handleShowReadinessDetails}>
                  <ActivityCard
                    title="Daily readiness"
                    value={readinessInsights.overallScore ?? 0}
                    target={100}
                    gradient={getGradientForScore(
                      readinessInsights.overallState
                    )}
                    overrideText={`${
                      readinessInsights.overallScore ?? 0
                    } - ${getFriendlyNameOfState(
                      readinessInsights.overallState
                    )}`}
                  />
                </Box>
              </Grid>
            )}

            {wellbeingInsights && wellbeingInsights.overallState && (
              <Grid item xs={6}>
                <Box onClick={handleShowWellbeingDetails}>
                  <ActivityCard
                    title="Daily wellbeing"
                    value={Math.floor(averageWellbeingScore)}
                    target={100}
                    gradient={getGradientForScore(
                      overallWellbeingScorePerformance
                    )}
                    overrideText={`${Math.floor(
                      averageWellbeingScore
                    )} - ${getFriendlyNameOfState(overallWellbeingScoreState)}`}
                  />
                </Box>
              </Grid>
            )}

            {sleepInsights && sleepInsights.overallState && (
              <Grid item xs={6}>
                <Box onClick={handleShowSleepDetails}>
                  <ActivityCard
                    title="Daily sleep"
                    value={sleepInsights.overallScore ?? 0}
                    target={100}
                    gradient={getGradientForScore(sleepInsights.overallState)}
                    overrideText={`${Math.floor(
                      sleepInsights.overallScore ?? 0
                    )} - ${getFriendlyNameOfState(sleepInsights.overallState)}`}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={6}>
              <Box onClick={handleShowActivityDetails}>
                <ActivityCard
                  title="Steps"
                  value={Math.floor(steps?.value ?? 0)}
                  target={steps?.goal ?? 0}
                  gradient={getGradientForScore(todayStepsPerformance)}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box onClick={handleShowActivityDetails}>
                <ActivityCard
                  title="Active calories burned"
                  value={Math.floor(activeCalories?.value ?? 0)}
                  target={activeCalories?.goal ?? 0}
                  unit="kcal"
                  gradient={getGradientForScore(caloriesBurnedPerformance)}
                />
              </Box>
            </Grid>
          </Grid>
        )}

      {/* Detail Cards */}
      <StepsDetailCard
        insights={activityInsights ?? null}
        open={showStepsDetail}
        onClose={() => setShowStepsDetail(false)}
        dailyDeviceStats={{
          steps: steps?.value ?? 0,
          activeCalories: activeCalories?.value ?? 0,
        }}
      />
      <SleepDetailsCard
        sleepInsights={sleepInsights ?? null}
        open={showSleepDetail}
        onClose={() => setShowSleepDetail(false)}
      />
      <ReadinessDetailsCard
        readinessInsights={readinessInsights ?? null}
        open={showReadinessDetail}
        onClose={() => setShowReadinessDetail(false)}
      />
      <WellbeingDetailsCard
        wellbeingInsights={wellbeingInsights ?? null}
        mentalWellbeingInsights={mentalWellbeingInsights ?? null}
        open={showWellbeingDetail}
        onClose={() => setShowWellbeingDetail(false)}
      />
    </Box>
  );
};
