import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  ClientInvoice,
  InvoiceRequestParams,
  UpdateClientInvoiceParams,
  BillingProductRequestParams,
  BillingProduct,
  CreateBillingProductRequest,
  BillingProductPriceUser,
  AddUserToBillingProductRequest,
  SendDirectDebitFormRequest,
  SubscriptionStatusResponse,
} from "./models";

export const billingApi = createApi({
  baseQuery,
  reducerPath: "billingApi",
  tagTypes: ["Billing", "InvoiceList", "Invoices", "Invoice", "InvoiceCount"],
  endpoints: (builder) => ({
    getInvoices: builder.query<ClientInvoice[], InvoiceRequestParams>({
      query: (params) => ({
        url: `/billing/invoices`,
        params,
      }),
      providesTags: ["InvoiceList"],
    }),
    getInvoicesCount: builder.query<number, void>({
      query: () => ({
        url: `/billing/invoices?countOnly=true`,
        method: "GET",
      }),
      providesTags: ["InvoiceCount"],
    }),
    updateInvoiceStatus: builder.mutation<void, UpdateClientInvoiceParams>({
      query: ({ invoiceId, invoiceStatus }) => ({
        url: `/billing/invoices/${invoiceId}`,
        method: "PATCH",
        body: {
          invoiceStatus,
        },
      }),
      invalidatesTags: [
        "Invoices",
        "InvoiceList",
        "InvoiceCount",
        "Invoice",
        "Billing",
      ],
    }),
    getBillingProducts: builder.query<
      BillingProduct[],
      BillingProductRequestParams
    >({
      query: (params) => ({
        url: `/billing/products`,
        params,
      }),
      providesTags: ["Billing"],
    }),
    createBillingProduct: builder.mutation<
      BillingProduct,
      CreateBillingProductRequest
    >({
      query: (body) => ({
        url: `/billing/products`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Billing"],
    }),
    updateBillingProduct: builder.mutation<
      BillingProduct,
      CreateBillingProductRequest & { id: number }
    >({
      query: (body) => ({
        url: `/billing/products/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Billing"],
    }),
    addUserToBillingProduct: builder.mutation<
      BillingProductPriceUser,
      AddUserToBillingProductRequest
    >({
      query: (body) => ({
        url: `/billing/products/${body.billingProductId}/users`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Billing"],
    }),
    removeUserFromBillingProduct: builder.mutation<
      void,
      AddUserToBillingProductRequest
    >({
      query: (body) => ({
        url: `/billing/products/${body.billingProductId}/users`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Billing"],
    }),
    getBillingProductPriceUsers: builder.query<
      BillingProductPriceUser[],
      number
    >({
      query: (billingProductId) => ({
        url: `/billing/products/${billingProductId}/users`,
      }),
      providesTags: ["Billing"],
    }),
    getBillingProductsForUser: builder.query<BillingProduct[], number>({
      query: (userId) => ({
        url: `/billing/products/users/${userId}`,
      }),
      providesTags: ["Billing"],
    }),
    sendDirectDebitFormToUser: builder.mutation<
      void,
      SendDirectDebitFormRequest
    >({
      query: (body) => ({
        url: `/billing/send-direct-debit-form`,
        method: "POST",
        body,
      }),
    }),
    getSubscriptionStatus: builder.query<SubscriptionStatusResponse, void>({
      query: () => ({
        url: `/billing/subscription-status`,
        method: "GET",
      }),
      providesTags: ["Billing"],
    }),
    checkOrganisationHasSubscription: builder.query<boolean, void>({
      query: () => ({
        url: `/billing/organisation-has-subscription`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoicesCountQuery,
  useUpdateInvoiceStatusMutation,
  useGetBillingProductsQuery,
  useCreateBillingProductMutation,
  useUpdateBillingProductMutation,
  useAddUserToBillingProductMutation,
  useRemoveUserFromBillingProductMutation,
  useGetBillingProductPriceUsersQuery,
  useGetBillingProductsForUserQuery,
  useSendDirectDebitFormToUserMutation,
  useGetSubscriptionStatusQuery,
  useCheckOrganisationHasSubscriptionQuery,
} = billingApi;
