import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  WorkoutResponse,
  WorkoutSession,
  WorkoutItem,
  WorkoutItemLog,
  Exercise,
  CombinedData,
  CombinedDataParams,
  FeedbackParams,
} from "./model";

export const athleteApi = createApi({
  baseQuery,
  reducerPath: "athleteApi",
  tagTypes: ["Workouts", "IndividualWorkouts", "UpdatedWorkout"],
  endpoints: (builder) => ({
    getWorkouts: builder.query<WorkoutResponse, void>({
      query: () => ({
        url: "/dashboard",
        method: "GET",
      }),
      providesTags: ["Workouts"],
      keepUnusedDataFor: 0,
    }),
    getAthleteWorkouts: builder.query<WorkoutResponse, void>({
      query: () => ({
        url: "/dashboard/athlete",
        method: "GET",
      }),
      providesTags: ["Workouts"],
    }),
    getWorkout: builder.query<WorkoutSession, number>({
      query: (id) => ({
        url: `/workouts/${id}`,
        method: "GET",
      }),
      providesTags: ["Workouts"],
    }),
    getWorkoutInProgress: builder.query<WorkoutSession[], void>({
      query: () => ({
        url: `/workouts?workoutStatus=InProgress`,
        method: "GET",
      }),
      providesTags: ["Workouts"],
    }),
    getCombinedWorkoutItems: builder.query<CombinedData[], CombinedDataParams>({
      query: (params) => ({
        url: `/combined-workout-items`,
        method: "GET",
        params,
      }),
      providesTags: ["Workouts", "IndividualWorkouts", "UpdatedWorkout"],
    }),
    getWorkoutItems: builder.query<WorkoutItem[], number>({
      query: (id) => ({
        url: `/workout-items?workoutId=${id}`,
        method: "GET",
      }),
      providesTags: ["Workouts"],
    }),
    getExercises: builder.query<Exercise, number>({
      query: (id) => ({
        url: `/exercises/${id}`,
        method: "GET",
      }),
      providesTags: ["Workouts"],
    }),
    changeWorkoutStatus: builder.mutation<void, Partial<WorkoutSession>>({
      query: (body) => ({
        url: `/workouts/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Workouts", "IndividualWorkouts", "UpdatedWorkout"],
    }),
    changeWorkoutValues: builder.mutation<void, Partial<WorkoutItem>>({
      query: (body) => ({
        url: `/workout-items/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UpdatedWorkout"],
    }),
    changeWorkoutItemLog: builder.mutation<void, Partial<WorkoutItemLog>>({
      query: (body) => ({
        url: `/workout-item-logs/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UpdatedWorkout"],
    }),
    getWorkoutItem: builder.query<WorkoutItem, number>({
      query: (id) => ({
        url: `/workout-items?workoutId=${id}`,
        method: "GET",
      }),
      providesTags: ["UpdatedWorkout"],
    }),
    getWorkoutItemLog: builder.query<WorkoutItemLog, number>({
      query: (id) => ({
        url: `/workout-item-logs/${id}`,
        method: "GET",
      }),
      providesTags: ["UpdatedWorkout"],
    }),
    getWorkoutItemLogs: builder.query<WorkoutItemLog[], number>({
      query: (id) => ({
        url: `/workout-item-logs?workoutItemId=${id}`,
        method: "GET",
      }),
      providesTags: ["UpdatedWorkout"],
    }),
    changeWorkoutItemStatus: builder.mutation<void, WorkoutItem>({
      query: ({ id, ...rest }) => ({
        url: `/workout-items/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["IndividualWorkouts"],
    }),
    sendFeedback: builder.mutation<void, FeedbackParams>({
      query: ({ description, rating, workoutProgramId, workoutId }) => ({
        url: "/workout-program-notes",
        method: "POST",
        body: {
          description,
          rating,
          workoutProgramId,
          workoutId,
        },
      }),
    }),
    skipWorkout: builder.mutation<void, FeedbackParams>({
      query: ({ workoutProgramId, workoutId }) => ({
        url: "/workout-program-notes/skip",
        method: "POST",
        body: {
          workoutProgramId,
          workoutId,
        },
      }),
    }),
    quickCompleteWorkoutItem: builder.mutation<void, number>({
      query: (workoutItemId) => ({
        url: `/workout-items/quick-complete/${workoutItemId}`,
        method: "POST",
      }),
      invalidatesTags: ["IndividualWorkouts"],
    }),
  }),
});

export const {
  useGetWorkoutsQuery,
  useGetAthleteWorkoutsQuery,
  useGetWorkoutQuery,
  useGetWorkoutItemsQuery,
  useGetExercisesQuery,
  useChangeWorkoutStatusMutation,
  useGetCombinedWorkoutItemsQuery,
  useGetWorkoutInProgressQuery,
  useGetWorkoutItemQuery,
  useChangeWorkoutItemStatusMutation,
  useSendFeedbackMutation,
  useSkipWorkoutMutation,
  useChangeWorkoutValuesMutation,
  useChangeWorkoutItemLogMutation,
  useGetWorkoutItemLogQuery,
  useGetWorkoutItemLogsQuery,
  useQuickCompleteWorkoutItemMutation,
} = athleteApi;
