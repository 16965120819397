import { FC, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Box, Tab, Typography, useMediaQuery, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  useGetOrganisationByIdQuery,
  useGetOrganisationApiKeysQuery,
  useGetFiveTranConnectorsQuery,
} from "../../shared/api";
import { useTypedSelector } from "../../shared/stores";
import { userSelectors } from "../../shared/stores/user";
import { OrganisationUsersContainer } from "./OrganisationUsersContainer";
import { OrganisationNewUserModal } from "./OrganisationNewUserModal";
import { OrganisationIntegrationsContainer } from "./OrganisationIntegrationsContainer";
import { OrganisationAddIntegrationModal } from "./OrganisationAddIntegrationModal";

interface OrganisationManagementProps {
  openSidebar: () => void;
}

const OrganisationManagement: FC<OrganisationManagementProps> = ({
  openSidebar,
}) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const [isAddNewIntegrationModalOpen, setIsAddNewIntegrationModalOpen] =
    useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const handleAddNewIntegrationModal = () =>
    setIsAddNewIntegrationModalOpen(!isAddNewIntegrationModalOpen);

  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const organisationType = useTypedSelector(userSelectors.organisationType);

  const organisationId = useTypedSelector(userSelectors.orgId) ?? 0;
  const {
    data: currentOrganisationData,
    isLoading: isLoadingOrganisationData,
  } = useGetOrganisationByIdQuery(organisationId);
  const { data: organisationApiKeys, isLoading: isLoadingApiKeys } =
    useGetOrganisationApiKeysQuery({
      organisationId,
    });
  const { data: fiveTranConnectors, isLoading: isLoadingFiveTran } =
    useGetFiveTranConnectorsQuery();

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {currentOrganisationData?.name || t("organisation-management.title")}{" "}
          <Button
            variant="outlined"
            color="primary"
            href="https://billing.stripe.com/p/login/28obKofxOcorbwAcMM"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textTransform: "none", ml: 2 }}
          >
            Manage Billing
          </Button>
        </Typography>
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Users" value="1" />
            {organisationType !== "WorkplaceWellbeing" && (
              <Tab label="Integrations" value="2" />
            )}
          </TabList>
        </Box>
        <TabPanel value="1">
          <PrimaryButton
            value={t("organisation-management.addUserBtn")}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleToggleModal}
            icon="add_icon"
          />
          <OrganisationUsersContainer />
        </TabPanel>
        <TabPanel value="2">
          <PrimaryButton
            value={t("organisation-management.integrations.addIntegrationBtn")}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleAddNewIntegrationModal}
            icon="add_icon"
          />
          {!isLoadingApiKeys && (
            <OrganisationIntegrationsContainer
              organisationApiKeys={organisationApiKeys}
            />
          )}
        </TabPanel>
      </TabContext>
      {!isLoadingOrganisationData && (
        <OrganisationNewUserModal
          handleCloseModal={handleToggleModal}
          isOpenModal={isOpenModal}
          currentOrganisationData={currentOrganisationData}
        />
      )}
      {!isLoadingFiveTran && (
        <OrganisationAddIntegrationModal
          isOpenModal={isAddNewIntegrationModalOpen}
          handleCloseModal={handleAddNewIntegrationModal}
          fiveTranConnectors={fiveTranConnectors}
        />
      )}
    </Box>
  );
};

export { OrganisationManagement };
