/* eslint-disable no-nested-ternary */
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Tab,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Images } from "shared/themes";
import {
  useGetAthleteWorkoutsQuery,
  useGetWorkoutTemplatesQuery,
  WorkoutTemplateResponse,
} from "shared/api";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SessionCard } from "./SessionCard";
import { WorkoutPageSkeleton } from "./WorkoutPageSkeleton";
import { ActionButton } from "./ActionButton";
import { WorkoutList } from "./WorkoutList";
import { WorkoutTemplatePreview } from "./WorkoutTemplatePreview";

const Workouts: FC = () => {
  dayjs.extend(advancedFormat);
  const { data, isLoading, refetch } = useGetAthleteWorkoutsQuery();
  const [activeIndex, setActiveIndex] = useState<number | null>(1);

  const [openWorkoutTemplatePreview, setOpenWorkoutTemplatePreview] =
    useState(false);

  const [selectedWorkoutTemplate, setSelectedWorkoutTemplate] =
    useState<WorkoutTemplateResponse>();

  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, []);

  const { data: workoutTemplatesData, isLoading: workoutTemplatesIsLoading } =
    useGetWorkoutTemplatesQuery();

  const workoutProgramWeeksFrequency =
    data?.curentWorkoutProgram?.weeksInProgram === 1
      ? "1 week"
      : `${data?.curentWorkoutProgram?.weeksInProgram} weeks`;

  const workoutProgramDaysFrequency =
    data?.curentWorkoutProgram?.daysPerWeek === 1
      ? "1 day per week"
      : `${data?.curentWorkoutProgram?.daysPerWeek} days per week`;

  const workoutProgramFrequencyText = `${workoutProgramWeeksFrequency}, ${workoutProgramDaysFrequency}`;

  const [tabValue, setTabValue] = useState("active");
  const [difficultyFilter, setDifficultyFilter] = useState("");
  const [daysFilter, setDaysFilter] = useState<number | "">("");

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleDifficultyChange = (event: SelectChangeEvent<string>) => {
    setDifficultyFilter(event.target.value as string);
  };

  const handleDaysChange = (event: SelectChangeEvent<number | "">) => {
    setDaysFilter(event.target.value as number | "");
  };

  const uniqueDays = Array.from(
    new Set(workoutTemplatesData?.map((template) => template.daysInProgram))
  ).sort((a = 0, b = 0) => a - b);

  const filteredWorkoutTemplates = workoutTemplatesData?.filter(
    (template) =>
      (!difficultyFilter || template.difficulty === difficultyFilter) &&
      (!daysFilter || template.daysInProgram === daysFilter)
  );

  return !isLoading ? (
    <Box sx={{ overflowY: "auto" }}>
      <Card
        sx={{
          width: "100%",
          position: "relative",
          borderRadius: 0,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: Colors.gray[100],
              fontWeight: 600,
              mb: 1,
            }}
          >
            {data?.curentWorkoutProgram?.name || "No program selected"}
          </Typography>

          {data?.curentWorkoutProgram &&
            !data?.curentWorkoutProgram?.invoiceIsOverdue && (
              <>
                <Typography
                  sx={{
                    color: Colors.gray[100],
                    mb: 1,
                  }}
                >
                  {workoutProgramFrequencyText}
                </Typography>

                <Typography
                  sx={{
                    color: Colors.gray[100],
                    fontSize: 14,
                  }}
                >
                  {dayjs(data?.curentWorkoutProgram?.startDate).format(
                    "Do MMMM YYYY"
                  )}
                </Typography>
              </>
            )}
        </Box>
      </Card>
      <Box
        sx={{
          padding: "9px 24px 23px 24px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          {data?.curentWorkoutProgram?.invoiceIsOverdue && (
            <Alert
              variant="outlined"
              severity="error"
              sx={{ mb: "10px", alignItems: "center" }}
            >
              <Typography variant="h6">
                {t("athlete-dashboard.invoice-overdue")}
              </Typography>

              <Typography variant="body2">
                {t("athlete-dashboard.invoice-overdue-description")}
              </Typography>
            </Alert>
          )}

          {data?.curentWorkoutProgram?.invoiceDueDateTime &&
            !data?.curentWorkoutProgram?.invoiceIsOverdue &&
            data?.curentWorkoutProgram?.invoiceStatus === "open" && (
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ mb: "10px", alignItems: "center" }}
              >
                <Typography variant="h6">
                  {t("athlete-dashboard.invoice-due-warning")}:{" "}
                  {dayjs(data?.curentWorkoutProgram?.invoiceDueDateTime).format(
                    "Do MMMM YYYY"
                  )}
                </Typography>

                <Typography variant="body2">
                  {t("athlete-dashboard.invoice-due-warning-description")}
                </Typography>
              </Alert>
            )}
        </Box>
        <TabContext value={tabValue}>
          {workoutTemplatesData && workoutTemplatesData.length > 0 && (
            <TabList
              onChange={handleTabChange}
              aria-label="Workouts"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                mb: 2,
                maxWidth: "100%",
                ".MuiTabs-scroller": {
                  overflowX: "auto !important",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                },
              }}
            >
              <Tab
                label="Active"
                value="active"
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="On Demand"
                value="onDemand"
                sx={{ textTransform: "none" }}
              />
            </TabList>
          )}
          <TabPanel value="active" sx={{ p: 0 }}>
            {data?.remainingWorkouts?.length ? (
              <>
                <Typography variant="h6">Next workout</Typography>
                <SessionCard
                  item={data?.remainingWorkouts[0]}
                  id={data?.remainingWorkouts[0].id}
                  image={Images.CurrentSession}
                  dayNumber={data?.remainingWorkouts[0].dayNumber}
                  weekNumber={data?.remainingWorkouts[0].weekNumber}
                  workoutStatus={data?.remainingWorkouts[0].workoutStatus}
                  title={data?.remainingWorkouts[0].title}
                />
              </>
            ) : (
              <Alert
                variant="outlined"
                severity="success"
                sx={{ mt: 1, mb: "10px", alignItems: "center" }}
              >
                <Typography variant="h6">
                  {t("athlete-dashboard.awesome")}
                </Typography>

                <Typography variant="body2">
                  {t("athlete-dashboard.description")}
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body2">
                  You can also select from a list of on-demand workouts that
                  have been created by leading coaches and trainers.
                </Typography>
              </Alert>
            )}
            {data?.curentWorkoutProgram?.invoiceIsOverdue === false && (
              <>
                <ActionButton
                  isActive={activeIndex === 1}
                  title="Upcoming workouts"
                  onPress={() => setActiveIndex(activeIndex === 1 ? null : 1)}
                />

                <Collapse in={activeIndex === 1} timeout="auto" unmountOnExit>
                  {data?.remainingWorkouts?.length > 1 ? (
                    <WorkoutList
                      workouts={data?.remainingWorkouts.slice(1) || []}
                    />
                  ) : (
                    <Alert
                      variant="outlined"
                      severity="success"
                      sx={{ mt: 1, mb: "10px", alignItems: "center" }}
                    >
                      <Typography variant="h6">
                        {t("athlete-dashboard.awesome")}
                      </Typography>

                      <Typography variant="body2">
                        {t("athlete-dashboard.description")}
                      </Typography>
                    </Alert>
                  )}
                </Collapse>
              </>
            )}
            {!!data?.completedWorkouts &&
              data?.completedWorkouts?.length !== 0 && (
                <>
                  <ActionButton
                    isActive={activeIndex === 2}
                    title="Completed workouts"
                    onPress={() => setActiveIndex(activeIndex === 2 ? null : 2)}
                  />

                  <Collapse in={activeIndex === 2} timeout="auto" unmountOnExit>
                    <WorkoutList
                      workouts={data?.completedWorkouts}
                      isCompleted
                    />
                  </Collapse>
                </>
              )}
          </TabPanel>
          <TabPanel value="onDemand" sx={{ p: 0 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1">
                Select from a list of on-demand workouts that have been created
                by leading coaches and trainers.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid xs={6} item>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="difficulty-filter-label">
                    Difficulty
                  </InputLabel>
                  <Select
                    labelId="difficulty-filter-label"
                    id="difficulty-filter"
                    value={difficultyFilter}
                    label="Difficulty"
                    onChange={handleDifficultyChange}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advanced</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} item>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="days-filter-label">Days</InputLabel>
                  <Select
                    labelId="days-filter-label"
                    id="days-filter"
                    value={daysFilter}
                    label="Days"
                    onChange={handleDaysChange}
                  >
                    <MenuItem value="">All</MenuItem>
                    {uniqueDays.map((days) => (
                      <MenuItem key={days} value={days}>
                        {days} days
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {workoutTemplatesIsLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Typography variant="h6">Loading...</Typography>
              </Box>
            ) : (
              <Box>
                {filteredWorkoutTemplates?.map((workoutTemplate) => (
                  <Card
                    key={workoutTemplate.id}
                    onClick={() => {
                      setSelectedWorkoutTemplate(workoutTemplate);
                      setOpenWorkoutTemplatePreview(true);
                    }}
                    sx={{
                      width: "100%",
                      height: "184px",
                      position: "relative",
                      mb: "16px",
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                      image={workoutTemplate.imageUrl}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                      }}
                    />
                    <CardContent
                      sx={{
                        position: "absolute",
                        top: 30,
                        width: "100%",
                        height: "60%",
                        padding: "0px 20px 0px 20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ height: "120%" }}>
                          <Box
                            sx={{
                              mb: 2,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                px: 1.5,
                                py: 0.5,
                                borderRadius: "12px",
                                backgroundColor: (() => {
                                  switch (workoutTemplate.difficulty) {
                                    case "Beginner":
                                      return "#4caf50";
                                    case "Intermediate":
                                      return "#ff9800";
                                    default:
                                      return "#f44336";
                                  }
                                })(),
                                color: "#fff",
                                mr: 1,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.875rem",
                                }}
                              >
                                {workoutTemplate.difficulty}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "140px",
                                height: "24px",
                                borderRadius: "16px",
                                bgcolor: `${Colors.blue[100]}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {!!workoutTemplate.weeksInProgram &&
                                !!workoutTemplate.daysInProgram && (
                                  <Typography
                                    sx={{
                                      fontFamily: "Inter",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                      lineHeight: "18px",
                                      letterSpacing: "0.16px",
                                      color: `${Colors.blue[1300]}`,
                                    }}
                                  >
                                    {workoutTemplate.daysInProgram === 1 &&
                                    workoutTemplate.weeksInProgram === 1
                                      ? "On demand"
                                      : `${workoutTemplate.daysInProgram} days / ${workoutTemplate.weeksInProgram} weeks`}
                                  </Typography>
                                )}
                            </Box>
                          </Box>

                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "28px",
                              color: `${Colors.gray[100]}`,
                              mb: "8px",
                              textShadow: "0px 12px 12px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {workoutTemplate.name}
                          </Typography>
                          <Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Avatar
                                src={workoutTemplate.coachProfileImageUrl}
                                sx={{ mr: 2 }}
                              />
                              <Typography
                                sx={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  lineHeight: "28px",
                                  color: `${Colors.gray[100]}`,
                                  mb: "8px",
                                  textShadow:
                                    "0px 12px 12px rgba(0, 0, 0, 0.25)",
                                }}
                              >
                                {workoutTemplate.coachName}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
          </TabPanel>
        </TabContext>
      </Box>
      {selectedWorkoutTemplate && (
        <WorkoutTemplatePreview
          open={openWorkoutTemplatePreview}
          onClose={() => setOpenWorkoutTemplatePreview(false)}
          workoutTemplate={selectedWorkoutTemplate}
          currentWorkoutProgram={data?.curentWorkoutProgram}
        />
      )}
    </Box>
  ) : (
    <WorkoutPageSkeleton />
  );
};

export { Workouts };
