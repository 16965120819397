// UserProfileNoteTextEditor.tsx

import { FC, useRef, useState, useEffect } from "react";
import hash from "hash.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Box } from "@mui/system";
import { CheckCircleOutline } from "@mui/icons-material";
import { CircularProgress, Skeleton, Typography, Button } from "@mui/material";
import {
  UpdateNoteParams,
  useGetNoteByIdQuery,
  useUpdateNoteMutation,
} from "shared/api";
import { MenuBar } from "./UserNotesMenuBar";
import { UserNoteFormValues } from "./UserNoteFormValues";

type UserProfileNoteTextEditorProps = {
  userNoteId: number;
};

export const UserProfileNoteTextEditor: FC<UserProfileNoteTextEditorProps> = ({
  userNoteId,
}) => {
  dayjs.extend(utc);

  const { data: note, isLoading, error } = useGetNoteByIdQuery(userNoteId);
  const [updateNoteMutation] = useUpdateNoteMutation();

  const [noteDate, setNoteDate] = useState<string>("");
  const [titleToChange, setTitleToChange] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [statusIndicator, setStatusIndicator] = useState<JSX.Element | null>(
    null
  );

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: "",
  });

  // Update local state and editor content when note data changes
  useEffect(() => {
    if (note && editor) {
      setNoteDate(
        note.dateTime || dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      );
      setTitleToChange(note.title || "");
      editor.commands.setContent(note.content || "");
    }
  }, [note, editor]);

  const handleDateChange = (date: string | null) => {
    setNoteDate(dayjs.utc(date).format("YYYY-MM-DDTHH:mm:ss[Z]"));
  };

  const handleTitleChange = (title: string) => {
    setTitleToChange(title);
  };

  const onSubmit = async () => {
    if (!note || !note.id || !editor) return;

    setIsSaving(true);
    setIsSuccess(false);
    try {
      const updatedNote: UpdateNoteParams = {
        id: note.id,
        dateTime: noteDate || note.dateTime,
        title: titleToChange || note.title,
        content: editor.getHTML(),
        noteType: note.noteType,
      };

      await updateNoteMutation(updatedNote).unwrap();
      setIsSuccess(true);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setIsSaving(false);
      setTimeout(() => setIsSuccess(false), 2000);
      setTimeout(() => setErrorMessage(""), 2000);
    }
  };

  useEffect(() => {
    if (isSaving) {
      setStatusIndicator(<CircularProgress size={24} sx={{ ml: 2 }} />);
    } else if (isSuccess) {
      setStatusIndicator(<CheckCircleOutline color="success" sx={{ ml: 2 }} />);
    } else {
      setStatusIndicator(null);
    }
  }, [isSaving, isSuccess]);

  if (isLoading || !editor) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "900px", width: "100%", mb: "12px" }}
      />
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        Error loading note.
      </Typography>
    );
  }

  return (
    <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 4 }}>
      <UserNoteFormValues
        titleToChange={titleToChange}
        noteDate={noteDate}
        handleDateChange={handleDateChange}
        handleTitleChange={handleTitleChange}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <MenuBar editor={editor} />
      </Box>

      <Button
        variant="outlined"
        onClick={onSubmit}
        disabled={isSaving}
        startIcon={isSaving ? <CircularProgress size={20} /> : null}
        sx={{
          pointerEvents: isSaving ? "none" : "auto",
          opacity: isSaving ? 0.7 : 1,
          textTransform: "none",
        }}
      >
        {isSaving ? "Saving..." : "Save"}
      </Button>
      {statusIndicator}
      <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            flexGrow: 1,
            borderRadius: 1,
            "& .ProseMirror": {
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              padding: 2,
              height: "calc(100vh - 500px)",
              overflow: "auto",
              pointerEvents: isSaving ? "none" : "auto",
              opacity: isSaving ? 0.7 : 1,
            },
          }}
        >
          <EditorContent editor={editor} />
        </Box>
      </Box>
    </Box>
  );
};
