import React, { useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import {
  useGetChallengesQuery,
  useGetCurrentActiveChallengeQuery,
  ChallengesRequestParams,
  ChallengeDashboardResponse,
} from "shared/api";
import { getGraphicSvg } from "shared/themes";
import { EmployeeChallengeContainer } from "pages/Dashboard/AthleteDashboard/EmployeeChallengeContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`challenge-tabpanel-${index}`}
      aria-labelledby={`challenge-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `challenge-tab-${index}`,
    "aria-controls": `challenge-tabpanel-${index}`,
  };
}

export interface AthleteChallengesProps {
  condensed?: boolean;
  activeOnly?: boolean;
  hideStatus?: boolean;
}

export const AthleteChallenges = ({
  condensed,
  activeOnly,
  hideStatus,
}: AthleteChallengesProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [challengeDialogOpen, setChallengeDialogOpen] = useState(false);
  const [selectedChallenge, setSelectedChallenge] =
    useState<ChallengeDashboardResponse>();

  const { data: challengesData, isLoading: challengesLoading } =
    useGetChallengesQuery({} as ChallengesRequestParams);

  const {
    data: currentActiveChallengeData,
    isLoading: currentActiveChallengeLoading,
  } = useGetCurrentActiveChallengeQuery();

  const activeChallenges =
    challengesData?.filter(
      (challenge) => challenge?.challengeStatus?.toString() === "Active"
    ) || [];

  const scheduledChallenges =
    challengesData?.filter(
      (challenge) => challenge?.challengeStatus?.toString() === "Scheduled"
    ) || [];

  const completedChallenges =
    challengesData?.filter(
      (challenge) => challenge?.challengeStatus?.toString() === "Completed"
    ) || [];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCloseChallengeDialog = () => {
    setChallengeDialogOpen(false);
  };

  const handleChallengeClick = (challenge: any) => {
    setChallengeDialogOpen(true);
    setSelectedChallenge({
      activeChallenge: challenge,
      userRank: null,
      userScore: null,
      totalParticipants: null,
      challengeTargetType: challenge.challengeTargetTypeFormatted,
    } as unknown as ChallengeDashboardResponse);
  };

  const renderChallengeCards = (challenges: any[]) => {
    if (!challenges || challenges.length === 0) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary" align="center">
            No challenges available in this category.
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        {challenges.map((challenge) => (
          <Card
            key={challenge.id}
            sx={{
              width: "100%",
              maxWidth: 600,
              borderRadius: 3,
              overflow: "hidden",
              position: "relative",
              mb: 2,
              cursor: "pointer",
              "&:hover": {
                boxShadow: 6,
              },
            }}
            onClick={() => handleChallengeClick(challenge)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: { xs: "100%", md: "40%" },
                  height: { xs: 200, md: "auto" },
                  maxHeight: { md: 200 },
                }}
                image={challenge.imageUrl || ""}
                alt={challenge.name || "Challenge Image"}
              />
              <CardContent
                sx={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <Box sx={{ mb: 1 }}>
                  <Typography variant="h6" component="div">
                    {challenge.name || "No Challenge Available"}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {challenge.startDate && challenge.endDate
                      ? `${dayjs(challenge.startDate).format(
                          "MMM D, YYYY"
                        )} - ${dayjs(challenge.endDate).format("MMM D, YYYY")}`
                      : "No Date Available"}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {challenge.description || "No description available."}
                </Typography>
                <Box sx={{ mt: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChallengeClick(challenge);
                    }}
                  >
                    View challenge
                  </Button>
                </Box>
              </CardContent>
            </Box>
          </Card>
        ))}
      </Box>
    );
  };

  if (challengesLoading || currentActiveChallengeLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <Typography variant="body1">Loading challenges...</Typography>
      </Box>
    );
  }

  if (activeOnly) {
    return (
      <Box component="div" sx={{ p: 2 }}>
        {renderChallengeCards(activeChallenges)}
        {selectedChallenge && (
          <EmployeeChallengeContainer
            open={challengeDialogOpen}
            onClose={handleCloseChallengeDialog}
            activeChallenge={selectedChallenge}
          />
        )}
      </Box>
    );
  }

  return (
    <Box component="div" sx={{ width: "100%" }}>
      <Paper
        square
        elevation={0}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="Challenge categories"
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontWeight: "medium",
            },
          }}
        >
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Upcoming" {...a11yProps(1)} />
          <Tab label="Completed" {...a11yProps(2)} />
        </Tabs>
      </Paper>

      <Box sx={{ p: 2 }}>
        <TabPanel value={tabValue} index={0}>
          {renderChallengeCards(activeChallenges)}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {renderChallengeCards(scheduledChallenges)}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {renderChallengeCards(completedChallenges)}
        </TabPanel>
      </Box>

      {selectedChallenge && (
        <EmployeeChallengeContainer
          open={challengeDialogOpen}
          onClose={handleCloseChallengeDialog}
          activeChallenge={selectedChallenge}
        />
      )}
    </Box>
  );
};
