import { FC, useState, MouseEvent } from "react";
import dayjs from "dayjs";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CardContent,
  Card,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import {
  UserProfileItemResponse,
  useGetBillingProductsForUserQuery,
  useSendDirectDebitFormToUserMutation,
} from "shared/api";
import { Colors, getGraphicSvg } from "shared/themes";
import { LoadingButton } from "@mui/lab";

interface UserProfileBillingProps {
  currentUserData: UserProfileItemResponse;
}

const UserProfileBilling: FC<UserProfileBillingProps> = ({
  currentUserData,
}) => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  // State for Date Picker Modal
  const [openDateModal, setOpenDateModal] = useState(false);
  const [selectedContractStartDate, setSelectedContractStartDate] =
    useState<string>(dayjs().format("YYYY-MM-DD"));

  // State for Menu (Burger Icon)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBillingProductId, setSelectedBillingProductId] = useState<
    number | null
  >(null);
  const [selectedBillingProductPriceId, setSelectedBillingProductPriceId] =
    useState<number | null>(null);

  const loggedInUserIsAdminOrCoach = useTypedSelector(
    userSelectors.isAdminOrCoach
  );
  const { data: billingProducts, isLoading: isLoadingBillingProducts } =
    useGetBillingProductsForUserQuery(currentUserData?.id);

  const [sendDirectDebitFormToUser, { isLoading: isSendingDirectDebitForm }] =
    useSendDirectDebitFormToUserMutation();

  // Open Action Menu
  const handleOpenMenu = (
    event: MouseEvent<HTMLElement>,
    billingProductId: number,
    billingProductPriceId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedBillingProductId(billingProductId);
    setSelectedBillingProductPriceId(billingProductPriceId);
  };

  // Close Action Menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Date Picker Modal
  const handleOpenDateModal = () => {
    setOpenDateModal(true);
    handleCloseMenu(); // Close the menu after selecting an action
  };

  // Close Date Picker Modal
  const handleCloseDateModal = () => {
    setOpenDateModal(false);
    setSelectedContractStartDate("");
  };

  // Handle Submission with Contract Start Date
  const handleSubmit = async () => {
    if (!selectedContractStartDate) {
      setErrorMessage("Please select a Contract Start Date.");
      return;
    }

    if (!selectedBillingProductId || !selectedBillingProductPriceId) {
      setErrorMessage("Something went wrong. Please try again.");
      return;
    }

    setSuccessMessage("");
    setErrorMessage("");

    try {
      await sendDirectDebitFormToUser({
        billingProductId: selectedBillingProductId,
        billingProductPriceId: selectedBillingProductPriceId,
        userIdToSendEmailTo: currentUserData.id,
        contractStartDate: selectedContractStartDate, // Passing the selected date
      });
      setSuccessMessage("Direct debit form has been sent successfully.");
      setOpenDateModal(false); // Close the modal on success
    } catch (error) {
      setErrorMessage("Failed to send the direct debit form.");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            bgcolor: Colors.gray[100],
            mt: "20px",
            padding: "20px",
            height: "auto",
            width: "100%",
          }}
        >
          {loggedInUserIsAdminOrCoach && (
            <>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: Colors.gray[900],
                  mb: 2,
                }}
              >
                Memberships
              </Typography>

              <Grid container spacing={2}>
                {!isLoadingBillingProducts && !billingProducts?.length ? (
                  <Grid xs={12} item>
                    <Box>
                      <Box
                        component="img"
                        src={getGraphicSvg("thinking")}
                        alt="Thinking"
                        sx={{ height: "110px", mb: 2 }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        There are no memberships for the user. Please create a
                        new one.
                      </Typography>
                    </Box>
                  </Grid>
                ) : null}
                {!isLoadingBillingProducts &&
                  billingProducts?.length &&
                  billingProducts?.map((billingMembership) => (
                    <Grid key={billingMembership.id} item xs={12} sm={6} md={3}>
                      <Card
                        sx={{
                          width: "100%",
                          height: "180px",
                          mb: "16px",
                          border: "1px solid #EEEEEE",
                          borderRadius: "8px",
                          boxShadow: "none",
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                        }}
                      >
                        {/* Action Menu Icon */}
                        <IconButton
                          aria-label="more"
                          onClick={(event) =>
                            handleOpenMenu(
                              event,
                              billingMembership.id,
                              billingMembership.activeBillingProductPrices[0].id
                            )
                          }
                          sx={{ position: "absolute", top: 8, right: 8 }}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              color: Colors.oxford[1100],
                            }}
                          >
                            {billingMembership.name}
                          </Typography>

                          {billingMembership.activeBillingProductPrices && (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: Colors.gray[1100],
                                mt: "4px",
                              }}
                            >
                              $
                              {
                                billingMembership.activeBillingProductPrices[0]
                                  .price
                              }{" "}
                              {
                                billingMembership.activeBillingProductPrices[0]
                                  .billingFrequency
                              }{" "}
                              -{" "}
                              {
                                billingMembership.activeBillingProductPrices[0]
                                  .description
                              }
                            </Typography>
                          )}

                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 300,
                              color: Colors.oxford[1100],
                              mt: "12px",
                            }}
                          >
                            {billingMembership.description}
                          </Typography>
                        </CardContent>
                      </Card>

                      {/* Action Menu */}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem onClick={handleOpenDateModal}>
                          Email Direct Debit Form
                        </MenuItem>
                      </Menu>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </Box>
      </Grid>

      {/* Date Picker Modal */}
      <Dialog open={openDateModal} onClose={handleCloseDateModal}>
        <DialogTitle>Email Direct Debit Form</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2" color="text.secondary">
              This will send a Direct Debit Authorisation Form to the user for
              them to complete.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2, fontWeight: 600 }}
            >
              Membership
            </Typography>
            <TextField
              fullWidth
              value={`${
                billingProducts?.find(
                  (product) => product.id === selectedBillingProductId
                )?.name
              } - $${
                billingProducts?.find(
                  (product) => product.id === selectedBillingProductId
                )?.activeBillingProductPrices[0].price
              } ${
                billingProducts?.find(
                  (product) => product.id === selectedBillingProductId
                )?.activeBillingProductPrices[0].billingFrequency
              }`}
              disabled
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2, fontWeight: 600 }}
            >
              Contract start date
            </Typography>
            <TextField
              type="date"
              fullWidth
              value={selectedContractStartDate}
              onChange={(e) => setSelectedContractStartDate(e.target.value)}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2, mb: 2 }}
            >
              Note: If the selected contract date falls on a weekend, the date
              will automatically be moved to the next business day.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={handleCloseDateModal}
            color="secondary"
            sx={{ textTransform: "none" }}
            disabled={isSendingDirectDebitForm}
            loading={isSendingDirectDebitForm}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={handleSubmit}
            color="primary"
            sx={{ textTransform: "none" }}
            disabled={isSendingDirectDebitForm}
            loading={isSendingDirectDebitForm}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {errorMessage && (
        <Alert sx={{ mt: 3, width: "100%" }} severity="error">
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert sx={{ mt: 3, width: "100%" }} severity="success">
          {successMessage}
        </Alert>
      )}
    </Grid>
  );
};

export { UserProfileBilling };
