import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { useDeleteUserMutation } from "shared/api";
import { Colors } from "shared/themes";
import { UserProfileItemResponse } from "shared/api/user/models";

interface DeleteUserModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  user?: UserProfileItemResponse;
  onSuccess?: () => void;
}

const DeleteUserModal: FC<DeleteUserModalProps> = ({
  isOpenModal,
  handleCloseModal,
  user,
  onSuccess,
}) => {
  const [deleteUser, { isLoading }] = useDeleteUserMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (isOpenModal) {
      setConfirmDelete(false);
    }
  }, [isOpenModal]);

  const handleDelete = async () => {
    if (!user?.id) return;

    try {
      await deleteUser(user.id).unwrap();
      setSuccessMessage("User successfully deleted");
      if (onSuccess) {
        setTimeout(() => {
          onSuccess();
        }, 1500);
      }
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || "An error occurred. Please try again later"
      );
    } finally {
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 1500);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: Colors.red[200],
              }}
            >
              Delete User: {user?.firstName} {user?.lastName}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 2,
              mb: 1,
            }}
          >
            Warning: This action cannot be undone. The user and all associated
            data will be permanently deleted.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.checked)}
                sx={{
                  color: Colors.red[200],
                  "&.Mui-checked": {
                    color: Colors.red[200],
                  },
                }}
              />
            }
            label="I understand that this action is irreversible and I want to delete this user"
            sx={{
              mb: 3,
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: Colors.red[200],
              },
            }}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              disabled={!confirmDelete || isLoading}
              onClick={() => handleDelete()}
              sx={{ textTransform: "none" }}
            >
              Delete
            </Button>
          </Box>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { DeleteUserModal };
