export interface InvoiceRequestParams {
  page?: number;
  count?: number;
  customerId?: string;
  countOnly?: boolean;
}

export interface ClientInvoice {
  id: string;
  amountDue: number;
  customerId: string;
  customerName: string;
  customerEmail: string;
  dueDate: string;
  status: string;
}

export interface UpdateClientInvoiceParams {
  invoiceId: string;
  invoiceStatus: string;
}

export enum BillingProductType {
  Undefined = 0,
  Membership = 1,
  Product = 2,
  Service = 3,
}

export enum BillingProductStatus {
  Undefined = 0,
  Active = 1,
  Inactive = 2,
  Archived = 3,
}

export enum BillingFrequency {
  Undefined = 0,
  Weekly = 1,
  Fortnightly = 2,
  Monthly = 3,
  Quarterly = 4,
  Biannually = 5,
  Annually = 6,
}

export interface CreateBillingProductPriceRequest {
  price: number;
  description: string;
  billingFrequency: BillingFrequency;
  ddrPageUrl: string;
  defaultPrice?: boolean;
}

export interface CreateBillingProductRequest {
  name: string;
  description: string;
  billingProductType: BillingProductType;
  billingProductPrices: CreateBillingProductPriceRequest[];
}

export interface BillingProductRequestParams {
  billingProductType?: BillingProductType;
  page?: number;
  count?: number;
  countOnly?: boolean;
}

export interface BillingProductPrice {
  id: number;
  price: number;
  currency: string;
  description: string;
  ddrPageUrl: string;
  freeTrialDays?: number;
  billingProductId: number;
  billingFrequency: BillingFrequency;
}

export interface BillingProduct {
  id: number;
  name: string;
  description: string;
  defaultBillingProductPriceId?: number;
  billingProductType: BillingProductType;
  billingProductStatus: BillingProductStatus;
  organisationId: number;
  billingProductPrices: BillingProductPrice[];
  activeBillingProductPrices: BillingProductPrice[];
}

export interface AddUserToBillingProductRequest {
  billingProductUserId: number;
  userIdToAdd: number;
  billingProductId: number;
  billingProductPriceId: number;
}

export interface BillingProductPriceUser {
  id: number;
  userId: number;
  isActive?: boolean;
  billingProductPrice: BillingProductPrice;
  billingProductPriceId: number;
  billingProductId: number;
  organisationId: number;
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
  activeBillingProductPrice: BillingProductPrice;
}

export interface SendDirectDebitFormRequest {
  userIdToSendEmailTo: number;
  billingProductId: number;
  billingProductPriceId: number;
  contractStartDate?: string;
}

export interface SubscriptionStatusResponse {
  organisationId: number;
  customerId: string;
  subscriptionId: string;
  subscriptionStatus: string;
  dueDate: string;
  lockOutDate: string;
  overdue: boolean;
  lockedOut: boolean;
}
