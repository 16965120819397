import React, { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Icon,
  Rating,
  TextField,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Colors } from "shared/themes";
import { WorkoutSession, useSendFeedbackMutation } from "shared/api";
import { Routes } from "shared/routers";
import confetti from "canvas-confetti";
import { SubmitFeedback } from "./SubmitFeedback";

// Format the feedback with structured data first
const difficultyEmojis = {
  "too easy": "💤",
  "just right": "🙂",
  "too hard": "😫",
};

const energyEmojis = {
  drained: "😴",
  normal: "😌",
  energized: "😎",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FeedbackProps {
  showFeedback: boolean;
  finishedWorkout: WorkoutSession;
  workoutId: number;
  linkTo?: string;
}

interface SubmitValues {
  description: string;
  rating: number | undefined;
  difficulty: string | undefined;
  energyLevel: string | undefined;
  workoutProgramId: number;
  workoutId: number;
}

const FeedbackSchema = Yup.object().shape({
  rating: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable()
    .min(1, "This field is required")
    .max(5, "This field is required")
    .required("This field is required"),
  difficulty: Yup.string()
    .nullable()
    .required("Please select how challenging the workout was"),
  energyLevel: Yup.string()
    .nullable()
    .required("Please select how you felt after the workout"),
  description: Yup.string().nullable(),
});

const Feedback: FC<FeedbackProps> = ({
  showFeedback,
  finishedWorkout,
  workoutId,
  linkTo = Routes.dashboard.url,
}) => {
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();
  const [feedbackFinalStep, setFeedbackFinalStep] = useState(false);

  useEffect(() => {
    if (showFeedback) {
      confetti({
        particleCount: 150,
        spread: 70,
        origin: { y: 0.3 },
        zIndex: 2000,
        disableForReducedMotion: true,
        ticks: 500,
      });
    }
  }, [showFeedback]);

  const onSubmit = async (params: SubmitValues) => {
    try {
      if (
        params.rating === undefined ||
        params.difficulty === undefined ||
        params.energyLevel === undefined
      ) {
        return;
      }

      const stars =
        "★".repeat(params.rating || 0) + "☆".repeat(5 - (params.rating || 0));

      const formattedDescription = [
        `${stars}`,
        `Difficulty: ${
          difficultyEmojis[params.difficulty as keyof typeof difficultyEmojis]
        } ${params.difficulty}`,
        `Energy Level: ${
          energyEmojis[params.energyLevel as keyof typeof energyEmojis]
        } ${params.energyLevel}`,
        params.description ? `\nComments:\n${params.description}` : "",
      ].join("\n");

      await sendFeedback({
        ...params,
        rating: params.rating,
        description: formattedDescription,
      }).unwrap();
      setFeedbackFinalStep(true);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        description: "",
        rating: undefined,
        difficulty: undefined,
        energyLevel: undefined,
        workoutProgramId: finishedWorkout.workoutProgramId,
        workoutId,
      },
      validationSchema: FeedbackSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    });

  return (
    <Dialog fullScreen open={showFeedback} TransitionComponent={Transition}>
      <AppBar
        sx={{
          position: "relative",
          pt: 7,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Leave feedback
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.25rem",
                lineHeight: "140%",
                color: Colors.gray[1000],
                mb: 2,
                textAlign: "left",
              }}
            >
              Woohoo! You crushed this!
            </Typography>
            <Box
              sx={{
                width: "100%",
                mb: "16px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "140%",
                  color: Colors.gray[1000],
                  mb: "8px",
                }}
              >
                How was your workout?
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Rating
                  value={Number(values.rating)}
                  onBlur={handleBlur("rating")}
                  onChange={handleChange("rating")}
                  icon={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span style={{ fontSize: "2.5rem" }}>★</span>
                    </Box>
                  }
                  emptyIcon={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span style={{ fontSize: "2.5rem" }}>☆</span>
                    </Box>
                  }
                  IconContainerComponent={({ value, ...props }) => {
                    const labels = [
                      "Poor",
                      "Not great",
                      "Okay",
                      "Good",
                      "Great",
                    ];
                    return (
                      <span {...props}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 1.5,
                          }}
                        >
                          {props.children}
                          <Typography
                            sx={{
                              fontSize: "0.875rem",
                              color: "rgb(145, 145, 145)",
                              textAlign: "center",
                            }}
                          >
                            {labels[value - 1]}
                          </Typography>
                        </Box>
                      </span>
                    );
                  }}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    px: 2,
                    "& .MuiRating-icon": {
                      margin: 0,
                    },
                  }}
                />
              </Box>
              {errors.rating && (
                <Box
                  sx={{
                    color: Colors.red[200],
                    fontSize: "0.875rem",
                    mb: 2,
                    mt: 1,
                  }}
                >
                  {errors.rating}
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ width: "100%", mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1rem",
                lineHeight: "140%",
                color: Colors.gray[1000],
                mb: 2,
              }}
            >
              How challenging was this workout?
            </Typography>
            <ToggleButtonGroup
              value={values.difficulty}
              exclusive
              onChange={(e, value) => {
                e?.preventDefault();
                handleChange({
                  target: { name: "difficulty", value },
                });
              }}
              aria-label="workout difficulty"
              sx={{
                width: "100%",
                display: "flex",
                gap: 2,
                "& .MuiToggleButton-root": {
                  flex: 1,
                  py: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  textTransform: "none",
                  fontSize: "0.9rem",
                  border: "none",
                  borderRadius: 2,
                  backgroundColor: Colors.gray[50],
                  "&:hover": {
                    backgroundColor: Colors.gray[100],
                  },
                  "&.Mui-selected": {
                    backgroundColor: Colors.blue[200],
                    color: Colors.blue[700],
                    "&:hover": {
                      backgroundColor: Colors.blue[200],
                    },
                  },
                },
                "& .MuiToggleButtonGroup-grouped": {
                  border: "none",
                  "&:not(:first-of-type)": {
                    borderRadius: 2,
                  },
                  "&:first-of-type": {
                    borderRadius: 2,
                  },
                },
              }}
            >
              <ToggleButton value="too easy">
                <Box sx={{ fontSize: "1.5rem" }}>
                  {difficultyEmojis["too easy"]}
                </Box>
                <Box>Too easy</Box>
              </ToggleButton>
              <ToggleButton value="just right">
                <Box sx={{ fontSize: "1.5rem" }}>
                  {difficultyEmojis["just right"]}
                </Box>
                <Box>Just right</Box>
              </ToggleButton>
              <ToggleButton value="too hard">
                <Box sx={{ fontSize: "1.5rem" }}>
                  {difficultyEmojis["too hard"]}
                </Box>
                <Box>Too hard</Box>
              </ToggleButton>
            </ToggleButtonGroup>
            {errors.difficulty && (
              <Box
                sx={{
                  color: Colors.red[200],
                  fontSize: "0.875rem",
                  mt: 1,
                }}
              >
                {errors.difficulty}
              </Box>
            )}
          </Box>

          <Box sx={{ width: "100%", mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1rem",
                lineHeight: "140%",
                color: Colors.gray[1000],
                mb: 2,
              }}
            >
              How did you feel after this workout?
            </Typography>
            <ToggleButtonGroup
              value={values.energyLevel}
              exclusive
              onChange={(e, value) => {
                e?.preventDefault();
                handleChange({
                  target: { name: "energyLevel", value },
                });
              }}
              aria-label="energy level"
              sx={{
                width: "100%",
                display: "flex",
                gap: 2,
                "& .MuiToggleButton-root": {
                  flex: 1,
                  py: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  textTransform: "none",
                  fontSize: "0.9rem",
                  border: "none",
                  borderRadius: 2,
                  backgroundColor: Colors.gray[50],
                  "&:hover": {
                    backgroundColor: Colors.gray[100],
                  },
                  "&.Mui-selected": {
                    backgroundColor: Colors.blue[200],
                    color: Colors.blue[700],
                    "&:hover": {
                      backgroundColor: Colors.blue[200],
                    },
                  },
                },
                "& .MuiToggleButtonGroup-grouped": {
                  border: "none",
                  "&:not(:first-of-type)": {
                    borderRadius: 2,
                  },
                  "&:first-of-type": {
                    borderRadius: 2,
                  },
                },
              }}
            >
              <ToggleButton value="drained">
                <Box sx={{ fontSize: "1.5rem" }}>{energyEmojis.drained}</Box>
                <Box>Drained</Box>
              </ToggleButton>
              <ToggleButton value="normal">
                <Box sx={{ fontSize: "1.5rem" }}>{energyEmojis.normal}</Box>
                <Box>Normal</Box>
              </ToggleButton>
              <ToggleButton value="energized">
                <Box sx={{ fontSize: "1.5rem" }}>{energyEmojis.energized}</Box>
                <Box>Energized</Box>
              </ToggleButton>
            </ToggleButtonGroup>
            {errors.energyLevel && (
              <Box
                sx={{
                  color: Colors.red[200],
                  fontSize: "0.875rem",
                  mt: 1,
                }}
              >
                {errors.energyLevel}
              </Box>
            )}
          </Box>

          <Typography
            sx={{
              width: "100%",
              fontWeight: "500",
              fontSize: "1rem",
              lineHeight: "140%",
              color: Colors.gray[1000],
              mb: 1,
              textAlign: "left",
            }}
          >
            Further comments
          </Typography>

          <TextField
            variant="outlined"
            name="description"
            fullWidth
            multiline
            rows={4}
            type="text"
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />

          <Button
            onClick={() => handleSubmit()}
            disabled={isLoading}
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              height: "48px",
              textTransform: "none",
              fontWeight: "500",
              fontSize: "1rem",
              lineHeight: "26px",
              letterSpacing: "0.46px",
            }}
          >
            Submit feedback
            <Icon sx={{ ml: 1 }}>send_icon</Icon>
          </Button>
        </Box>
      </Box>

      {feedbackFinalStep && <SubmitFeedback linkTo={linkTo} />}
    </Dialog>
  );
};

export { Feedback };
