import { Button, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";

const WorkoutTemplateThankYouPage: FC = () => {
  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "56px",
          height: "56px",
          borderRadius: "50px",
          background: "rgba(76, 175, 80, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
          mt: 8,
        }}
      >
        <Box
          sx={{
            width: "32px",
            height: "32px",
            borderRadius: "50px",
            bgcolor: Colors.green[500],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon sx={{ color: Colors.gray[100] }}>check_icon</Icon>
        </Box>
      </Box>

      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "140%",
          textAlign: "center",
          color: Colors.gray[1200],
          mb: 3,
        }}
      >
        You are now ready to start your program.
      </Typography>

      <Button
        component={NavLink}
        to={Routes.workouts.url}
        reloadDocument
        sx={{
          width: "100%",
          height: "42px",
          color: Colors.blue[1200],
          textTransform: "none",
        }}
      >
        Start program
      </Button>
    </Box>
  );
};

export { WorkoutTemplateThankYouPage };
