import { FC, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import { CreateChallengeParams, ChallengeTargetType } from "shared/api";
import { useFormik } from "formik";

export enum ChallengeStatus {
  Undefined = 0,
  Active = 1,
  Inactive = 2,
}

export enum ChallengeCategory {
  Undefined = 0,
  Individual = 1,
  Team = 2,
}

interface AddChallengeModalProps {
  isOpenModal: boolean;
  isLoading: boolean;
  handleCloseModal: () => void;
  onSubmit: (params: CreateChallengeParams) => void;
}

const AddChallengeModal: FC<AddChallengeModalProps> = ({
  isOpenModal,
  isLoading,
  handleCloseModal,
  onSubmit: onParentSubmit,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      imageUrl: "",
      description: "",
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().add(7, "day").format("YYYY-MM-DD"),
      challengeTargetType: ChallengeTargetType.Undefined,
      challengeStatus: ChallengeStatus.Undefined,
      challengeCategory: ChallengeCategory.Undefined,
      targetValue: 0,
    },
    onSubmit: (params) => {
      onParentSubmit(params);
    },
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Create challenge
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              mb: 2,
            }}
          >
            This will create a new challenge for your organisation. You will be
            able to add people to the challenge once it is created.
          </Typography>

          {/* Name Input */}
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.name}
            helperText={errors.name}
            sx={{ mb: 2 }}
          />

          {/* Description Input */}
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.description}
            helperText={errors.description}
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          {/* Start Date Input */}
          <TextField
            fullWidth
            label="Start Date"
            name="startDate"
            type="date"
            value={values.startDate}
            onChange={(e) =>
              setFieldValue(
                "startDate",
                dayjs(e.target.value).format("YYYY-MM-DD")
              )
            }
            onBlur={handleBlur}
            error={!!errors.startDate}
            helperText={errors.startDate}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />

          {/* End Date Input */}
          <TextField
            fullWidth
            label="End Date"
            name="endDate"
            type="date"
            value={values.endDate}
            onChange={(e) =>
              setFieldValue(
                "endDate",
                dayjs(e.target.value).format("YYYY-MM-DD")
              )
            }
            onBlur={handleBlur}
            error={!!errors.endDate}
            helperText={errors.endDate}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />

          {/* Challenge Target Type Select */}
          <Select
            fullWidth
            name="challengeTargetType"
            value={values.challengeTargetType || ""}
            onChange={(e) =>
              setFieldValue("challengeTargetType", e.target.value)
            }
            onBlur={handleBlur}
            displayEmpty
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>
              Select Target Type
            </MenuItem>
            <MenuItem value={ChallengeTargetType.Wellbeing}>Wellbeing</MenuItem>
            <MenuItem value={ChallengeTargetType.Activity}>Activity</MenuItem>
            <MenuItem value={ChallengeTargetType.SleepQuality}>
              Sleep Quality
            </MenuItem>
            <MenuItem value={ChallengeTargetType.Steps}>Steps</MenuItem>
            <MenuItem value={ChallengeTargetType.ActiveCalories}>
              Active Calories
            </MenuItem>
            <MenuItem value={ChallengeTargetType.ActiveHours}>
              Active Hours
            </MenuItem>
            <MenuItem value={ChallengeTargetType.IntenseActivityDuration}>
              Intense Activity Duration Minutes
            </MenuItem>
          </Select>

          <Select
            fullWidth
            name="challengeCategory"
            value={values.challengeCategory || ""}
            onChange={(e) => setFieldValue("challengeCategory", e.target.value)}
            onBlur={handleBlur}
            displayEmpty
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>
              Select Category
            </MenuItem>
            <MenuItem value={ChallengeCategory.Individual}>Individual</MenuItem>
            <MenuItem value={ChallengeCategory.Team}>Team</MenuItem>
          </Select>

          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              mt: 2,
            }}
            onClick={() => handleSubmit()}
            loading={isLoading}
          >
            Create challenge
          </LoadingButton>

          {errorMessage && (
            <Alert
              sx={{ mt: 3, width: "100%" }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{ mt: 3, width: "100%" }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { AddChallengeModal };
