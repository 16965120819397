import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { WorkoutTemplateResponse } from "./models";

export const workoutTemplatesApi = createApi({
  reducerPath: "workoutTemplatesApi",
  baseQuery,
  tagTypes: ["WorkoutTemplates"],
  endpoints: (builder) => ({
    getWorkoutTemplate: builder.query<WorkoutTemplateResponse, number>({
      query: (templateId) => ({
        url: `/workout-template-library/${templateId}`,
        method: "GET",
      }),
      providesTags: ["WorkoutTemplates"],
    }),
    getWorkoutTemplates: builder.query<WorkoutTemplateResponse[], void>({
      query: () => ({
        url: "/workout-template-library",
        method: "GET",
      }),
      providesTags: ["WorkoutTemplates"],
    }),
    assignWorkoutTemplate: builder.mutation<void, number>({
      query: (templateId) => ({
        url: `/workout-template-library/${templateId}/assign`,
        method: "POST",
      }),
      invalidatesTags: ["WorkoutTemplates"],
    }),
  }),
});

export const {
  useGetWorkoutTemplateQuery,
  useGetWorkoutTemplatesQuery,
  useAssignWorkoutTemplateMutation,
} = workoutTemplatesApi;
