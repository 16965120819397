import { Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { FC, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";
import {
  CombinedData,
  WorkoutItemType,
  WorkoutLoadStatus,
  useQuickCompleteWorkoutItemMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { ChangeWorkoutDetailsModal } from "./ChangeWorkoutDetailsModal";
import { WorkoutTrendIcon } from "./WorkoutTrendIcon";

interface WorkoutListViewItemProps {
  exercise: CombinedData;
  selectedUserId: number;
}

const WorkoutListViewItem: FC<WorkoutListViewItemProps> = ({
  exercise,
  selectedUserId,
}) => {
  const { t } = useTranslation();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isResponseModalOpen, setResponseModal] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setResponseModal(false);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const [exerciseName, setExerciseName] = useState(exercise.exerciseName);

  const isExerciseCompleted = exercise.workoutItemSchemeBreakdowns.every(
    (item) =>
      item.workoutItemLogs.length > 0 &&
      item.workoutItemLogs.every(
        (log) =>
          log.completedReps ||
          log.completedPartialReps ||
          log.completedIntensity ||
          log.completedWeight ||
          log.completedRepsLeftSide ||
          log.completedRepsRightSide ||
          log.completedPartialRepsLeftSide ||
          log.completedPartialRepsRightSide ||
          log.completedIntensityLeftSide ||
          log.completedIntensityRightSide ||
          log.completedWeightLeftSide ||
          log.completedWeightRightSide ||
          log.completedDistance ||
          log.completedTime ||
          log.completedSpeed
      )
  );

  const displayTrend =
    exercise.workoutLoadStatus !== WorkoutLoadStatus.Undefined;

  const hasTrainerNotes = exercise.notes?.length > 0;

  const [isSwiped, setIsSwiped] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setIsSwiped(true);
    },
    onSwipedRight: () => {
      setIsSwiped(false);
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const [quickCompleteWorkoutItem, { isLoading: isQuickCompleting }] =
    useQuickCompleteWorkoutItemMutation();

  const handleQuickComplete = (exerciseItem: CombinedData) => {
    const uniqueWorkoutItemIds = exerciseItem.workoutItemSchemeBreakdowns
      .map((item) => item.workoutItemId)
      .filter((value, index, self) => self.indexOf(value) === index);

    Promise.all(
      uniqueWorkoutItemIds.map((id) => quickCompleteWorkoutItem(id).unwrap())
    )
      .then(() => {
        setSuccessMessage("Exercise completed successfully");
      })
      .catch((error: any) => {
        setErrorMessage(error?.data?.message || t("errors.server-unable"));
      })
      .finally(() => {
        setIsSwiped(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          {...handlers}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !isSwiped && openModal();
          }}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            mb: "20px",
            boxShadow:
              "0px 10px 40px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
            borderRadius: "12px",
            padding: "20px",
            backgroundColor: "#FAFAFA",
            border: "1px solid #F5F5F5",
            boxSizing: `border-box`,
            transition: "transform 0.3s ease-in-out",
            transform: isSwiped ? "translateX(-100px)" : "translateX(0)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "100%",
              paddingBottom: 1,
            }}
          >
            <Box
              sx={{
                width: "70%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "140%",
                      color: `${Colors.gray[1200]}`,
                      textTransform: "none",
                      mb: "4px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {isExerciseCompleted && (
                      <Icon
                        sx={{
                          color: `${Colors.blue[1200]}`,
                          fontSize: "16px",
                          mr: "4px",
                        }}
                      >
                        check_circle_outline
                      </Icon>
                    )}
                    {exercise ? exerciseName : ""}
                    {displayTrend && (
                      <WorkoutTrendIcon
                        finalTrend={exercise.workoutLoadStatus}
                      />
                    )}
                    {hasTrainerNotes && (
                      <Icon
                        className="material-icons-outlined"
                        sx={{
                          fontSize: "16px",
                          color: "#896804",
                          opacity: 0.87,
                        }}
                      >
                        sms
                      </Icon>
                    )}
                  </Typography>
                  {exercise.workoutItemSchemeBreakdowns.map((exerciseItem) => (
                    <div
                      key={`${exercise.exerciseId}-${exerciseItem.workoutItemId}`}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "140%",
                          color: `${Colors.oxford[1100]}`,
                          opacity: "0.87",
                          textDecoration: "none",
                          mb: "7px",
                        }}
                      >
                        <Icon
                          sx={{
                            fontSize: "16px !important",
                            mr: "4px",
                            ml: "4px",
                            color: `${Colors.oxford[1100]}`,
                            opacity: "0.87",
                          }}
                        >
                          fitness_center
                        </Icon>
                        {exerciseItem.prescribedSets}{" "}
                        {Number.isNaN(
                          parseInt(exerciseItem.prescribedSets, 10)
                        ) || parseInt(exerciseItem.prescribedSets, 10) > 1
                          ? "sets"
                          : "set"}{" "}
                        {exerciseItem.prescribedReps
                          ? ` x ${exerciseItem.prescribedReps} ${
                              Number.isNaN(
                                parseInt(exerciseItem.prescribedReps, 10)
                              ) || parseInt(exerciseItem.prescribedReps, 10) > 1
                                ? "reps"
                                : "rep"
                            }`
                          : null}
                        {exerciseItem.prescribedWeight &&
                        exerciseItem.prescribedWeight !== "0"
                          ? ` x ${exerciseItem.prescribedWeight} ${
                              exerciseItem?.workoutItemWeightUnit ?? "Kg"
                            }`
                          : null}
                        {exerciseItem.prescribedIntensity &&
                        (exerciseItem.workoutItemType !== WorkoutItemType.RPE ||
                          exerciseItem.prescribedIntensity !== "0")
                          ? ` @ ${exerciseItem.prescribedIntensity} ${
                              exerciseItem.workoutItemType !==
                              WorkoutItemType.UNDEFINED
                                ? exerciseItem.workoutItemType
                                : ""
                            }`
                          : null}
                      </Typography>

                      {!!exerciseItem.restTime && (
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "140%",
                            color: `${Colors.blue[1300]}`,
                            opacity: "0.87",
                            textDecoration: "none",
                          }}
                        >
                          {t("session-card.rest-time")} {exerciseItem.restTime}{" "}
                        </Typography>
                      )}
                    </div>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                zIndex: 999,
                width: "38px",
                height: "24px",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "38px",
                    height: "24px",
                  }}
                >
                  <Icon
                    sx={{
                      color: `${Colors.blue[1200]}`,
                      fontSize: "16px",
                      marginLeft: "-100%",
                    }}
                  >
                    add_circle_outline
                  </Icon>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    lineHeight: "24px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    marginLeft: "-50%",
                    color: `${Colors.blue[1200]}`,
                  }}
                >
                  Enter
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {isSwiped && (
          <LoadingButton
            sx={{
              position: "absolute",
              right: "0px", // Adjust this value to control the distance between the card and the button
              top: 0,
              width: "85px",
              height: "85%",
              backgroundColor: "#FAFAFA",
              border: "1px solid #F5F5F5",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
            loading={isQuickCompleting}
            onClick={() => handleQuickComplete(exercise)}
          >
            <Icon
              sx={{
                color: `${Colors.blue[1200]}`,
                fontSize: "24px",
                cursor: "pointer",
              }}
            >
              check_circle
            </Icon>
          </LoadingButton>
        )}
      </Box>
      <ChangeWorkoutDetailsModal
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        exercise={exercise}
        exerciseName={exerciseName}
        setExerciseName={setExerciseName}
        selectedUserId={selectedUserId}
      />
      {errorMessage && (
        <SnackbarAlert
          isOpen={isResponseModalOpen}
          onClose={handleClose}
          severity="error"
          message={errorMessage}
        />
      )}
      {successMessage && (
        <SnackbarAlert
          isOpen={isResponseModalOpen}
          onClose={handleClose}
          severity="success"
          message={successMessage}
        />
      )}
    </Box>
  );
};

export { WorkoutListViewItem };
