import { Container, Alert, Box, Typography } from "@mui/material";
import { FormError } from "components/Form/FormError";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { FormContainer } from "components/Form/FormContainer";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { LogoLink } from "components/Form/LogoLink";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Colors } from "shared/themes";
import { useFintessProfessionalSignupMutation } from "shared/api";
import { Routes } from "shared/routers/Routes";

const FitnessProfessionalSignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
  organisationName: Yup.string().required("Business name is required"),
});

export const FitnessProfessionalSignup: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [signup, { isLoading, error }] = useFintessProfessionalSignupMutation();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      organisationName: "",
      acceptTerms: false,
    },
    validationSchema: FitnessProfessionalSignupSchema,
    onSubmit: async (values) => {
      values.confirmPassword = values.password;
      values.acceptTerms = true;
      try {
        setErrorMessage("");
        await signup(values).unwrap();
        try {
          navigate(Routes.fitnessProfessionalSignupEmailVerification.url);
        } catch (error) {
          setErrorMessage(
            "Something went wrong. Please check your details and try again."
          );
        }
      } catch (error) {
        setErrorMessage(
          "Something went wrong. Please check your details and try again."
        );
      }
    },
  });

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        {error && (
          <Box sx={{ mb: 2 }}>
            <Alert severity="error">
              {t("signup.fitness-professionals.signup-error")}
            </Alert>
          </Box>
        )}
        <LogoLink />
        <Typography sx={sharedStyles.headings.signIn}>
          {t("login.sign-up")}
        </Typography>
        <Typography sx={sharedStyles.body.signIn}>
          {t("login.message")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <InputField
              id="firstName"
              name="firstName"
              label={t("signup.fitness-professionals.labels.firstName")}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              startAdornment="badge"
              placeholder={t("placeholders.firstName")}
            />
            <InputField
              id="lastName"
              name="lastName"
              label={t("signup.fitness-professionals.labels.lastName")}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              startAdornment="badge"
              placeholder={t("placeholders.lastName")}
            />
          </Box>
          <InputField
            id="email"
            name="email"
            label={t("signup.fitness-professionals.form.email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            startAdornment="mail"
            placeholder={t("placeholders.email")}
          />
          <InputField
            id="password"
            name="password"
            type="password"
            label={t("signup.fitness-professionals.form.password")}
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            startAdornment="lock"
            placeholder={t("placeholders.password")}
          />
          <InputField
            id="organisationName"
            name="organisationName"
            label={t("signup.fitness-professionals.form.organisationName")}
            variant="outlined"
            value={formik.values.organisationName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.organisationName &&
              Boolean(formik.errors.organisationName)
            }
            helperText={
              formik.touched.organisationName && formik.errors.organisationName
            }
            startAdornment="account_balance"
            placeholder={t("placeholders.businessName")}
          />
          {errorMessage && <FormError errorMessage={errorMessage} />}
          <PrimaryButton
            value={t("signup.fitness-professionals.buttons.signup")}
            size="large"
            loading={isLoading}
            disabled={isLoading || !formik.isValid}
            variant="contained"
            type="submit"
            fullWidth
          />
          <Box
            sx={{
              fontSize: "12px",
            }}
          >
            {t("signup.fitness-professionals.accept-terms")}
            <a
              href="https://www.fitfocus.io/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              <Box component="span" color={Colors.blue[500]} ml={0.5} mr={0.5}>
                {t("signup.fitness-professionals.terms-of-service")}
              </Box>
            </a>
            {t("signup.fitness-professionals.join")}
            <a
              href="https://www.fitfocus.io/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <Box component="span" color={Colors.blue[500]} ml={0.5}>
                {t("signup.fitness-professionals.privacy-policy")}
              </Box>
            </a>
          </Box>
          <Box sx={sharedStyles.containers.signIn.link}>
            <Link
              to={Routes.signIn.url}
              style={sharedStyles.links.backToSignIn}
            >
              Back to Sign In
            </Link>
          </Box>
        </form>
      </Container>
    </FormContainer>
  );
};
