import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface WhatIsTheChallengeCardProps {
  setDialogOpen: (open: boolean) => void;
  title: string;
}

export const WhatIsTheChallengeCard: React.FC<WhatIsTheChallengeCardProps> = ({
  setDialogOpen,
  title,
}) => {
  return (
    <Box
      sx={{
        mt: 3,
        p: 2,
        borderRadius: 2,
        backgroundColor: "#f8f9ff",
        border: "1px solid rgba(108, 92, 231, 0.1)",
        boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => setDialogOpen(true)}
    >
      <Box>
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          How does the {title} challenge work?
        </Typography>
        <Typography variant="body2">
          Discover what the {title} challenge is about and learn more about the
          science behind it.
        </Typography>
      </Box>
      <Box>
        <ArrowForwardIosIcon fontSize="small" />
      </Box>
    </Box>
  );
};
