/* es-lint-disable */

import { FC, useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";
import { LinearProgress, IconButton } from "@mui/material";
import { Colors } from "shared/themes";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

interface WrappedSlideProps {
  slide: {
    imageUrl: string;
    audioUrl: string;
    order: number;
  };
  isActive: boolean;
  onFinished?: () => void;
  duration?: number;
}

const WrappedSlide: FC<WrappedSlideProps> = ({
  slide,
  isActive,
  onFinished,
  duration = 5000,
}) => {
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const intervalRef = useRef<number>();

  const toggleAudio = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current
          .play()
          .catch((err) => console.error("Error playing audio:", err));
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    if (isActive) {
      // Start progress timer
      const startTime = Date.now();
      intervalRef.current = window.setInterval(() => {
        const elapsed = Date.now() - startTime;
        const newProgress = (elapsed / duration) * 100;

        setProgress(newProgress);

        if (newProgress >= 100) {
          clearInterval(intervalRef.current);
          if (onFinished) onFinished();
        }
      }, 50);
    } else {
      // Pause audio and reset progress when not active
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
      }
      clearInterval(intervalRef.current);
      setProgress(0);
    }

    return () => {
      clearInterval(intervalRef.current);
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };
  }, [isActive, duration, onFinished, slide.audioUrl]);

  return (
    <Box
      sx={{
        opacity: isActive ? 1 : 0,
        position: isActive ? "relative" : "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        transition: "opacity 0.5s ease-in-out",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={slide.imageUrl}
        alt={`Wrapped slide ${slide.order}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />

      {/* Audio element without visible controls */}
      {slide.audioUrl && (
        <>
          <audio ref={audioRef} src={slide.audioUrl} preload="auto" hidden>
            <track
              kind="captions"
              src=""
              srcLang="en"
              label="No captions available"
            />
          </audio>

          {/* Custom audio control */}
          <IconButton
            onClick={toggleAudio}
            size="large"
            sx={{
              position: "absolute",
              bottom: 20, // Position above the progress bar
              right: 20,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
              zIndex: 10,
            }}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
        </>
      )}

      {/* Progress bar - now with higher z-index */}
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "70px",
          zIndex: 20, // Ensure it's above other elements
          "& .MuiLinearProgress-bar": {
            backgroundColor: Colors.blue[800],
            transition: "none", // Smoother progress updates
          },
        }}
      />
    </Box>
  );
};

export { WrappedSlide };
