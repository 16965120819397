import { FC } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Colors } from "shared/themes";
import { BillingProduct } from "shared/api";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

interface BillingProductItemProps {
  billingMembership: BillingProduct;
  setSelectedBillingProduct: (billingMembership: BillingProduct) => void;
  onAdd: () => void;
  onEdit: () => void;
}

const BillingProductItem: FC<BillingProductItemProps> = ({
  billingMembership,
  setSelectedBillingProduct,
  onAdd,
  onEdit,
}) => {
  const handleAddClients = () => {
    setSelectedBillingProduct(billingMembership);
    onAdd();
  };

  const handleEdit = () => {
    setSelectedBillingProduct(billingMembership);
    onEdit();
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "180px",
        mb: "16px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        boxShadow: "none",
        display: "flex",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                padding: "5px",
                borderRadius: "16px",
                bgcolor: "white",
                display: "flex",
                flexDirection: "column",
                mb: "16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  color: `${Colors.oxford[1100]}`,
                }}
              >
                {billingMembership.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  color: `${Colors.oxford[1100]}`,
                  mt: "12px",
                }}
              >
                {billingMembership.description}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            display: "flex",
            gap: 1,
          }}
        >
          <Tooltip title="Add Clients">
            <IconButton
              onClick={handleAddClients}
              size="small"
              sx={{
                backgroundColor: Colors.blue[100],
                "&:hover": {
                  backgroundColor: Colors.blue[200],
                },
              }}
            >
              <PersonAddIcon sx={{ color: Colors.blue[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Membership">
            <IconButton
              onClick={handleEdit}
              size="small"
              sx={{
                backgroundColor: Colors.blue[100],
                "&:hover": {
                  backgroundColor: Colors.blue[100],
                },
              }}
            >
              <EditIcon sx={{ color: Colors.blue[500] }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
};

export { BillingProductItem };
