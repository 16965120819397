import React from "react";
import { Box, Dialog, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { WrappedData } from "shared/api";
import { WrappedPresentation } from "./WrappedPresentation";

interface WrappedChallengeCardProps {
  imageUrl?: string;
  wrappedData: WrappedData;
}

export const WrappedChallengeCard: React.FC<WrappedChallengeCardProps> = ({
  imageUrl,
  wrappedData,
}) => {
  const hasImage = !!imageUrl;
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenPresentation = () => {
    setIsOpen(true);
  };

  const handleClosePresentation = () => {
    console.log("Closed presentation");
    setIsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          mt: 3,
          p: 2,
          borderRadius: 2,
          backgroundColor: hasImage ? "transparent" : "#f8f9ff",
          border: hasImage
            ? "1px solid rgba(255, 255, 255, 0.2)"
            : "1px solid rgba(108, 92, 231, 0.1)",
          boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          overflow: "hidden",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          height: hasImage ? "240px" : "120px",
          "&:hover": {
            transform: "translateY(-3px)",
            boxShadow: "0 8px 16px rgba(108, 92, 231, 0.12)",
          },
          ...(hasImage && {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }),
        }}
        onClick={handleOpenPresentation}
      >
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: hasImage ? "white" : "inherit",
              textShadow: hasImage ? "0 1px 2px rgba(0,0,0,0.3)" : "none",
            }}
          >
            Your Challenge Wrapped is here
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: hasImage ? "rgba(255, 255, 255, 0.9)" : "inherit",
              textShadow: hasImage ? "0 1px 1px rgba(0,0,0,0.2)" : "none",
              maxWidth: "90%",
            }}
          >
            Find out what pushed you and the team to the next level.
          </Typography>
        </Box>
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <ArrowForwardIosIcon
            fontSize="small"
            sx={{ color: hasImage ? "white" : "inherit" }}
          />
        </Box>
      </Box>

      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClosePresentation}
        sx={{
          "& .MuiDialog-paper": {
            m: 0,
            p: 0,
          },
        }}
      >
        <WrappedPresentation
          wrappedData={wrappedData}
          onClose={handleClosePresentation}
        />
      </Dialog>
    </>
  );
};
