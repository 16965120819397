import React, { SyntheticEvent, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Tab,
  Avatar,
  Divider,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  WorkoutTemplateResponse,
  useGetWorkoutTemplateQuery,
  useAssignWorkoutTemplateMutation,
  WorkoutProgramItem,
} from "shared/api";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Colors } from "shared/themes";
import { useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface WorkoutTemplatePreviewProps {
  open: boolean;
  onClose: () => void;
  workoutTemplate: WorkoutTemplateResponse;
  currentWorkoutProgram?: WorkoutProgramItem;
}

export const WorkoutTemplatePreview: React.FC<WorkoutTemplatePreviewProps> = ({
  open,
  onClose,
  workoutTemplate,
  currentWorkoutProgram,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { data: workoutTemplateData, isLoading } = useGetWorkoutTemplateQuery(
    workoutTemplate.id
  );

  const [updating, setUpdating] = useState(false);

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [assignWorkoutTemplate] = useAssignWorkoutTemplateMutation();

  const handleAssignWorkoutTemplate = async () => {
    setUpdating(true);
    setErrorMessage("");
    try {
      await assignWorkoutTemplate(workoutTemplate.id).unwrap();
      navigate(`${Routes.workoutTemplateThankYouPage.url}`);
      onClose();
    } catch (error) {
      setErrorMessage("Failed to assign workout template. Please try again.");
    } finally {
      setUpdating(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            {workoutTemplate.name}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h6">Loading...</Typography>
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                color: "#6C5CE7",
                mb: 2,
              }}
            >
              {workoutTemplate.daysInProgram === 1 &&
              workoutTemplate.weeksInProgram === 1
                ? "The workout"
                : "The program"}
            </Typography>
            <Box
              sx={{
                display: "inline-block",
                px: 1.5,
                py: 0.5,
                borderRadius: "12px",
                backgroundColor: (() => {
                  switch (workoutTemplate.difficulty) {
                    case "Beginner":
                      return "#4caf50";
                    case "Intermediate":
                      return "#ff9800";
                    default:
                      return "#f44336";
                  }
                })(),
                color: "#fff",
                mb: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.875rem",
                }}
              >
                {workoutTemplate.difficulty}
              </Typography>
            </Box>
            {!(
              workoutTemplate.daysInProgram === 1 &&
              workoutTemplate.weeksInProgram === 1
            ) && (
              <Box
                sx={{
                  display: "inline-block",
                  px: 1.5,
                  py: 0.5,
                  ml: 2,
                  borderRadius: "12px",
                  backgroundColor: "#6C5CE7",
                  color: "#fff",
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  {`${workoutTemplate.daysInProgram} days / ${workoutTemplate.weeksInProgram} weeks`}
                </Typography>
              </Box>
            )}
            <Typography
              variant="body1"
              sx={{
                fontSize: "0.95rem",
                color: "#444",
                lineHeight: 1.6,
                mb: 2,
              }}
            >
              {workoutTemplate.description}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                color: "#6C5CE7",
                mb: 2,
              }}
            >
              Meet the coach
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={workoutTemplate.coachProfileImageUrl}
                alt={`${workoutTemplate.coachName}`}
                sx={{ mr: 2 }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  color: "#6C5CE7",
                  mb: 2,
                }}
              >
                {workoutTemplate.coachName}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: "0.95rem",
                color: "#444",
                lineHeight: 1.6,
                mb: 2,
              }}
            >
              {workoutTemplate.coachDescription}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                color: "#6C5CE7",
                mb: 2,
              }}
            >
              {workoutTemplate.daysInProgram === 1 &&
              workoutTemplate.weeksInProgram === 1
                ? "Preview the workout"
                : "Preview the first week"}
            </Typography>
            <TabContext value={tabValue}>
              <TabList
                onChange={handleTabChange}
                aria-label="Days"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  mb: 2,
                  maxWidth: "100%",
                  ".MuiTabs-scroller": {
                    overflowX: "auto !important",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    scrollbarWidth: "none",
                  },
                }}
              >
                {workoutTemplate.daysInProgram &&
                  Array.from(
                    { length: workoutTemplate.daysInProgram },
                    (_: unknown, index: number) => index + 1
                  ).map((day) => (
                    <Tab
                      sx={{
                        textTransform: "none",
                      }}
                      key={day}
                      label={`Day ${day}`}
                      value={day.toString()}
                    />
                  ))}
              </TabList>
              <TabPanel value={tabValue}>
                {workoutTemplateData?.previews
                  ?.filter(
                    (preview) => preview.dayNumber?.toString() === tabValue
                  )
                  ?.map((preview, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "1rem",
                            color: "#444",
                            lineHeight: 1.6,
                            mb: 1,
                          }}
                        >
                          {preview.exerciseName}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "0.85rem",
                            color: "#444",
                            lineHeight: 1.6,
                            mb: 1,
                          }}
                        >
                          {preview.sets} sets, {preview.reps} reps
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                  ))}
              </TabPanel>
            </TabContext>
          </Box>
          {!currentWorkoutProgram ? (
            <Box>
              <LoadingButton
                type="button"
                onClick={handleAssignWorkoutTemplate}
                loading={updating || isLoading}
                disabled={updating || isLoading}
                sx={{
                  width: "100%",
                  height: "42px",
                }}
                variant="contained"
                color="primary"
                size="large"
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "26px",
                    letterSpacing: " 0.46px",
                    textTransform: "none",
                    color: `${Colors.gray[100]}`,
                  }}
                >
                  Start program
                </Typography>
              </LoadingButton>
              {errorMessage && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ mt: 2, textAlign: "center" }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              <Alert severity="error" sx={{ width: "100%" }}>
                {" "}
                <Typography variant="body1">
                  You are unable to start a new{" "}
                  {workoutTemplate.daysInProgram === 1 &&
                  workoutTemplate.weeksInProgram === 1
                    ? "workout"
                    : "workout program"}{" "}
                  while you already have an active one. Finish your current
                  program to start a new one.
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </Dialog>
  );
};
