import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  ChallengeLeaderboardItem,
  AthleteLeaderboardRequestParams,
} from "../challenges/models";

export const leaderboardApi = createApi({
  baseQuery,
  reducerPath: "leaderboardApi",
  tagTypes: ["AthleteLeaderboard"],
  endpoints: (builder) => ({
    getAthleteLeaderboard: builder.query<
      ChallengeLeaderboardItem[],
      AthleteLeaderboardRequestParams
    >({
      query: (body) => {
        let queryString = `/leaderboard/athlete?leaderboardType=${body.leaderboardType}`;
        if (body.startDate) {
          queryString += `&startDate=${body.startDate}`;
        }
        if (body.endDate) {
          queryString += `&endDate=${body.endDate}`;
        }
        return queryString;
      },
      providesTags: ["AthleteLeaderboard"],
    }),
  }),
});

export const { useGetAthleteLeaderboardQuery } = leaderboardApi;
