/* eslint-disable no-nested-ternary */
import {
  Box,
  Icon,
  Typography,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import {
  WorkoutItemSchemeBreakdown,
  WorkoutItemType,
  useGetWorkoutItemLogQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { DefaultCardioInputScreen } from "./inputScreens/DefaultCardioInputScreen";
import { DefaultInputScreen } from "./inputScreens/DefaultInputScreen";
import { DefaultInputScreenWithPartials } from "./inputScreens/DefaultInputScreenWithPartials";
import { UnilateralInputScreen } from "./inputScreens/UnilateralInputScreen";
import { UnilateralInputScreenWithPartials } from "./inputScreens/UnilateralInputScreenWithPartials";

interface ChangeWorkoutDetailsModalInputCardProps {
  schema: WorkoutItemSchemeBreakdown;
  selectedWorkoutItemId: number;
  selectedWorkoutItemLogId: number;
  onSelect: (workoutItemId: number, logId: number) => void;
  exerciseType: string;
  values: any;
  handleBlur: (e: any) => void;
  handleChange: (e: any) => void;
  exercise: any;
  handleTimerClick: () => void;
  handleSubmit: () => void;
  onApplyToAllSetsSubmit: (values: any) => void;
  isLoading: boolean;
  isExpanded: boolean;
  onExpandChange: (expanded: boolean) => void;
}

interface CollapsedViewProps {
  schema: WorkoutItemSchemeBreakdown;
  onSelect: (workoutItemId: number, logId: number) => void;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  getTitle: () => string;
}

interface ExpandedViewProps {
  schema: WorkoutItemSchemeBreakdown;
  selectedWorkoutItemId: number;
  selectedWorkoutItemLogId: number;
  onSelect: (workoutItemId: number, logId: number) => void;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  getTitle: () => string;
  exerciseType: string;
  values: any;
  handleBlur: (e: any) => void;
  handleChange: (e: any) => void;
  exercise: any;
  handleTimerClick: () => void;
  unilateralEnabled: boolean;
  setUnilateralEnabled: (value: boolean) => void;
  partialRepsEnabled: boolean;
  setPartialRepsEnabled: (value: boolean) => void;
  handleSubmit: () => void;
  onApplyToAllSetsSubmit: (values: any) => void;
  isLoading: boolean;
}

const CollapsedView: FC<CollapsedViewProps> = ({
  schema,
  onSelect,
  isExpanded,
  setIsExpanded,
  getTitle,
}) => {
  const isExerciseCompleted = (setOrder: number | null) =>
    schema.workoutItemLogs?.find(
      (log: any) =>
        log.setOrder === setOrder &&
        (log.completedReps ||
          log.completedPartialReps ||
          log.completedIntensity ||
          log.completedWeight ||
          log.completedRepsLeftSide ||
          log.completedRepsRightSide ||
          log.completedPartialRepsLeftSide ||
          log.completedPartialRepsRightSide ||
          log.completedIntensityLeftSide ||
          log.completedIntensityRightSide ||
          log.completedWeightLeftSide ||
          log.completedWeightRightSide ||
          log.completedDistance ||
          log.completedTime ||
          log.completedSpeed)
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          setIsExpanded(!isExpanded);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          schema.workoutItemLogs[0]?.id &&
            onSelect(schema.workoutItemId, schema.workoutItemLogs[0].id);
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon
            sx={{
              color: Colors.oxford[1100],
              opacity: 0.6,
              fontSize: 20,
              mr: 1,
            }}
          >
            fitness_center
          </Icon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.oxford[1100],
            }}
          >
            {getTitle()}
          </Typography>
        </Box>
        <Icon sx={{ color: Colors.oxford[1100], opacity: 0.38 }}>
          {isExpanded ? "expand_less" : "expand_more"}
        </Icon>
      </Box>

      <Box
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: Colors.oxford[1100],
              opacity: 0.6,
              minWidth: "36px",
            }}
          >
            Sets
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {schema.workoutItemLogs.map((log) => {
              const isCompleted = isExerciseCompleted(log.setOrder);
              return (
                <Box
                  key={log.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                    onSelect(schema.workoutItemId, log.id);
                  }}
                  sx={{
                    width: isCompleted ? 52 : 32,
                    height: 28,
                    borderRadius: "100px",
                    border: "1px solid",
                    borderColor: isCompleted ? "#2500F5" : "#BDBDBD",
                    backgroundColor: "transparent",
                    color: Colors.oxford[1100],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "relative",
                    transition: "all 0.2s ease-in-out",
                    boxSizing: "border-box",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                    ...(isCompleted && {
                      "& .set-number": {
                        position: "absolute",
                        left: 0,
                        width: 28,
                        height: 28,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "20px",
                      },
                      "& .icon-container": {
                        position: "absolute",
                        right: 0,
                        width: 28,
                        height: 28,
                        borderRadius: "100px",
                        backgroundColor: "#D9DAFF",
                        border: "1px solid #2500F5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxSizing: "border-box",
                        marginRight: -0.5,
                      },
                    }),
                  }}
                >
                  {isCompleted ? (
                    <>
                      <span className="set-number">{log.setOrder}</span>
                      <Icon
                        className="material-icons-outlined icon-container"
                        sx={{ fontSize: "16px !important", color: "#2500F5" }}
                      >
                        check
                      </Icon>
                    </>
                  ) : (
                    log.setOrder
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const ExpandedView: FC<ExpandedViewProps> = ({
  schema,
  selectedWorkoutItemId,
  selectedWorkoutItemLogId,
  onSelect,
  isExpanded,
  setIsExpanded,
  getTitle,
  exerciseType,
  values,
  handleBlur,
  handleChange,
  exercise,
  handleTimerClick,
  unilateralEnabled,
  setUnilateralEnabled,
  partialRepsEnabled,
  setPartialRepsEnabled,
  handleSubmit,
  onApplyToAllSetsSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  const isSetSelected =
    selectedWorkoutItemLogId &&
    schema.workoutItemLogs.some((log) => log.id === selectedWorkoutItemLogId);

  const isExerciseCompleted = (setOrder: number | null) =>
    schema.workoutItemLogs?.find(
      (log: any) =>
        log.setOrder === setOrder &&
        (log.completedReps ||
          log.completedPartialReps ||
          log.completedIntensity ||
          log.completedWeight ||
          log.completedRepsLeftSide ||
          log.completedRepsRightSide ||
          log.completedPartialRepsLeftSide ||
          log.completedPartialRepsRightSide ||
          log.completedIntensityLeftSide ||
          log.completedIntensityRightSide ||
          log.completedWeightLeftSide ||
          log.completedWeightRightSide ||
          log.completedDistance ||
          log.completedTime ||
          log.completedSpeed)
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          cursor: "pointer",
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon
            sx={{
              color: Colors.oxford[1100],
              opacity: 0.6,
              fontSize: 20,
              mr: 1,
            }}
          >
            fitness_center
          </Icon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.oxford[1100],
            }}
          >
            {getTitle()}
          </Typography>
        </Box>
        <Icon sx={{ color: Colors.oxford[1100], opacity: 0.38 }}>
          expand_less
        </Icon>
      </Box>

      <Box
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: Colors.oxford[1100],
              opacity: 0.6,
              minWidth: "36px",
            }}
          >
            Sets
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {schema.workoutItemLogs.map((log) => {
              const isCompleted = isExerciseCompleted(log.setOrder);
              const isSelected = log.id === selectedWorkoutItemLogId;
              return (
                <Box
                  key={log.id}
                  onClick={() => onSelect(schema.workoutItemId, log.id)}
                  sx={{
                    width: isSelected ? 58 : isCompleted ? 52 : 32,
                    height: 28,
                    borderRadius: "100px",
                    border: "1px solid",
                    borderColor: isSelected
                      ? "transparent"
                      : isCompleted
                      ? "#2500F5"
                      : "#BDBDBD",
                    backgroundColor: isSelected ? "#D9DAFF" : "transparent",
                    color: Colors.oxford[1100],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "relative",
                    transition: "all 0.2s ease-in-out",
                    boxSizing: "border-box",
                    "&:hover": {
                      backgroundColor: isSelected
                        ? "#D9DAFF"
                        : "rgba(0, 0, 0, 0.04)",
                    },
                    ...(isSelected || (!isSelected && isCompleted)
                      ? {
                          "& .set-number": {
                            position: "absolute",
                            left: 0,
                            width: 28,
                            height: 28,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "20px",
                          },
                          "& .icon-container": {
                            position: "absolute",
                            right: 0,
                            width: 28,
                            height: 28,
                            borderRadius: "100px",
                            backgroundColor: isSelected ? "#2500F5" : "#D9DAFF",
                            border: isSelected ? "none" : "1px solid #2500F5",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxSizing: "border-box",
                            marginRight: isSelected ? 0 : -0.5,
                          },
                        }
                      : {}),
                  }}
                >
                  {isSelected ? (
                    <>
                      <span className="set-number">{log.setOrder}</span>
                      <Icon
                        className="material-icons-outlined icon-container"
                        sx={{ fontSize: "16px !important", color: "white" }}
                      >
                        edit
                      </Icon>
                    </>
                  ) : isCompleted ? (
                    <>
                      <span className="set-number">{log.setOrder}</span>
                      <Icon
                        className="material-icons-outlined icon-container"
                        sx={{ fontSize: "16px !important", color: "#2500F5" }}
                      >
                        check
                      </Icon>
                    </>
                  ) : (
                    log.setOrder
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          p: 2,
          background: "#F5F5F5",
          // disable everything if no set is selected
          opacity: isSetSelected ? 1 : 0.8,
          pointerEvents: isSetSelected ? "auto" : "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: "50%",
                backgroundColor: Colors.gray[800],
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {
                schema.workoutItemLogs.find(
                  (log) => log.id === selectedWorkoutItemLogId
                )?.setOrder
              }
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                color: Colors.oxford[1100],
                letterSpacing: "0.1em",
              }}
            >
              Set{" "}
              {
                schema.workoutItemLogs.find(
                  (log) => log.id === selectedWorkoutItemLogId
                )?.setOrder
              }
            </Typography>
          </Box>
          <IconButton onClick={handleTimerClick}>
            <OutlinedIcon
              iconName="timer"
              style={{
                color: `${Colors.blue[1200]}`,
              }}
            />
          </IconButton>
        </Box>
        {exerciseType !== "Cardio" && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              mb: 1,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setUnilateralEnabled(!unilateralEnabled)}
              startIcon={
                <Icon
                  sx={{
                    color: Colors.oxford[1100],
                    opacity: 0.6,
                    fontSize: 20,
                  }}
                  className="material-icons-outlined"
                >
                  sign_language
                </Icon>
              }
              endIcon={
                <Checkbox
                  size="small"
                  checked={unilateralEnabled}
                  sx={{
                    padding: 0,
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setUnilateralEnabled(!unilateralEnabled);
                  }}
                />
              }
              sx={{
                flex: 1,
                justifyContent: "space-between",
                textTransform: "none",
                color: Colors.oxford[1100],
                borderColor: "rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                backgroundColor: "white !important",
                "&:hover": {
                  borderColor: "rgba(0, 0, 0, 0.12)",
                },
                "& .MuiButton-startIcon": {
                  marginRight: 1,
                },
                "& .MuiButton-endIcon": {
                  marginLeft: 1,
                },
              }}
            >
              {t("item-modal.unilateral-title")}
            </Button>

            <Button
              variant="outlined"
              onClick={() => setPartialRepsEnabled(!partialRepsEnabled)}
              startIcon={
                <Icon
                  sx={{
                    color: Colors.oxford[1100],
                    opacity: 0.6,
                    fontSize: 20,
                  }}
                  className="material-icons-outlined"
                >
                  donut_small
                </Icon>
              }
              endIcon={
                <Checkbox
                  size="small"
                  checked={partialRepsEnabled}
                  sx={{
                    padding: 0,
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPartialRepsEnabled(!partialRepsEnabled);
                  }}
                />
              }
              sx={{
                flex: 1,
                justifyContent: "space-between",
                textTransform: "none",
                color: Colors.oxford[1100],
                borderColor: "rgba(0, 0, 0, 0.12)",
                backgroundColor: "white !important",
                borderRadius: "8px",
                "&:hover": {
                  borderColor: "rgba(0, 0, 0, 0.12)",
                },
                "& .MuiButton-startIcon": {
                  marginRight: 1,
                },
                "& .MuiButton-endIcon": {
                  marginLeft: 1,
                },
              }}
            >
              {t("item-modal.partial-reps-title")}
            </Button>
          </Box>
        )}

        {exerciseType === "Cardio" && (
          <DefaultCardioInputScreen
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        )}
        {exerciseType !== "Cardio" &&
          !unilateralEnabled &&
          !partialRepsEnabled && (
            <DefaultInputScreen
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        {exerciseType !== "Cardio" &&
          partialRepsEnabled &&
          !unilateralEnabled && (
            <DefaultInputScreenWithPartials
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        {exerciseType !== "Cardio" &&
          unilateralEnabled &&
          !partialRepsEnabled && (
            <UnilateralInputScreen
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        {exerciseType !== "Cardio" &&
          unilateralEnabled &&
          partialRepsEnabled && (
            <UnilateralInputScreenWithPartials
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}

        <Box
          sx={{
            width: "100%",
            padding: "16px 0 0",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            marginTop: "16px",
          }}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              height: "42px",
            }}
            type="submit"
            size="large"
            loading={isLoading}
            disabled={isLoading || !isSetSelected}
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              Save set
            </Typography>
          </LoadingButton>
          {schema.workoutItemLogs.length > 1 && (
            <LoadingButton
              variant="outlined"
              color="primary"
              sx={{
                width: "100%",
                height: "42px",
                mt: "12px",
              }}
              type="submit"
              size="large"
              onClick={() => onApplyToAllSetsSubmit(values)}
              loading={isLoading}
              disabled={isLoading || !isSetSelected}
            >
              <Typography
                sx={{
                  textTransform: "none",
                }}
              >
                {t("item-modal.apply-to-all-sets-btn")}
              </Typography>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export const ChangeWorkoutDetailsModalInputCard: FC<
  ChangeWorkoutDetailsModalInputCardProps
> = ({
  schema,
  selectedWorkoutItemId,
  selectedWorkoutItemLogId,
  onSelect,
  exerciseType,
  values,
  handleBlur,
  handleChange,
  exercise,
  handleTimerClick,
  handleSubmit,
  onApplyToAllSetsSubmit,
  isLoading,
  isExpanded,
  onExpandChange,
}) => {
  const [partialRepsEnabled, setPartialRepsEnabled] = useState(false);
  const [unilateralEnabled, setUnilateralEnabled] = useState(false);
  const isSelected = selectedWorkoutItemId === schema.workoutItemId;

  const { data: workoutItemLog } = useGetWorkoutItemLogQuery(
    selectedWorkoutItemLogId
  );

  useEffect(() => {
    const isUnilateralExercise =
      workoutItemLog?.completedIntensityLeftSide ||
      workoutItemLog?.completedIntensityRightSide ||
      workoutItemLog?.completedPartialRepsLeftSide ||
      workoutItemLog?.completedPartialRepsRightSide ||
      workoutItemLog?.completedRepsLeftSide ||
      workoutItemLog?.completedRepsRightSide ||
      workoutItemLog?.completedWeightLeftSide ||
      workoutItemLog?.completedWeightRightSide;

    const isPartialRepsExercise =
      workoutItemLog?.completedPartialReps ||
      workoutItemLog?.completedPartialRepsLeftSide ||
      workoutItemLog?.completedPartialRepsRightSide;

    if (isUnilateralExercise) {
      setUnilateralEnabled(true);
    }
    if (isPartialRepsExercise) {
      setPartialRepsEnabled(true);
    }
  }, [workoutItemLog]);

  const getTitle = () => {
    const parts = [];

    if (schema.prescribedSets) {
      parts.push(`${schema.prescribedSets} sets`);
    }

    if (schema.prescribedReps) {
      parts.push(`${schema.prescribedReps} reps`);
    }

    if (schema.prescribedWeight && schema.prescribedWeight !== "0") {
      parts.push(
        `${schema.prescribedWeight}${schema.workoutItemWeightUnit ?? "kg"}`
      );
    }

    const partsString = parts.join(" × ");

    const partsIntensity =
      schema.prescribedIntensity &&
      (schema.workoutItemType !== WorkoutItemType.RPE ||
        schema.prescribedIntensity !== "0")
        ? ` @ ${schema.prescribedIntensity} ${
            schema.workoutItemType !== WorkoutItemType.UNDEFINED
              ? schema.workoutItemType
              : ""
          }`
        : null;

    return partsIntensity ? `${partsString} ${partsIntensity}` : partsString;
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: isSelected ? Colors.blue[1200] : "rgba(0, 0, 0, 0.12)",
        mb: 1,
      }}
    >
      {isExpanded ? (
        <ExpandedView
          schema={schema}
          selectedWorkoutItemId={selectedWorkoutItemId}
          selectedWorkoutItemLogId={selectedWorkoutItemLogId}
          onSelect={onSelect}
          isExpanded={isExpanded}
          setIsExpanded={(value) => onExpandChange(value)}
          getTitle={getTitle}
          exerciseType={exerciseType}
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          exercise={exercise}
          handleTimerClick={handleTimerClick}
          unilateralEnabled={unilateralEnabled}
          setUnilateralEnabled={setUnilateralEnabled}
          partialRepsEnabled={partialRepsEnabled}
          setPartialRepsEnabled={setPartialRepsEnabled}
          handleSubmit={handleSubmit}
          onApplyToAllSetsSubmit={onApplyToAllSetsSubmit}
          isLoading={isLoading}
        />
      ) : (
        <CollapsedView
          schema={schema}
          onSelect={onSelect}
          isExpanded={isExpanded}
          setIsExpanded={(value) => onExpandChange(value)}
          getTitle={getTitle}
        />
      )}
    </Box>
  );
};
