import React, { useState } from "react";
import { Box, Typography, Paper, Grid, Divider, Skeleton } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import TimelineIcon from "@mui/icons-material/Timeline";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Colors } from "shared/themes";
import { SafeAreaBox } from "components/SafeAreaBox/SafeAreaBox";
import { userSelectors } from "shared/stores/user";
import { useTypedSelector } from "shared/stores";
import { TrainingInsightsContent } from "pages/TrainingInsights/TrainingInsightsContent";
import { useGetProgressInsightsQuery } from "shared/api/trainingInsights/trainingInsights";
import {
  SelfImprovementOutlined,
  Leaderboard,
  EmojiEvents,
} from "@mui/icons-material";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AthleteNutrition } from "pages/Nutrition";
import { AthleteBookingSchedule } from "pages/Bookings/Athlete/AthleteBookingSchedule";
import { FeatureDialog } from "./FeatureDialog";
import { BodyMetricsContent } from "./BodyMetricsContent";
import { UserPersonalRecords } from "./UserPersonalRecords";
import { AthleteLeaderboard } from "./AthleteLeaderboard";
import { AthleteChallenges } from "./AthleteChallenges";

const FeatureCard = ({
  icon,
  title,
  description,
  onClick,
  iconColor,
}: {
  icon: React.ReactNode;
  title: string;
  description?: string;
  onClick: () => void;
  iconColor?: string;
}) => (
  <Paper
    elevation={0}
    sx={{
      backgroundColor: "white",
      border: `1px solid ${Colors.gray[200]}`,
      borderRadius: 2,
      p: 2,
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        borderColor: Colors.blue[300],
      },
    }}
    onClick={onClick}
  >
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          borderRadius: "50%",
          width: 40,
          height: 40,
          mr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: iconColor || Colors.blue[500],
          backgroundColor: iconColor
            ? `${iconColor}15`
            : "rgba(66, 69, 255, 0.1)",
        }}
      >
        {icon}
      </Box>
      <Typography variant="h6" sx={{ color: Colors.gray[900] }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ color: Colors.gray[600] }}>
      {description}
    </Typography>
  </Paper>
);

const InsightCard = ({
  value,
  label,
  sublabel,
  icon,
  iconColor = "#7C3AED",
  isLoading = false,
}: {
  value: React.ReactNode;
  label: string;
  sublabel: string;
  icon: React.ReactNode;
  iconColor?: string;
  isLoading?: boolean;
}) => (
  <Paper
    elevation={0}
    sx={{
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.05)",
      borderRadius: 3,
      p: 3,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: 1,
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: Colors.gray[900],
            mb: 0.5,
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="text"
              width={40}
              sx={{
                transform: "none",
                bgcolor: Colors.gray[200],
              }}
            />
          ) : (
            value
          )}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: Colors.gray[600],
          }}
        >
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: `${iconColor}15`,
          borderRadius: "50%",
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: iconColor,
        }}
      >
        {icon}
      </Box>
    </Box>
    <Typography
      variant="body2"
      sx={{
        color: Colors.gray[500],
        mt: "auto",
      }}
    >
      {sublabel}
    </Typography>
  </Paper>
);

export const AthleteProgress: React.FC = () => {
  const organisationType = useTypedSelector(userSelectors.organisationType);
  const [openFeature, setOpenFeature] = useState<string | null>(null);
  const userId = useTypedSelector(userSelectors.userId);

  const { data: progressInsights, isLoading: isProgressLoading } =
    useGetProgressInsightsQuery(userId ?? 0);

  const handleOpenFeature = (title: string) => {
    setOpenFeature(title);
  };

  const handleCloseFeature = () => {
    setOpenFeature(null);
  };

  const leaderboardItems = [
    {
      title: "Leaderboard",
      icon: <Leaderboard />,
      iconColor: "#6366F1",
      content: <AthleteLeaderboard userId={userId ?? 0} />,
    },
    {
      title: "Challenges",
      icon: <EmojiEvents />,
      iconColor: Colors.green[500],
      content: <AthleteChallenges />,
    },
  ];

  const progressItems = [
    {
      title: "Body measurements",
      icon: <SelfImprovementOutlined />,
      iconColor: "#FFB800",
      content: <BodyMetricsContent userId={userId ?? 0} />,
    },
  ];

  if (organisationType !== "WorkplaceWellbeing") {
    progressItems.push(
      {
        title: "Nutrition",
        icon: <RestaurantMenuIcon />,
        iconColor: Colors.red[300],
        content: (
          <AthleteNutrition
            isDialog
            open
            onClose={() => handleCloseFeature()}
          />
        ),
      },
      {
        title: "Exercise history",
        icon: <FitnessCenterIcon />,
        iconColor: "#10B981",
        content: (
          <TrainingInsightsContent
            showOverviewOnly
            preselectedUserId={userId ?? undefined}
          />
        ),
      },
      {
        title: "Personal bests",
        icon: <EmojiEventsIcon />,
        iconColor: "#6366F1",
        content: <UserPersonalRecords userId={userId ?? 0} />,
      },
      {
        title: "Bookings",
        icon: <CalendarTodayIcon />,
        iconColor: "#6366F1",
        content: <AthleteBookingSchedule closeSidebar={() => {}} />,
      }
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        background: "linear-gradient(180deg, #FBFBFE 0%, #FFFFFF 80%)",
      }}
    >
      <SafeAreaBox sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: Colors.gray[900],
                }}
              >
                My progress
              </Typography>
            </Box>

            <Typography
              variant="subtitle2"
              sx={{
                color: Colors.gray[600],
                mb: 2,
              }}
            >
              Insights
            </Typography>

            <Box sx={{ display: "flex", gap: 3, mb: 4 }}>
              <InsightCard
                value={
                  progressInsights?.consecutiveWorkoutsCompleted.toString() ??
                  "0"
                }
                label="in a row"
                sublabel="Consecutive workout streak"
                icon={<TimelineIcon />}
                iconColor="#3B82F6"
                isLoading={isProgressLoading}
              />
              <InsightCard
                value={
                  progressInsights?.workoutsCompletedLast7Days.toString() ?? "0"
                }
                label="workouts"
                sublabel="Completed in the last 7 days"
                icon={<EmojiEventsIcon />}
                iconColor="#3B82F6"
                isLoading={isProgressLoading}
              />
            </Box>

            <Divider sx={{ borderColor: "divider", mb: 4 }} />

            <Grid container spacing={2}>
              {leaderboardItems.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <FeatureCard
                    icon={item.icon}
                    title={item.title}
                    onClick={() => handleOpenFeature(item.title)}
                    iconColor={item.iconColor}
                  />
                </Grid>
              ))}
            </Grid>

            <Divider sx={{ borderColor: "divider", mb: 4 }} />

            <Grid container spacing={2}>
              {progressItems.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  sx={{ mb: index === progressItems.length - 1 ? 12 : 0 }}
                >
                  <FeatureCard
                    icon={item.icon}
                    title={item.title}
                    onClick={() => handleOpenFeature(item.title)}
                    iconColor={item.iconColor}
                  />
                </Grid>
              ))}
            </Grid>

            {progressItems.map((item) => (
              <FeatureDialog
                key={item.title}
                open={openFeature === item.title}
                onClose={handleCloseFeature}
                title={item.title}
              >
                <Box sx={{ height: "100%", overflow: "auto" }}>
                  {item.content}
                </Box>
              </FeatureDialog>
            ))}
            {leaderboardItems.map((item) => (
              <FeatureDialog
                key={item.title}
                open={openFeature === item.title}
                onClose={handleCloseFeature}
                title={item.title}
              >
                <Box sx={{ height: "100%", overflow: "auto" }}>
                  {item.content}
                </Box>
              </FeatureDialog>
            ))}
          </Box>
        </Box>
      </SafeAreaBox>
    </Box>
  );
};

export default AthleteProgress;
