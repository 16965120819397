/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  useGetUserBodyMeasurementsQuery,
  useCreateUserBodyMeasurementMutation,
} from "shared/api/userBodyMeasurements/userBodyMeasurementsApi";
import { Colors } from "shared/themes";

interface BodyMetricsContentProps {
  userId: number;
}

export const BodyMetricsContent: React.FC<BodyMetricsContentProps> = ({
  userId,
}) => {
  const [weight, setWeight] = useState<string>("");
  const [unit, setUnit] = useState<"kg" | "lbs">("kg");
  const { data: measurements, isLoading } = useGetUserBodyMeasurementsQuery({
    userId,
    countOnly: false,
    count: 100,
    page: 1,
  });
  const [createMeasurement] = useCreateUserBodyMeasurementMutation();

  const handleUnitChange = (
    _event: React.MouseEvent<HTMLElement>,
    newUnit: "kg" | "lbs" | null
  ) => {
    if (newUnit !== null) {
      // Convert the current weight value when changing units
      if (weight) {
        const numWeight = parseFloat(weight);
        if (!Number.isNaN(numWeight)) {
          if (newUnit === "kg" && unit === "lbs") {
            setWeight((numWeight * 0.453592).toFixed(1));
          } else if (newUnit === "lbs" && unit === "kg") {
            setWeight((numWeight * 2.20462).toFixed(1));
          }
        }
      }
      setUnit(newUnit);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!weight) return;

    try {
      const weightInKg =
        unit === "lbs" ? parseFloat(weight) * 0.453592 : parseFloat(weight);

      await createMeasurement({
        weight: parseFloat(weightInKg.toFixed(1)),
        weightUnit: "kg", // Always store in kg
        userId,
      });
      setWeight("");
    } catch (error) {
      console.error("Failed to create measurement:", error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading measurements...</Typography>
      </Box>
    );
  }

  const chartOptions: ApexOptions = {
    chart: {
      id: "weight-trend",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth" as "smooth",
      width: 3,
    },
    grid: {
      show: true,
      borderColor: Colors.gray[200],
      strokeDashArray: 7,
    },
    fill: {
      colors: [Colors.blue[500]],
    },
    xaxis: {
      categories: measurements?.map((m) =>
        m.dateTime ? new Date(m.dateTime).toLocaleDateString() : "N/A"
      ),
      tickAmount: 5,
      labels: {
        show: true,
        style: {
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: `Weight (${unit})`,
      },
    },
  };

  const series = [
    {
      name: "Weight",
      data:
        measurements?.map((m) => {
          if (m.weight === null) return 0;
          return unit === "lbs"
            ? parseFloat((m.weight * 2.20462).toFixed(1))
            : m.weight;
        }) || [],
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
          <TextField
            size="small"
            label="Add new weight"
            type="number"
            fullWidth
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
          />
          <ToggleButtonGroup
            value={unit}
            exclusive
            onChange={handleUnitChange}
            size="small"
            sx={{
              height: 40,
              "& .MuiToggleButton-root": {
                textTransform: "none",
                px: 2,
                border: `1px solid ${Colors.gray[300]}`,
                "&.Mui-selected": {
                  backgroundColor: Colors.blue[500],
                  color: "white",
                  "&:hover": {
                    backgroundColor: Colors.blue[600],
                  },
                },
              },
            }}
          >
            <ToggleButton value="kg">kg</ToggleButton>
            <ToggleButton value="lbs">lbs</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Button
          variant="contained"
          type="submit"
          disabled={!weight}
          size="large"
          sx={{
            backgroundColor: Colors.blue[500],
            "&:hover": {
              backgroundColor: Colors.blue[600],
            },
            minWidth: "auto",
            px: 2,
            textTransform: "none",
          }}
        >
          Add
        </Button>
      </Box>

      {measurements && measurements.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Chart
            options={chartOptions}
            series={series}
            type="line"
            height={400}
          />
        </Box>
      )}

      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${Colors.gray[200]}`,
          boxShadow: "none",
          borderRadius: 1,
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.gray[50] }}>
              <TableCell sx={{ fontWeight: 600 }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Weight</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measurements
              ?.slice()
              .reverse()
              .map((measurement) => (
                <TableRow
                  key={measurement.id}
                  sx={{ "&:hover": { backgroundColor: Colors.gray[50] } }}
                >
                  <TableCell>
                    {measurement.dateTime
                      ? new Date(measurement.dateTime).toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {measurement.weight
                      ? `${
                          unit === "lbs"
                            ? (measurement.weight * 2.20462).toFixed(1)
                            : measurement.weight
                        } ${unit}`
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            {(!measurements || measurements.length === 0) && (
              <TableRow>
                <TableCell colSpan={4} align="center" sx={{ py: 4 }}>
                  <Typography variant="body2" sx={{ color: Colors.gray[600] }}>
                    No measurements recorded yet
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
