/* eslint-disable */

import { FC, useState } from "react";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
  CircularProgress,
  Paper,
  Grid,
  Collapse,
  IconButton,
  TablePagination,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { ExpandLess, ExpandMore, Delete } from "@mui/icons-material";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import {
  useGetChallengeQuery,
  ChallengeTeam,
  useGetChallengeLeaderboardQuery,
  ChallengeLeaderboardItem,
  useAddUsersToChallengeMutation,
  useRemoveUsersFromChallengeMutation,
  ChallengeUser,
} from "shared/api";
import { Routes } from "shared/routers";
import { OrganisationClientSelector } from "../UserNotes/OrganisationClientSelector";

interface ChallengeContainerProps {
  openSidebar: () => void;
}

const ChallengeContainer: FC<ChallengeContainerProps> = ({ openSidebar }) => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const params = useParams();
  const challengeId = Number(params.id);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const { data: challenge, isLoading: isChallengeLoading } =
    useGetChallengeQuery(challengeId);

  const {
    data: leaderboard,
    isLoading: isLeaderboardLoading,
    refetch: refetchLeaderboard,
  } = useGetChallengeLeaderboardQuery(challengeId);

  const [expandedTeams, setExpandedTeams] = useState<Record<number, boolean>>(
    {}
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [addUsersToChallenge] = useAddUsersToChallengeMutation();
  const [removeUsersFromChallenge] = useRemoveUsersFromChallengeMutation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddUsers = async () => {
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const challengeUsersToUse = {
        userId: selectedUser,
        challengeId,
      } as ChallengeUser;

      await addUsersToChallenge({
        challengeId,
        challengeUsers: [challengeUsersToUse],
      }).unwrap();
      setSelectedUser(null);

      refetchLeaderboard();
      setSuccessMessage("User added to challenge successfully");
    } catch (error) {
      console.error("Failed to add users to challenge:", error);
      setErrorMessage("Failed to add users to challenge");
    }
  };

  const handleRemoveUser = async (userId: number) => {
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await removeUsersFromChallenge({
        challengeId,
        userIds: [userId],
      }).unwrap();

      refetchLeaderboard();
      setSuccessMessage("User removed from challenge successfully");
    } catch (error) {
      console.error("Failed to remove user from challenge:", error);
      setErrorMessage("Failed to remove user from challenge");
    }
  };

  if (isChallengeLoading || isLeaderboardLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const isTeamChallenge = challenge?.challengeCategory?.toString() === "Team";

  const toggleTeamExpansion = (teamId: number) => {
    setExpandedTeams((prev) => ({ ...prev, [teamId]: !prev[teamId] }));
  };

  const renderIndividualLeaderboard = () => {
    const paginatedLeaderboard = leaderboard?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>
                {challenge ? challenge.challengeTargetTypeFormatted : "Points"}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLeaderboard?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.rank}</TableCell>
                <TableCell>
                  <Link
                    to={`${Routes.profile.url}/${item.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={item.profileImageUrl}
                        alt={`${item.firstName} ${item.lastName}`}
                        sx={{ mr: 2 }}
                      />
                      <Typography>
                        {item.firstName} {item.lastName}
                      </Typography>
                    </Box>
                  </Link>
                </TableCell>
                <TableCell>{item.value?.toLocaleString()}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemoveUser(item.userId)}
                    sx={{ textTransform: "none" }}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={leaderboard?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    );
  };

  const renderTeamLeaderboard = () => {
    const teamScores: Record<
      number,
      {
        team: ChallengeTeam;
        totalScore: number;
        members: ChallengeLeaderboardItem[];
      }
    > = {};

    // Ensure teamScores is initialized properly
    challenge?.challengeTeams?.forEach((team) => {
      teamScores[team.id] = { team, totalScore: 0, members: [] };
    });

    // Check if leaderboard and challengeUsers exist
    if (leaderboard && challenge?.challengeUsers) {
      leaderboard.forEach((entry) => {
        // Find the user’s team
        const userEntry = challenge.challengeUsers.find(
          (user) => user.userId === entry.userId
        );

        if (userEntry && userEntry.teamId) {
          const teamId = userEntry.teamId;

          // Ensure the team exists in our map
          if (!teamScores[teamId]) {
            const teamData = challenge.challengeTeams.find(
              (team) => team.id === teamId
            );
            if (teamData) {
              teamScores[teamId] = {
                team: teamData,
                totalScore: 0,
                members: [],
              };
            }
          }

          // Add user score to team total
          teamScores[teamId].totalScore += entry.value || 0;

          // Add user to team members
          teamScores[teamId].members.push(entry);
        }
      });
    }

    // Sort teams based on total score
    const sortedTeams = Object.values(teamScores).sort(
      (a, b) => b.totalScore - a.totalScore
    );

    const paginatedTeams = sortedTeams.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>
                {challenge ? challenge.challengeTargetTypeFormatted : "Points"}
              </TableCell>
              <TableCell>Expand</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTeams.map((teamData, index) => (
              <>
                <TableRow key={teamData.team.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{teamData.team.name}</TableCell>
                  <TableCell>{teamData.totalScore.toLocaleString()}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => toggleTeamExpansion(teamData.team.id)}
                    >
                      {expandedTeams[teamData.team.id] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ padding: 0 }}>
                    <Collapse
                      in={expandedTeams[teamData.team.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Table size="small" sx={{ marginLeft: 4 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>
                              {challenge
                                ? challenge.challengeTargetTypeFormatted
                                : "Points"}
                            </TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {teamData.members.length > 0 ? (
                            teamData.members.map((member, memberIndex) => (
                              <TableRow key={memberIndex}>
                                <TableCell>{member.rank}</TableCell>
                                <TableCell>
                                  <Link
                                    to={`${Routes.profile.url}/${member.userId}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        src={member.profileImageUrl}
                                        alt={`${member.firstName} ${member.lastName}`}
                                        sx={{ mr: 2 }}
                                      />
                                      <Typography>
                                        {member.firstName} {member.lastName}
                                      </Typography>
                                    </Box>
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {member.value?.toLocaleString()}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleRemoveUser(member.userId)
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No participants yet
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedTeams.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    );
  };

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={{ mb: 2 }}>
        <Box sx={sharedStyles.containers.sidebar.heading}>
          <Typography sx={sharedStyles.headings.sidebar}>
            {challenge?.name} -{" "}
            {dayjs(challenge?.startDate).format("DD MMM YYYY")} -{" "}
            {dayjs(challenge?.endDate).format("DD MMM YYYY")}
          </Typography>
        </Box>
        <Box sx={{ mt: "-12px" }}>
          <Typography variant="body2" color="text.secondary">
            {challenge?.description}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <OrganisationClientSelector
          selectedUserId={selectedUser}
          setSelectedUserId={setSelectedUser}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddUsers}
          disabled={!selectedUser}
          sx={{ mt: 2, textTransform: "none" }}
        >
          Add user to challenge
        </Button>
      </Box>

      {!leaderboard || leaderboard.length === 0 ? (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
          There is no data available for this challenge yet.
        </Typography>
      ) : (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={12}>
            <Typography variant="h6">Leaderboard</Typography>
            {isTeamChallenge
              ? renderTeamLeaderboard()
              : renderIndividualLeaderboard()}
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export { ChallengeContainer };
