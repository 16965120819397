import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Alert, Modal, Icon, Button, Typography, Divider } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  CreateUserNoteWithMediaParams,
  useAddUserNoteMutation,
  useAddUserNoteWithMediaMutation,
  useStartTranscriptionMutation,
  useLazyGetTranscriptQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { UserNoteDropzone } from "pages/UserNotes/UserNoteDropzone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Routes } from "shared/routers";
import { OrganisationClientSelector } from "pages/UserNotes/OrganisationClientSelector";
import { CloudUpload } from "@mui/icons-material";

interface CreateUserNoteModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preSelectedUserId?: number;
}

const CreateUserNoteSchema = Yup.object().shape({
  note: Yup.string().optional(),
  audioFile: Yup.mixed().optional(),
});

const CreateUserNoteModal: FC<CreateUserNoteModalProps> = ({
  isOpenModal,
  handleCloseModal: propsHandleClose,
  preSelectedUserId = 0,
}) => {
  dayjs.extend(utc);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [dropzoneError, setDropzoneError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [addUserNote, { isLoading: isLoadingAdd }] = useAddUserNoteMutation();
  const [addUserNoteWithMedia, { isLoading: isLoadingAddWithMedia }] =
    useAddUserNoteWithMediaMutation();
  const [startTranscription] = useStartTranscriptionMutation();
  const [trigger, { isError, isLoading: isLoadingGetTranscript }] =
    useLazyGetTranscriptQuery();

  const [userId, setSelectedUserId] = useState<number | null>(
    preSelectedUserId
  );
  const [isPolling, setIsPolling] = useState(false);
  const [displayDropzone, setDisplayDropzone] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedUserId(preSelectedUserId);
  }, [isOpenModal]);

  const handleCloseModal = () => {
    propsHandleClose();
    setSelectedUserId(null);
    setSuccessMessage("");
    setErrorMessage("");
    setDropzoneError("");
    setDisplayDropzone(false);
  };

  const onSubmit = async (params: CreateUserNoteWithMediaParams) => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      if (params.audioFile) {
        const createdUserNote = await addUserNoteWithMedia(params).unwrap();
        if (createdUserNote) {
          startTranscription(createdUserNote.id).catch((error) => {
            console.error("Error processing user note:", error);
          });
          setIsPolling(true);

          const interval = setInterval(async () => {
            const result = await trigger(createdUserNote.id).unwrap();
            if (result?.userNoteStatus === "Completed") {
              setIsPolling(false);
              clearInterval(interval);
              navigate(`${Routes.userNotes.url}/${createdUserNote.id}/1`);
            }
            if (isError || result?.userNoteStatus === "Failed") {
              setIsPolling(false);
              clearInterval(interval);
              setErrorMessage(
                "There was an error processing your note. Please try again."
              );
            }
          }, 2500);
        }
      } else {
        const { audioFile, ...rest } = params;
        const createdUserNote = await addUserNote(rest).unwrap();
        navigate(`${Routes.userNotes.url}/${createdUserNote.id}/1`);
      }
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      audioFile: undefined,
    },
    validationSchema: CreateUserNoteSchema,
    onSubmit: (params) => {
      if (!userId) {
        setErrorMessage("Please select a client.");
        throw new Error("User ID is required");
      }
      onSubmit({
        title: params.title,
        dateTime: dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        userId,
        audioFile: params.audioFile,
      });
    },
  });

  const handleDropVideo = (file: File) => {
    setFieldValue("audioFile", file);
  };

  const handleDropzoneError = (e: string) => {
    setDropzoneError(e);
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            maxHeight: "calc(100vh - 200px)",
            height: "auto",
            width: "500px",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>

          {/* Client Selector */}
          {!preSelectedUserId && (
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  color: Colors.blue[1300],
                  mb: 1,
                }}
              >
                Select a client to get started
              </Typography>
              <OrganisationClientSelector
                setSelectedUserId={setSelectedUserId}
                selectedUserId={userId}
              />
            </Box>
          )}

          {/* Action Buttons */}
          {!displayDropzone ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleSubmit()}
                disabled={!userId}
                sx={{ height: "48px", textTransform: "none" }}
              >
                Create note
              </Button>
              <Divider>or</Divider>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setDisplayDropzone(true)}
                disabled={!userId}
                startIcon={<CloudUpload />}
                sx={{
                  height: "48px",
                  textTransform: "none",
                }}
              >
                Upload audio file
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                sx={{ mb: 2 }}
                startIcon={<Icon>arrow_back</Icon>}
                onClick={() => setDisplayDropzone(false)}
              >
                Back
              </Button>
              <UserNoteDropzone
                onDrop={handleDropVideo}
                onError={handleDropzoneError}
              />
              <PrimaryButton
                size="large"
                type="submit"
                value="Upload"
                loading={isLoadingAdd || isLoadingAddWithMedia || isPolling}
                disabled={
                  isLoadingAdd ||
                  isLoadingAddWithMedia ||
                  isPolling ||
                  !values.audioFile
                }
                variant="contained"
                fullWidth
                onClick={() => handleSubmit()}
                sx={{ mt: 2 }}
              >
                Upload and process
              </PrimaryButton>
            </Box>
          )}

          {/* Error Messages */}
          {(errorMessage || dropzoneError) && (
            <Alert sx={{ mt: 2 }} variant="outlined" severity="error">
              {errorMessage || dropzoneError}
            </Alert>
          )}

          {/* Success Messages */}
          {successMessage && (
            <Alert sx={{ mt: 2 }} variant="outlined" severity="success">
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateUserNoteModal };
