/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Icon,
  Modal,
  TextField,
  Typography,
  Alert,
  Divider,
  Grid,
  FormControl,
  MenuItem,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import {
  BillingProduct,
  useGetBillingProductPriceUsersQuery,
  useAddUserToBillingProductMutation,
  AddUserToBillingProductRequest,
  useRemoveUserFromBillingProductMutation,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { OrganisationClientSelector } from "pages/UserNotes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { useTranslation } from "react-i18next";

interface BillingProductItemModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  billingProduct: BillingProduct;
}

const BillingProductItemModal: FC<BillingProductItemModalProps> = ({
  isOpenModal,
  handleCloseModal,
  billingProduct,
}) => {
  const { t } = useTranslation();

  const [selectedPriceId, setSelectedPriceId] = useState<number | null>(null);

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const {
    data: billingProductPriceUsers,
    isLoading: isLoadingUsers,
    refetch: refetchBillingProductPriceUsers,
  } = useGetBillingProductPriceUsersQuery(billingProduct?.id);

  const [removeUserFromBillingProduct, { isLoading: isRemovingUser }] =
    useRemoveUserFromBillingProductMutation();

  const handleRemoveUserFromBillingProduct = (billingProductUserId: number) => {
    setErrorMessage("");
    setSuccessMessage("");

    const selectedUser = billingProductPriceUsers?.find(
      (user) => user.id === billingProductUserId
    );

    removeUserFromBillingProduct({
      billingProductUserId: selectedUser?.userId,
      billingProductId: selectedUser?.billingProductId,
      billingProductPriceId: selectedUser?.billingProductPriceId,
    } as AddUserToBillingProductRequest)
      .unwrap()
      .then(() => {
        refetchBillingProductPriceUsers();
        setSuccessMessage("Athlete removed from billing product successfully");
        setSelectedUserId(null);
        setSelectedPriceId(null);
      })
      .catch((error) => {
        setErrorMessage(error?.data?.message || t("errors.server-unable"));
      })
      .finally(() => {
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 1500);
      });
  };

  const [addUserToBillingProduct, { isLoading: isAddingUser }] =
    useAddUserToBillingProductMutation();

  const handleAddUserToBillingProduct = () => {
    setErrorMessage("");
    setSuccessMessage("");

    if (!selectedUserId) {
      setErrorMessage("Please select an athlete");
      return;
    }

    if (
      billingProductPriceUsers?.find((user) => user.userId === selectedUserId)
    ) {
      setErrorMessage("Athlete already added to billing product");
      return;
    }

    if (!selectedPriceId) {
      setErrorMessage("Please select a price");
      return;
    }

    addUserToBillingProduct({
      userIdToAdd: selectedUserId,
      billingProductId: billingProduct.id,
      billingProductPriceId: selectedPriceId,
    } as AddUserToBillingProductRequest)
      .unwrap()
      .then(() => {
        refetchBillingProductPriceUsers();
        setSuccessMessage("Athlete added to billing product successfully");
        setSelectedUserId(null);
        setSelectedPriceId(null);
      })
      .catch((error) => {
        setErrorMessage(error?.data?.message || t("errors.server-unable"));
      })
      .finally(() => {
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 1500);
      });
  };

  const handleOpenProfile = (userId: number) => {
    window.open(`/profile/${userId}/7`, "_blank");
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          ...sharedStyles.containers.modal,
          alignItems: { xs: "flex-end", md: "center" },
        }}
      >
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            width: { xs: "100%", md: "50%" },
            height: { xs: "90vh", md: "auto" },
            maxHeight: { xs: "90vh", md: "80vh" },
            borderRadius: { xs: "16px 16px 0 0", md: "8px" },
            m: { xs: 0, md: 3 },
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Fixed header area */}
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" sx={{ color: Colors.blue[1300] }}>
                Manage membership
              </Typography>
              <Icon onClick={handleCloseModal} sx={{ cursor: "pointer" }}>
                close_icon
              </Icon>
            </Box>
            <Typography sx={{ color: Colors.blue[1300], mb: 2 }}>
              Assign clients to memberships
            </Typography>
          </Box>

          {/* Scrollable content area */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              minHeight: 0,
            }}
          >
            <Typography sx={{ color: Colors.blue[1300], mb: 2 }}>
              {billingProduct?.name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "14px",
                color: Colors.blue[1300],
              }}
            >
              {billingProduct?.description}
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "18px",
                color: Colors.blue[1300],
              }}
            >
              Payment terms
            </Typography>

            <Divider sx={{ mt: 2, mb: 2 }} />
            {billingProduct &&
              billingProduct?.activeBillingProductPrices &&
              billingProduct?.activeBillingProductPrices.length > 0 && (
                <Box>
                  {billingProduct?.activeBillingProductPrices.map(
                    (p, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ flex: 1 }}>
                          {p.billingFrequency}: ${p.price} - {p.description}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={p.ddrPageUrl}
                          disabled
                          sx={{ flex: 1, ml: 2 }}
                        />
                      </Box>
                    )
                  )}
                </Box>
              )}
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "18px",
                color: Colors.blue[1300],
              }}
            >
              Athletes
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <OrganisationClientSelector
                  setSelectedUserId={setSelectedUserId}
                  selectedUserId={selectedUserId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      fullWidth
                      label="Select Price"
                      variant="outlined"
                      value={selectedPriceId || ""}
                      onChange={(e) =>
                        setSelectedPriceId(Number(e.target.value))
                      }
                    >
                      {billingProduct.activeBillingProductPrices.map(
                        (price) => (
                          <MenuItem key={price.id} value={price.id}>
                            {price.billingFrequency}: ${price.price} -{" "}
                            {price.description}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-start">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={
                    isAddingUser ||
                    !selectedUserId ||
                    isLoadingUsers ||
                    isRemovingUser ||
                    !selectedPriceId
                  }
                  onClick={handleAddUserToBillingProduct}
                  loading={isAddingUser}
                  sx={{
                    mt: 2,
                    mb: 2,
                    textTransform: "none",
                  }}
                >
                  Add athlete
                </LoadingButton>
              </Grid>
            </Grid>

            {!isLoadingUsers && billingProductPriceUsers?.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                {billingProductPriceUsers?.map((billingProductUser) => (
                  <Box
                    sx={{
                      width: "100%",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: "12px",
                    }}
                    key={billingProductUser.id}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IdenticonAvatar
                        seedValue={billingProductUser.userId.toString()}
                        profileImageUrl={billingProductUser.profileImageUrl}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          ml: 2,
                        }}
                      >
                        <Link
                          onClick={() =>
                            handleOpenProfile(billingProductUser.userId)
                          }
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "140%",
                            color: Colors.blue[800],
                            textAlign: "left",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                            },
                          }}
                        >
                          {billingProductUser.firstName}{" "}
                          {billingProductUser.lastName}
                        </Link>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "140%",
                            color: `${Colors.blue[1300]}`,
                          }}
                        >
                          $ {billingProductUser.activeBillingProductPrice.price}{" "}
                          -{" "}
                          {
                            billingProductUser.activeBillingProductPrice
                              .billingFrequency
                          }{" "}
                          -{" "}
                          {
                            billingProductUser.activeBillingProductPrice
                              .description
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <LoadingButton
                      onClick={() =>
                        handleRemoveUserFromBillingProduct(
                          billingProductUser.id
                        )
                      }
                      loading={isRemovingUser}
                      disabled={isRemovingUser}
                      sx={{
                        minWidth: "auto",
                        padding: 0,
                      }}
                    >
                      <Icon
                        sx={{
                          color: `${Colors.gray[1400]}`,
                          cursor: "pointer",
                        }}
                      >
                        close_icon
                      </Icon>
                    </LoadingButton>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          {/* Fixed footer area */}
          <Box sx={{ mt: 2 }}>
            {errorMessage && (
              <Alert sx={{ width: "100%" }} variant="outlined" severity="error">
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert
                sx={{ width: "100%" }}
                variant="outlined"
                severity="success"
              >
                {successMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { BillingProductItemModal };
