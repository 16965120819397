import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { HealthSummaryResponse } from "shared/api/healthScores/models";
import { ActivityCard } from "./ActivityCard";
import {
  determinePerformanceLevel,
  getGradientForScore,
  getFriendlyNameOfState,
} from "./formatters";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface StepsDetailCardProps {
  insights: HealthSummaryResponse | null;
  open: boolean;
  onClose: () => void;
  dailyDeviceStats: {
    steps: number;
    activeCalories: number;
  };
}

export const StepsDetailCard: React.FC<StepsDetailCardProps> = ({
  insights,
  open,
  onClose,
  dailyDeviceStats,
}) => {
  if (!insights) return null;

  const todayStepsPerformance = determinePerformanceLevel(
    dailyDeviceStats.steps,
    10000,
    "activity"
  );

  const caloriesBurnedPerformance = determinePerformanceLevel(
    dailyDeviceStats.activeCalories,
    500,
    "activity"
  );

  const averageDailyStepsPerformance = determinePerformanceLevel(
    insights.averageDailySteps ?? 0,
    10000,
    "activity"
  );

  const averageDailyActiveCaloriesPerformance = determinePerformanceLevel(
    insights.averageDailyActiveCalories ?? 0,
    500,
    "activity"
  );

  const activeHours = insights.factors.find(
    (factor) => factor.name === "active_hours"
  );

  const intenseActivityDuration = insights.factors.find(
    (factor) => factor.name === "intense_activity_duration"
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            Activity details
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ActivityCard
              title="Today"
              value={Math.floor(dailyDeviceStats.steps)}
              target={10000}
              unit="steps"
              gradient={getGradientForScore(todayStepsPerformance)}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Active calories burned"
              value={Math.floor(dailyDeviceStats.activeCalories)}
              target={500}
              unit="kcal"
              gradient={getGradientForScore(caloriesBurnedPerformance)}
            />
          </Grid>
          {!!intenseActivityDuration && (
            <Grid item xs={6}>
              <ActivityCard
                title="Intense activity duration"
                value={intenseActivityDuration.value}
                target={intenseActivityDuration.goal ?? 0}
                unit="minutes"
                gradient={getGradientForScore(
                  intenseActivityDuration.state ?? ""
                )}
              />
            </Grid>
          )}

          {!!activeHours && (
            <Grid item xs={6}>
              <ActivityCard
                title="Active hours"
                value={activeHours.value}
                target={activeHours.goal ?? 0}
                unit="hours"
                gradient={getGradientForScore(activeHours.state ?? "")}
              />
            </Grid>
          )}

          {!!insights.averageDailySteps && (
            <Grid item xs={6}>
              <ActivityCard
                title="Daily average steps (last 30 days)"
                value={Math.round(insights.averageDailySteps)}
                target={10000}
                unit="steps"
                gradient={getGradientForScore(averageDailyStepsPerformance)}
              />
            </Grid>
          )}
          {!!insights.averageDailyActiveCalories && (
            <Grid item xs={6}>
              <ActivityCard
                title="Average active calories (last 30 days)"
                value={Math.round(insights.averageDailyActiveCalories)}
                target={500}
                unit="kcal"
                gradient={getGradientForScore(
                  averageDailyActiveCaloriesPerformance
                )}
              />
            </Grid>
          )}
        </Grid>
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: "#f8f9ff",
            border: "1px solid rgba(108, 92, 231, 0.1)",
            boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#6C5CE7",
              mb: 2,
              fontWeight: 600,
              fontSize: "1.1rem",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LightbulbOutlinedIcon sx={{ fontSize: "1.2rem" }} />
            Insight
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.95rem",
              color: "#444",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            {insights.overallInsight}
          </Typography>
          <Link
            href="https://learn.sahha.ai/a-30-activity-score-explained"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#6C5CE7",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "0.85rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Learn more about activity scores by clicking here. Powered by
            Sahha.ai
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
