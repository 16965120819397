/* es-lint-disable */

import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Dialog, Paper } from "@mui/material";
import { WrappedData } from "shared/api";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { WrappedPresentation } from "./WrappedPresentation";

const WrappedPreview: FC<{ mediaUrl: string }> = ({ mediaUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPresentation = () => {
    setIsOpen(true);
  };

  const [wrappedData, setWrappedData] = useState<WrappedData | null>(null);

  useEffect(() => {
    try {
      // Clean up the JSON string
      let jsonString = mediaUrl;

      // 1. Remove surrounding quotes if present
      if (jsonString.startsWith('"') && jsonString.endsWith('"')) {
        jsonString = jsonString.slice(1, -1);
      }

      // 2. Remove any query string parameters (everything after '?')
      const questionMarkIndex = jsonString.indexOf("?");
      if (questionMarkIndex !== -1) {
        jsonString = jsonString.substring(0, questionMarkIndex);
      }

      // 3. Replace escaped quotes with regular quotes
      jsonString = jsonString.replace(/\\"/g, '"');

      // 4. Parse the cleaned JSON
      const parsedData = JSON.parse(jsonString) as WrappedData;

      // 5. Map lowercase properties to the expected case if needed
      const normalizedData: WrappedData = {
        title: parsedData.title,
        description: parsedData.description,
        imageUrl: parsedData.imageUrl,
        audioUrl: parsedData.audioUrl,
        wrappedItems: parsedData.wrappedItems.map((item) => ({
          imageUrl: item.imageUrl,
          audioUrl: item.audioUrl,
          order: item.order,
        })),
      };

      setWrappedData(normalizedData);
    } catch (error) {
      console.error("Error parsing wrapped data: ", error);
    }
  }, [mediaUrl]);

  if (!wrappedData) {
    return (
      <Paper elevation={1} sx={{ p: 2, mt: 1, mb: 1, textAlign: "center" }}>
        <Typography color="error">Unable to load</Typography>
      </Paper>
    );
  }

  return (
    <>
      <Paper
        elevation={2}
        onClick={handleOpenPresentation}
        sx={{
          mt: 1,
          mb: 2,
          overflow: "hidden",
          borderRadius: "12px",
          cursor: "pointer",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: 4,
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "relative",
              paddingBottom: "56.25%", // 16:9 aspect ratio
              minHeight: "180px", // Ensure minimum height
            }}
          >
            <img
              src={wrappedData.imageUrl}
              alt="Year in Review"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.8))", // Stronger gradient
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Better space distribution
                alignItems: "center",
                color: "white",
                p: { xs: 2, sm: 3 }, // Responsive padding
                textAlign: "center",
                overflow: "hidden", // Prevent content overflow
              }}
            >
              <Box sx={{ width: "100%", mt: 2 }}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 1,
                    fontWeight: "bold",
                    textShadow: "0px 1px 3px rgba(0,0,0,0.6)", // Improve readability
                  }}
                >
                  Your Challenge Wrapped is here
                </Typography>
              </Box>

              <Box sx={{ my: "auto" }}>
                <PlayArrowIcon
                  sx={{
                    fontSize: { xs: 40, sm: 50, md: 60 },
                    filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.5))", // Add shadow to icon
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            m: 0,
            p: 0,
          },
        }}
      >
        <WrappedPresentation
          wrappedData={wrappedData}
          onClose={() => setIsOpen(false)}
        />
      </Dialog>
    </>
  );
};

export { WrappedPreview };
