import { createElement, useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";

const PricingPage = () => {
  const [open, setOpen] = useState(true);

  const paymentSuccess = new URLSearchParams(window.location.search).get(
    "paymentSuccess"
  );

  const paymentSuccessIsValid =
    (paymentSuccess && paymentSuccess === "true") ||
    localStorage.getItem("paymentSuccess") === "true";

  useEffect(() => {
    if (paymentSuccessIsValid) {
      localStorage.setItem("paymentSuccess", "true");
      setOpen(false);
    }
  }, [paymentSuccessIsValid]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClose = () => {
    console.log("close");
  };

  const pricingTable = createElement("stripe-pricing-table", {
    "pricing-table-id": process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    "publishable-key": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="pricing-modal-title"
      aria-describedby="pricing-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          maxWidth: "90%",
          width: "600px",
          textAlign: "center",
        }}
      >
        <Typography
          id="pricing-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Welcome to FitFocus
        </Typography>
        <Typography id="pricing-modal-description" sx={{ mb: 4 }}>
          To get started, please select a payment plan to get started. You will
          receive a 14-day free trial with any plan.
        </Typography>
        <Box sx={{ mb: 4 }}>{pricingTable}</Box>
        <Typography variant="body2">
          It can take 5-10 minutes for your subscription to be activated. Try
          refreshing the page if you do not see the changes. If you have any
          issues, please contact support at{" "}
          <a href="mailto:support@fitfocus.io">support@fitfocus.io</a>.
        </Typography>
      </Box>
    </Modal>
  );
};

export { PricingPage };
