import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Divider,
  Tooltip,
  IconButton,
  Skeleton,
  Select,
  MenuItem,
  CardMedia,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InfoOutlined, TrendingDown, TrendingUp } from "@mui/icons-material";
import { useTypedSelector } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  useGetWellbeingDashboardForAllUsersQuery,
  XYChartData,
  WellbeingDashboardRequest,
  WellbeingDashboardResponse,
} from "shared/api";
import { getGraphicSvg } from "shared/themes";
import { Routes } from "shared/routers";
import { WellbeingChart } from "./WellbeingChart";
import { EmployeeDashboardItem } from "./EmployeeDashboardItem";

interface AdminWorkplaceWellbeingDashboardProps {}

const AdminWorkplaceWellbeingDashboard: FC<
  AdminWorkplaceWellbeingDashboardProps
> = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const firstName = useTypedSelector((state) => state.user.firstName);

  const currentTime = dayjs().format("HH:mm");
  const time = dayjs(currentTime, "HH:mm");

  const greeting = () => {
    if (time.isBefore(dayjs("12:00", "HH:mm"))) {
      return `Good morning ${firstName} 🌞`;
    }
    if (time.isBefore(dayjs("17:00", "HH:mm"))) {
      return `Good afternoon ${firstName} 🌤️`;
    }
    return `Good evening ${firstName} 🌙`;
  };

  const showSidebar = matches && isOpenSidebar;

  const [dateFrequency, setDateFrequency] = useState("month");
  const [isFetching, setIsFetching] = useState(false);
  const [wellbeingDataLocal, setWellbeingDataLocal] =
    useState<WellbeingDashboardResponse | null>(null);

  const {
    data: wellbeingData,
    isLoading: wellbeingDataLoading,
    refetch,
  } = useGetWellbeingDashboardForAllUsersQuery({
    dateFrequency,
  } as WellbeingDashboardRequest);

  // Clear local data and set fetching state when dateFrequency changes
  useEffect(() => {
    setIsFetching(true);
    setWellbeingDataLocal(null);
    refetch();
  }, [dateFrequency, refetch]);

  // Update local state when new data arrives
  useEffect(() => {
    if (!wellbeingDataLoading && wellbeingData) {
      setWellbeingDataLocal(wellbeingData);
      setIsFetching(false);
    }

    if (!wellbeingDataLoading && !wellbeingData) {
      setIsFetching(false);
    }
  }, [wellbeingData, wellbeingDataLoading]);

  const isTrendingUp = (trendData: XYChartData[]) => {
    if (trendData.length < 2) return false;
    return trendData[trendData.length - 1].value > trendData[0].value;
  };

  const trendIcon = (isTrendingUp: boolean) =>
    isTrendingUp ? (
      <TrendingUp color="success" />
    ) : (
      <TrendingDown color="error" />
    );

  const wellbeingTrendData = wellbeingDataLocal?.wellbeingChartData ?? [];

  const stepsTrendData = wellbeingDataLocal?.stepsChartData ?? [];

  const activeCaloriesTrendData = wellbeingDataLocal?.activeCaloriesData ?? [];

  const activeHoursTrendData = wellbeingDataLocal?.activeHoursData ?? [];

  const activityTrendData = wellbeingDataLocal?.activityData ?? [];

  const sleepDurationTrendData =
    wellbeingDataLocal?.sleepDurationTotalData ?? [];

  const navigateToChallenge = (challengeId?: number) => () => {
    if (challengeId) {
      navigate(`${Routes.challenges.url}/${challengeId}`);
    } else {
      navigate(Routes.challenges.url);
    }
  };

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={{ mb: 2 }}>
        <Box sx={sharedStyles.containers.sidebar.heading}>
          <Typography sx={sharedStyles.headings.sidebar}>
            {greeting()}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: "-12px" }}
          >
            View your overall workplace wellbeing and engagement data
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* Show loading state */}
      {isFetching && (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={8}>
            <Card
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
              sx={{ height: "100%" }}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Typography
                    variant="h6"
                    className="text-xl font-semibold text-gray-800"
                  >
                    Wellbeing
                  </Typography>
                  <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                    <Skeleton variant="text" width={100} />
                  </Box>
                </Box>
                <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                  <Skeleton variant="rectangular" height={200} />
                </div>
                <Skeleton variant="text" width={200} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={400} />
          </Grid>
        </Grid>
      )}

      {/* Show "no data" if fetch is complete but no data */}
      {!isFetching && !wellbeingDataLocal && (
        <Box>
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary">
            There is no data available for the selected period. Data can take up
            to 72 hours to appear for new users.
          </Typography>
        </Box>
      )}

      {/* Show actual data */}
      {!isFetching && wellbeingDataLocal && (
        <>
          {wellbeingDataLocal?.activeChallenge && (
            <Grid container spacing={2} alignItems="stretch" sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    width: "100%",
                    height: "180px",
                    mb: "16px",
                    border: "1px solid #EEEEEE",
                    borderRadius: "8px",
                    boxShadow: "none",
                    display: "flex",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 175 }}
                    image={getGraphicSvg("challenge")}
                  />
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Typography
                        variant="h6"
                        className="text-xl font-semibold text-gray-800"
                      >
                        {wellbeingDataLocal?.activeChallenge.name}
                      </Typography>
                      <Tooltip
                        title="This is the current active challenge for employees. The challenge is designed to encourage employees to be more active and improve their overall wellbeing."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {dayjs(
                        wellbeingDataLocal?.activeChallenge.startDate
                      ).format("DD MMM")}{" "}
                      to{" "}
                      {dayjs(
                        wellbeingDataLocal?.activeChallenge.endDate
                      ).format("DD MMM")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {wellbeingDataLocal?.activeChallenge.description}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ textTransform: "none" }}
                        onClick={navigateToChallenge(
                          wellbeingDataLocal?.activeChallenge.id
                        )}
                      >
                        Explore
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {`${dayjs().subtract(31, "days").format("D MMM YYYY")} - ${dayjs()
              .subtract(1, "day")
              .format("D MMM YYYY")}`}
          </Typography>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={8}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Wellbeing
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingDataLocal?.wellbeingAverage}
                      </Typography>
                      {trendIcon(isTrendingUp(wellbeingTrendData))}
                      <Tooltip
                        title="This score is calculated based on aggregated data from various health metrics, including activity, sleep, and mental health. The trend indicates whether the score has improved or declined over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Wellbeing"
                      chartSeriesData={wellbeingTrendData}
                      minYValue={wellbeingTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        wellbeingTrendData[0]?.value ?? 0
                      )}
                      maxYValue={wellbeingTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        wellbeingTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                  <Typography variant="body2" color="text.secondary">
                    {wellbeingDataLocal?.wellbeingSummary}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      People needing support
                    </Typography>
                    <Tooltip title="These are the employees with the lowest wellbeing scores who may need additional support.">
                      <IconButton size="small">
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    {wellbeingDataLocal?.wellbeingUsersToWatch.map((user) => (
                      <EmployeeDashboardItem
                        key={user.userId}
                        userId={user.userId}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        profileImageUrl={user.profileImageUrl}
                        score={user.wellbeing}
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 4, mb: 4 }} />

          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Steps
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingDataLocal?.stepsTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(stepsTrendData))}
                      <Tooltip
                        title="The total number of steps taken by employees on average. The trend indicates whether the number of steps has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Steps"
                      chartSeriesData={stepsTrendData}
                      minYValue={stepsTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        stepsTrendData[0]?.value ?? 0
                      )}
                      maxYValue={stepsTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        stepsTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Active calories
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingDataLocal?.activeCaloriesTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(activeCaloriesTrendData))}
                      <Tooltip
                        title="The total number of active calories burned by employees on average. The trend indicates whether the number of active calories has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Active calories"
                      chartSeriesData={activeCaloriesTrendData}
                      minYValue={activeCaloriesTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        activeCaloriesTrendData[0]?.value ?? 0
                      )}
                      maxYValue={activeCaloriesTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        activeCaloriesTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 4, mb: 4 }} />

          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Active minutes
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingDataLocal?.activeHoursTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(activeHoursTrendData))}
                      <Tooltip
                        title="The total number of minutes spent being active by employees on average. The trend indicates whether the number of active minutes has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Active hours"
                      chartSeriesData={activeHoursTrendData}
                      minYValue={activeHoursTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        activeHoursTrendData[0]?.value ?? 0
                      )}
                      maxYValue={activeHoursTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        activeHoursTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Sleep hours
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingDataLocal?.sleepDurationTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(sleepDurationTrendData))}
                      <Tooltip
                        title="The total number of hours spent sleeping by employees on average. The trend indicates whether the number of hours spent sleeping has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Sleep hours"
                      chartSeriesData={sleepDurationTrendData}
                      minYValue={sleepDurationTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        sleepDurationTrendData[0]?.value ?? 0
                      )}
                      maxYValue={sleepDurationTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        sleepDurationTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 4, mb: 4 }} />

          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Activity
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingDataLocal?.activityAverage.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(activityTrendData))}
                      <Tooltip
                        title="The average of activity levels of employees. The trend indicates whether the activity levels have increased or decreased over the recent period. This is a measure of various metrics such as steps, floors climbed and general movement."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Activity"
                      chartSeriesData={activityTrendData}
                      minYValue={activityTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        activityTrendData[0]?.value ?? 0
                      )}
                      maxYValue={activityTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        activityTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export { AdminWorkplaceWellbeingDashboard };
