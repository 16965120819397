import React, { useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { App as CapacitorApp } from "@capacitor/app";
import { BrowserRouter, Routes as RoutesDom, Route } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import {
  AdminDashboard,
  AdminWorkplaceWellbeingDashboard,
  AthleteDashboard,
  EmployeeDashboard,
  EmployeeUserProfile,
  WorkoutDetails,
  SignIn,
  FitnessProfessionalSignup,
  FitnessProfessionalSignupEmailVerification,
  ClientSignupSuccess,
  ClientSignupEmailVerification,
  ResetPasswordEmailInput,
  ResetPasswordVerification,
  ResetPasswordSuccess,
  ExerciseView,
  Timeline,
  AdminTimelineView,
  WorkoutProgramBuilderSuccessScreen,
  AthletePrograms,
  AdminPrograms,
  ViewPrograms,
  TrainingInsights,
  AthleteTrainingInsights,
  LiveTraining,
  UserProfile,
  OrganisationManagement,
  OrganisationIntegrationSetupSuccess,
  AthleteUserProfile,
  FitnessProfessionalSignupSuccess,
  Users,
  Employees,
  Layout,
  Workflows,
  IndividualWorkflow,
  LeadsContainer,
  IndividualLead,
  BillingContainer,
  UserNoteView,
  OrganisationUserNotesTable,
  ExerciseLibrary,
  ModernProgramBuilder,
  AthleteProgramWorkouts,
  AthleteWorkouts,
  BookingsContainer,
  AthleteBookingSchedule,
  NutritionPlans,
  NutritionPlanView,
  AthleteNutrition,
  AthleteNutritionView,
  AthleteTimelineSelector,
  AthleteProgress,
  Challenges,
  ChallengeContainer,
  WorkoutTemplateThankYouPage,
} from "pages";
import { useAnalytics, useGetUser } from "shared/hooks";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import {
  setSidebarState,
  toggleSidebarState,
} from "shared/stores/sidebar/slice";
import { useLazyGetLatestAppVersionQuery } from "shared/api";
import {
  ProtectedRoute,
  ProtectedAdminRoute,
  ProtectedAdminOrCoachRoute,
} from "./ProtectedRoute";
import { Routes } from "./Routes";

export const Router: React.FC = () => {
  const { startSession } = useAnalytics();
  useGetUser();

  const [getLatestAppVersion] = useLazyGetLatestAppVersionQuery();
  const [updateSnackbarOpen, setUpdateSnackbarOpen] = useState(false);
  const [forceUpdateDialogOpen, setForceUpdateDialogOpen] = useState(false);
  const [devicePlatform, setDevicePlatform] = useState<string | null>(null);

  startSession();

  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const organisationType = useTypedSelector(userSelectors.organisationType);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const dispatch = useTypedDispatch();

  const openSidebar = () => {
    dispatch(setSidebarState(true));
  };

  const closeSidebar = () => {
    dispatch(setSidebarState(false));
  };

  const toggleSidebar = () => {
    dispatch(toggleSidebarState());
  };

  const [createWorkoutUserId, setCreateWorkoutUserId] = useState<number | null>(
    null
  );
  const getUserIdForWorkoutCreation = (userId: number) =>
    setCreateWorkoutUserId(userId);

  const [isMobileApp, setIsMobileApp] = useState(false);

  const getAppVersion = async () => {
    try {
      const device = await Device.getInfo();
      if (device.platform === "web") {
        return null;
      }
      const info = await CapacitorApp.getInfo();
      return info.version;
    } catch (error) {
      console.error("Error getting app version:", error);
      return null;
    }
  };

  // Helper function to compare semantic versions
  // Returns true if remoteVersion is newer than localVersion
  const compareVersions = (
    remoteVersion: string,
    localVersion: string
  ): boolean => {
    const remote = remoteVersion.split(".").map((part) => parseInt(part, 10));
    const local = localVersion.split(".").map((part) => parseInt(part, 10));

    // Ensure both arrays have at least 3 elements (major.minor.patch)
    while (remote.length < 3) remote.push(0);
    while (local.length < 3) local.push(0);

    const majorVersion = remote[0];
    const minorVersion = remote[1];
    const patchVersion = remote[2];

    const localMajorVersion = local[0];
    const localMinorVersion = local[1];
    const localPatchVersion = local[2];

    // Compare major version
    if (majorVersion > localMajorVersion) return true;
    if (majorVersion < localMajorVersion) return false;

    // If major versions are equal, compare minor version
    if (minorVersion > localMinorVersion) return true;
    if (minorVersion < localMinorVersion) return false;

    // If minor versions are equal, compare patch version
    if (patchVersion > localPatchVersion) return true;

    // If we get here, local version is either equal or ahead of remote
    return false;
  };

  const checkForUpdates = async () => {
    try {
      const currentVersion = await getAppVersion();
      if (!currentVersion) return; // Skip for web or if version can't be determined

      const device = await Device.getInfo();
      setDevicePlatform(device.platform);

      const response = await getLatestAppVersion().unwrap();
      if (response && response.length > 0) {
        // Find the version for the current platform
        const platformVersion = response.find(
          (v) =>
            (device.platform === "ios" && v.platform === "ios") ||
            (device.platform === "android" && v.platform === "android")
        );

        if (platformVersion) {
          // Compare versions semantically
          const isNewer = compareVersions(
            platformVersion.version,
            currentVersion
          );

          if (isNewer) {
            if (platformVersion.forceUpdate) {
              setForceUpdateDialogOpen(true);
            } else {
              setUpdateSnackbarOpen(true);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUpdateSnackbarOpen(false);
  };

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);

      // Set up version checking for mobile apps
      CapacitorApp.addListener(
        "appStateChange",
        async (state: { isActive: boolean }) => {
          if (state.isActive) {
            await checkForUpdates();
          }
        }
      );

      // Initial check on app launch
      await checkForUpdates();

      return;
    }
    setIsMobileApp(false);
  };

  useEffect(() => {
    checkDeviceIsMobileApp();

    // Cleanup listener on component unmount
    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, []);

  const renderUserProfile = () => {
    if (isMobileApp && organisationType === "WorkplaceWellbeing") {
      return <AthleteUserProfile />;
    }

    if (isAdminOrCoach && organisationType === "WorkplaceWellbeing") {
      return <EmployeeUserProfile closeSidebar={closeSidebar} />;
    }

    if (isAdminOrCoach) {
      return <UserProfile closeSidebar={closeSidebar} />;
    }

    return <AthleteUserProfile />;
  };

  const renderDashboard = () => {
    if (organisationType === "WorkplaceWellbeing" && isMobileApp) {
      return (
        <Layout
          isOpenSidebar={isOpenSidebar}
          closeSidebar={closeSidebar}
          openSidebar={openSidebar}
          toggleSidebar={toggleSidebar}
        >
          <EmployeeDashboard />
        </Layout>
      );
    }

    if (isAdminOrCoach) {
      if (organisationType === "WorkplaceWellbeing") {
        return (
          <Layout
            isOpenSidebar={isOpenSidebar}
            closeSidebar={closeSidebar}
            openSidebar={openSidebar}
            toggleSidebar={toggleSidebar}
          >
            <AdminWorkplaceWellbeingDashboard />
          </Layout>
        );
      }
      return (
        <AdminDashboard
          closeSidebar={closeSidebar}
          isOpenSidebar={isOpenSidebar}
          toggleSidebar={toggleSidebar}
          openSidebar={openSidebar}
          getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
        />
      );
    }

    if (organisationType === "WorkplaceWellbeing") {
      return (
        <Layout
          isOpenSidebar={isOpenSidebar}
          closeSidebar={closeSidebar}
          openSidebar={openSidebar}
          toggleSidebar={toggleSidebar}
        >
          <EmployeeDashboard />
        </Layout>
      );
    }

    return (
      <Layout
        isOpenSidebar={isOpenSidebar}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
        toggleSidebar={toggleSidebar}
      >
        <AthleteDashboard />
      </Layout>
    );
  };

  // Force update handlers
  const openAppStore = () => {
    const FITFOCUS_IOS_APP_ID = "6476741403";
    const FITFOCUS_PACKAGE_NAME = "io.fitfocus.app";
    const url =
      devicePlatform === "ios"
        ? `itms-apps://itunes.apple.com/app/${FITFOCUS_IOS_APP_ID}` // Replace with actual App Store URL
        : `market://details?id=${FITFOCUS_PACKAGE_NAME}`; // Replace with actual Play Store URL

    window.location.href = url;
  };

  return (
    <BrowserRouter>
      <RoutesDom>
        <Route path={Routes.signIn.url} element={<SignIn />} />
        <Route
          path={Routes.fitnessProfessionalSignup.url}
          element={<FitnessProfessionalSignup />}
        />
        <Route
          path={Routes.termsOfService.url}
          element={<FitnessProfessionalSignup />}
        />
        <Route
          path={Routes.fitnessProfessionalSignupEmailVerification.url}
          element={<FitnessProfessionalSignupEmailVerification />}
        />
        <Route
          path={Routes.fitnessProfessionalSignupSuccess.url}
          element={<FitnessProfessionalSignupSuccess />}
        />
        <Route
          path={Routes.clientSignupEmailVerification.url}
          element={<ClientSignupEmailVerification />}
        />
        <Route
          path={Routes.clientSignupSuccess.url}
          element={<ClientSignupSuccess />}
        />
        <Route
          path={Routes.forgotPassword.url}
          element={<ResetPasswordEmailInput />}
        />
        <Route
          path={Routes.forgotPasswordVerification.url}
          element={<ResetPasswordVerification />}
        />
        <Route
          path={Routes.forgotPasswordSuccess.url}
          element={<ResetPasswordSuccess />}
        />
        <Route
          path={Routes.dashboard.url}
          element={<ProtectedRoute>{renderDashboard()}</ProtectedRoute>}
        />
        <Route
          path={Routes.programs.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <AdminPrograms
                    getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                    setCreateWorkoutUserId={setCreateWorkoutUserId}
                  />
                ) : (
                  <AthletePrograms />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.workoutTemplateThankYouPage.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <WorkoutTemplateThankYouPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.programs.url}/:id`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <ViewPrograms
                    openSidebar={openSidebar}
                    getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                  />
                ) : (
                  <AthleteProgramWorkouts />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workouts.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteWorkouts />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workouts.url}/:userId/:completed?`}
          element={
            <ProtectedRoute>
              <WorkoutDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.profile.url}/:userId/:tabId?`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {renderUserProfile()}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.clients.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Users openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.employees.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Employees openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.challenges.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Challenges openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.challenges.url}/:id`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ChallengeContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.exerciseLibrary.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ExerciseLibrary />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.exercise.url}/:exerciseId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ExerciseView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.timeline.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <Timeline />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.athleteTimeline.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteTimelineSelector />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteBookings.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && (
                  <AthleteBookingSchedule closeSidebar={closeSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.timeline.url}/:timelineId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AdminTimelineView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.modernWorkoutProgramBuilder.url}/:workoutProgramId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <ModernProgramBuilder />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workoutProgramBuilderSuccess.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <WorkoutProgramBuilderSuccessScreen openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.organisation.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdmin && (
                  <OrganisationManagement openSidebar={openSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.organisationIntegrationSetupSuccess.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <OrganisationIntegrationSetupSuccess
                  openSidebar={openSidebar}
                />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workflows.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Workflows />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.workflows.url}/:workflowId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <IndividualWorkflow />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.liveTraining.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <LiveTraining />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.trainingInsights.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <TrainingInsights openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.athleteTrainingInsights.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteTrainingInsights />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.leads.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <LeadsContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.leads.url}/:id`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <IndividualLead />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.billing.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <BillingContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.bookings.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <BookingsContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.userNotes.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <OrganisationUserNotesTable />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.userNotes.url}/:userNoteId/:tabId?`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <UserNoteView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.nutrition.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <NutritionPlans />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.nutrition.url}/:nutritionPlanId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <NutritionPlanView />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteNutrition.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteNutrition />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteNutrition.url}/:nutritionPlanId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteNutritionView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.progress.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteProgress />
              </Layout>
            </ProtectedRoute>
          }
        />
      </RoutesDom>
      <Snackbar
        open={updateSnackbarOpen}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{ width: "100%" }}
        >
          A shiny new update is here! ✨ Get the latest features and
          improvements – update now!
        </Alert>
      </Snackbar>

      {/* Force Update Dialog */}
      <Dialog
        open={forceUpdateDialogOpen}
        disableEscapeKeyDown
        disablePortal
        onClose={(event, reason) => {
          // Prevent closing by backdrop click
          if (reason !== "backdropClick") {
            setForceUpdateDialogOpen(false);
          }
        }}
        aria-labelledby="force-update-dialog-title"
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          "& .MuiPaper-root": {
            borderRadius: 4,
            padding: 0,
            maxWidth: "90%",
            width: "400px",
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            background: `linear-gradient(
              126.67deg,
              #8B44AD 0%,
              #6C5CE7 50.52%,
              #4834D4 100%
            )`,
            color: "#fff",
            padding: "24px",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            Time for a Fresh Update! ✨
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            A new version is ready! Update now to keep enjoying the best
            experience.
          </Typography>
          <Button
            onClick={openAppStore}
            variant="contained"
            fullWidth
            sx={{
              borderRadius: 2,
              textTransform: "none",
              backgroundColor: "#fff",
              color: "#241465",
              padding: "12px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            Update Now
          </Button>
        </Box>
      </Dialog>
    </BrowserRouter>
  );
};
