/* es-lint-disable */

import { FC, useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";
import {
  Typography,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { WrappedData } from "shared/api";
import { Colors } from "shared/themes";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReplayIcon from "@mui/icons-material/Replay";
import { WrappedSlide } from "./WrappedSlide";

const WrappedPresentation: FC<{
  wrappedData: WrappedData;
  onClose: () => void;
}> = ({ wrappedData, onClose }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [hasFinished, setHasFinished] = useState(false);
  const mainAudioRef = useRef<HTMLAudioElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sortedSlides = [...wrappedData.wrappedItems].sort(
    (a, b) => a.order - b.order
  );

  useEffect(() => {
    // Play background music if available
    if (mainAudioRef.current && wrappedData.audioUrl && isPlaying) {
      mainAudioRef.current
        .play()
        .catch((err) => console.error("Error playing background audio:", err));
      mainAudioRef.current.loop = true;
    }

    return () => {
      if (mainAudioRef.current) {
        mainAudioRef.current.pause();
      }
    };
  }, [wrappedData.audioUrl, isPlaying]);

  const goToNextSlide = () => {
    if (currentSlideIndex < sortedSlides.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else {
      setHasFinished(true);
      setIsPlaying(false);
    }
  };

  const goToPrevSlide = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
      setIsPlaying(true);
      setHasFinished(false);
    }
  };

  const restartPresentation = () => {
    setCurrentSlideIndex(0);
    setIsPlaying(true);
    setHasFinished(false);
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: `1px solid ${Colors.gray[300]}`,
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {wrappedData.title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Main content */}
      <Box
        sx={{
          flex: 1,
          position: "relative",
          bgcolor: "#000",
          overflow: "hidden",
        }}
      >
        {wrappedData.audioUrl && (
          <audio
            ref={mainAudioRef}
            src={wrappedData.audioUrl}
            preload="auto"
            controls
          >
            <track
              kind="captions"
              src="path/to/captions.vtt"
              srcLang="en"
              label="English captions"
            />
          </audio>
        )}

        {hasFinished ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 3,
              textAlign: "center",
              color: "white",
              background: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${wrappedData.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              {wrappedData.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {wrappedData.description}
            </Typography>
            <Button
              variant="contained"
              startIcon={<ReplayIcon />}
              onClick={restartPresentation}
              sx={{
                mt: 2,
                bgcolor: Colors.blue[800],
                "&:hover": {
                  bgcolor: Colors.blue[900],
                },
                textTransform: "none",
              }}
            >
              Watch again
            </Button>
          </Box>
        ) : (
          sortedSlides.map((slide, index) => (
            <WrappedSlide
              key={slide.order}
              slide={slide}
              isActive={currentSlideIndex === index && isPlaying}
              onFinished={goToNextSlide}
            />
          ))
        )}
      </Box>

      {/* Controls */}
      {!hasFinished && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            borderTop: `1px solid ${Colors.gray[300]}`,
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={goToPrevSlide}
            disabled={currentSlideIndex === 0}
          >
            {!isMobile && "Previous"}
          </Button>

          <Typography>
            {currentSlideIndex + 1} / {sortedSlides.length}
          </Typography>

          <Button endIcon={<ArrowForwardIcon />} onClick={goToNextSlide}>
            {!isMobile && "Skip"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export { WrappedPresentation };
