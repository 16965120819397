import React from "react";
import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ReadinessDetailPageProps {
  open: boolean;
  onClose: () => void;
}

export const ReadinessDetailPage: React.FC<ReadinessDetailPageProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            What is Readiness?
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: "#f8f9ff",
            border: "1px solid rgba(108, 92, 231, 0.1)",
            boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              color: "#6C5CE7",
              mb: 2,
            }}
          >
            What is the Readiness Score?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.95rem",
              color: "#444",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            The Readiness Score gives you an idea of how well your body and mind
            have recovered and how ready you might be for the day ahead. It
            considers various factors related to physical strain and recovery.
            By taking into account your usual daily levels of strain, the score
            adapts to your unique lifestyle and habits. This personalised
            approach provides an indication of your readiness, helping you stay
            in tune with your body and make more balanced choices throughout the
            day.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              color: "#6C5CE7",
              mb: 2,
            }}
          >
            How do I interpret my Readiness Score?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.95rem",
              color: "#444",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            The Readiness Score ranges from 0 to 100, categorised into four
            levels to help you gauge your preparedness:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.95rem",
                  color: "#444",
                  lineHeight: 1.6,
                  mb: 2,
                }}
              >
                <strong>81-100: High</strong> - You are generally well-recovered
                and could be ready for higher-intensity physical or mental
                activities. This range suggests a good balance between recovery
                and recent strain.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.95rem",
                  color: "#444",
                  lineHeight: 1.6,
                  mb: 2,
                }}
              >
                <strong>61-80: Medium</strong> - You appear to be moderately
                recovered, with some signs of fatigue or strain. Engaging in
                moderate to high-intensity activities might be fine, but
                listening to your body throughout the day is key.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.95rem",
                  color: "#444",
                  lineHeight: 1.6,
                  mb: 2,
                }}
              >
                <strong>41-60: Low</strong> - This range indicates that recovery
                might be somewhat limited, and there could be signs of
                accumulated fatigue. It may be beneficial to prioritise lighter
                activities and recovery.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.95rem",
                  color: "#444",
                  lineHeight: 1.6,
                  mb: 2,
                }}
              >
                <strong>0-40: Minimal</strong> - A lower score could suggest
                that recovery is not at its peak and that your body may need
                more rest. Focusing on gentle activities and recuperation could
                be more suitable.
              </Typography>
            </li>
          </ul>
          <Link
            href="https://learn.sahha.ai/a-28-readiness-score-explained"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#6C5CE7",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "0.85rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Learn more about readiness scores by clicking here. Powered by
            Sahha.ai
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
