import { FC, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  TimelineItem,
  useGetTimelineItemsQuery,
  useGetTimelinesQuery,
} from "shared/api";
import { Skeleton } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TimelineViewItemCard } from "../TimelineViewItemCard";
import { AdminTimelineViewInput } from "./AdminTimelineViewInput";

interface UserProfileTimelineProps {
  userId: number;
  isAdmin?: boolean;
}

const UserProfileTimeline: FC<UserProfileTimelineProps> = ({
  userId,
  isAdmin = false,
}) => {
  const { t } = useTranslation();
  const timelineItemsRef = useRef<HTMLDivElement>(null);
  const [replyTo, setReplyTo] = useState<TimelineItem>();
  const [isReply, setIsReply] = useState(false);

  // Fetch the timeline data based on userId
  const { data: timelineDataItems, isLoading } = useGetTimelinesQuery(
    {
      userId,
    },
    { skip: isAdmin }
  );

  const timelineData = timelineDataItems?.[0];
  const timelineId = timelineData?.id;

  const { data: timelineItemsData, isLoading: isLoadingItems } =
    useGetTimelineItemsQuery(
      {
        timelineId: timelineId ?? 0,
        orderByDescending: true,
        count: 10,
        page: 0,
      },
      { skip: !timelineId }
    );

  const timelineItems = timelineItemsData || [];

  // Set up virtualizer
  const rowVirtualizer = useVirtualizer({
    count: timelineItems.length,
    getScrollElement: () => timelineItemsRef.current,
    estimateSize: () => 150,
    overscan: 5,
  });

  const handleAddItem = () => {
    if (timelineItemsRef.current) {
      timelineItemsRef.current.scrollTo({ top: 0 });
    }
    // Reset the reply state
    setReplyTo(undefined);
    setIsReply(false);
  };

  const handleReplyComment = (timelineItem: TimelineItem) => {
    if (timelineItem?.id === replyTo?.id) {
      setReplyTo(undefined);
      setIsReply(false);
      return;
    }
    setReplyTo(timelineItem);
    setIsReply(true);
  };

  if (isAdmin) {
    return null;
  }

  if (isLoading || isLoadingItems || !timelineId)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  const virtualItems = rowVirtualizer.getVirtualItems();

  const timelineUserIdRequiredForWorkoutPreview =
    timelineData?.timelineType === "Private" && timelineData?.userIds.length
      ? timelineData.userIds[0]
      : 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "95vh",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {/* Existing Timeline Input Component */}
      <AdminTimelineViewInput
        timelineId={timelineId}
        timelineTitle={timelineData?.name}
        onAddItem={handleAddItem}
        replyTo={replyTo}
        isReply={isReply}
      />

      {/* Timeline Items Container */}
      <Box
        ref={timelineItemsRef}
        sx={{
          flex: 1,
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
            }}
          >
            {virtualItems.map((virtualItem) => {
              const { index } = virtualItem;
              const item = timelineItems[index];

              return (
                <Box
                  key={index} // Use unique id
                  ref={rowVirtualizer.measureElement}
                  className={index % 2 ? "ListItemOdd" : "ListItemEven"}
                  sx={{
                    pt: 2,
                  }}
                >
                  <TimelineViewItemCard
                    timelineItem={item}
                    onReply={handleReplyComment}
                    timelineUserId={timelineUserIdRequiredForWorkoutPreview}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { UserProfileTimeline };
