import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Icon,
  Modal,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import {
  CreateBillingProductRequest,
  BillingFrequency,
  BillingProductType,
  useCreateBillingProductMutation,
  CreateBillingProductPriceRequest,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";

interface AddNewMembershipModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
}

const AddNewMembershipModal: FC<AddNewMembershipModalProps> = ({
  isOpenModal,
  handleCloseModal,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [createMembership, { isLoading }] = useCreateBillingProductMutation();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState<number | null>(null);
  const [frequency, setFrequency] = useState(BillingFrequency.Weekly);
  const [ddrPageUrl, setDdrPageUrl] = useState("");
  const [priceDescription, setPriceDescription] = useState("");
  const [pricesToAdd, setPricesToAdd] = useState<
    CreateBillingProductPriceRequest[]
  >([]);

  const addPrice = () => {
    if (price !== null && price > 0) {
      setPricesToAdd((prev) => [
        ...prev,
        {
          price,
          billingFrequency: frequency,
          ddrPageUrl,
          description: priceDescription,
        },
      ]);
      setPrice(null);
      setDdrPageUrl("");
      setPriceDescription("");
    } else {
      setErrorMessage("Price must be greater than zero");
    }
  };

  const removePrice = (index: number) => {
    setPricesToAdd(pricesToAdd.filter((_, i) => i !== index));
  };

  const onSubmit = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      if (!title) {
        setErrorMessage("Title is required");
        return;
      }

      if (pricesToAdd.length === 0) {
        setErrorMessage("At least one price is required");
        return;
      }

      const membershipToSave = {
        name: title,
        description,
        billingProductType: BillingProductType.Membership,
        billingProductPrices: pricesToAdd,
      } as CreateBillingProductRequest;

      await createMembership(membershipToSave).unwrap();

      setTitle("");
      setDescription("");
      setPricesToAdd([]);

      setSuccessMessage(
        "Membership has been created successfully. You can now assign it to clients."
      );
    } catch {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          ...sharedStyles.containers.modal,
          alignItems: { xs: "flex-end", md: "center" },
        }}
      >
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            width: { xs: "100%", md: "50%" },
            height: { xs: "90vh", md: "auto" },
            maxHeight: { xs: "90vh", md: "80vh" },
            borderRadius: { xs: "16px 16px 0 0", md: "8px" },
            m: { xs: 0, md: 3 },
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Fixed header area */}
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" sx={{ color: Colors.blue[1300] }}>
                Add new membership
              </Typography>
              <Icon onClick={handleCloseModal} sx={{ cursor: "pointer" }}>
                close_icon
              </Icon>
            </Box>
            <Typography sx={{ color: Colors.blue[1300], mb: 2 }}>
              Create a new billing membership that you can assign to clients
            </Typography>
          </Box>

          {/* Scrollable content area */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              minHeight: 0,
            }}
          >
            <TextField
              fullWidth
              label="Name"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "18px",
                color: Colors.gray[900],
              }}
            >
              Pricing
            </Typography>
            <Select
              fullWidth
              sx={{ mt: 2 }}
              value={frequency}
              onChange={(e) => setFrequency(e.target.value as BillingFrequency)}
            >
              <MenuItem value={BillingFrequency.Weekly}>Weekly</MenuItem>
              <MenuItem value={BillingFrequency.Fortnightly}>
                Fortnightly
              </MenuItem>
              <MenuItem value={BillingFrequency.Monthly}>Monthly</MenuItem>
              <MenuItem value={BillingFrequency.Quarterly}>Quarterly</MenuItem>
              <MenuItem value={BillingFrequency.Annually}>Yearly</MenuItem>
            </Select>
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              type="number"
              label="Price"
              value={price || ""}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Price Description"
              value={priceDescription}
              onChange={(e) => setPriceDescription(e.target.value)}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Direct Debit Request page URL"
              value={ddrPageUrl}
              onChange={(e) => setDdrPageUrl(e.target.value)}
            />
            <Button
              sx={{ mt: 2, textTransform: "none" }}
              variant="outlined"
              onClick={addPrice}
            >
              Add Price
            </Button>
            <Divider sx={{ mt: 2, mb: 2 }} />
            {pricesToAdd.length > 0 && (
              <Box>
                {pricesToAdd.map((p, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography>
                      {p.billingFrequency}: ${p.price} - {p.description}
                    </Typography>
                    <Button
                      variant="text"
                      color="error"
                      sx={{ textTransform: "none" }}
                      onClick={() => removePrice(index)}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
            <Divider sx={{ mt: 2, mb: 2 }} />
          </Box>

          {/* Fixed footer area */}
          <Box sx={{ mt: 2, pt: 2, borderTop: "1px solid #eee" }}>
            <LoadingButton
              fullWidth
              sx={{ textTransform: "none" }}
              loading={isLoading}
              variant="contained"
              onClick={onSubmit}
            >
              Create membership
            </LoadingButton>
            {errorMessage && (
              <Alert sx={{ mt: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert sx={{ mt: 2 }} severity="success">
                {successMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { AddNewMembershipModal };
