/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Icon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";
import SensorsIcon from "@mui/icons-material/Sensors";
import { getGraphicSvg } from "shared/themes";
import { Sahha } from "sahha-capacitor";
import SahhaService from "SahhaService";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import {
  useGetHealthScoresForUserQuery,
  useGetProfileTokenForUserQuery,
  useActivateProfileTokenMutation,
  healthScoresApi,
} from "shared/api";
import { userSelectors } from "shared/stores/user";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface HealthPermissionsDialogProps {
  open: boolean;
  onClose: () => void;
  isMobileApp: boolean;
}

export const HealthPermissionsDialog: React.FC<
  HealthPermissionsDialogProps
> = ({ open, onClose, isMobileApp }) => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(userSelectors.all);

  // State
  const [isApiKeyAuthorised, setIsApiKeyAuthorised] = useState(false);
  const [isDeviceAuthorised, setIsDeviceAuthorised] = useState(false);
  const [sensorStatus, setSensorStatus] = useState<string>("pending");
  const [isActivatingApiKey, setIsActivatingApiKey] = useState(false);
  const [isAuthorisingDevice, setIsAuthorisingDevice] = useState(false);
  const [isEnablingSensors, setIsEnablingSensors] = useState(false);

  const { data: activityHealthData } = useGetHealthScoresForUserQuery(
    { type: "activity", userId: user?.id! },
    { skip: !user?.id || !isMobileApp }
  );
  const { data: readinessHealthData } = useGetHealthScoresForUserQuery(
    { type: "readiness", userId: user?.id! },
    { skip: !user?.id || !isMobileApp }
  );
  const { data: sleepHealthData } = useGetHealthScoresForUserQuery(
    { type: "sleep", userId: user?.id! },
    { skip: !user?.id || !isMobileApp }
  );
  const { data: profileData } = useGetProfileTokenForUserQuery(
    {},
    { skip: !user?.id || !isMobileApp }
  );

  // Mutations
  const [activateProfileToken] = useActivateProfileTokenMutation();

  // Effects
  useEffect(() => {
    const checkStatuses = async () => {
      // Check API key authorization
      setIsApiKeyAuthorised(
        !!activityHealthData?.isAuthorised &&
          !!readinessHealthData?.isAuthorised &&
          !!sleepHealthData?.isAuthorised
      );

      // Check device authorization
      const authStatus = await Sahha.isAuthenticated();
      setIsDeviceAuthorised(authStatus.success);

      // Check sensor status
      const sahhaService = SahhaService.getInstance();
      const status = await sahhaService.getSahhaSensorStatus();
      setSensorStatus(status);
    };

    if (open) {
      checkStatuses();
    }
  }, [open, activityHealthData, readinessHealthData, sleepHealthData]);

  // Handlers
  const handleActivateApiKey = async () => {
    try {
      setIsActivatingApiKey(true);
      console.log("Starting API key activation...");
      console.log("Current profile data:", profileData);
      const result = await activateProfileToken().unwrap();
      console.log("API key activation successful:", result);
    } catch (error) {
      console.error("Failed to activate API key:", error);
      if (error instanceof Error) {
        console.error("Error details:", {
          name: error.name,
          message: error.message,
          stack: error.stack,
        });
      }
    } finally {
      setIsActivatingApiKey(false);
      console.log("API key activation process completed");
    }
  };

  const handleAuthoriseDevice = async () => {
    try {
      setIsAuthorisingDevice(true);
      console.log("Starting device authorization...");

      const sahhaService = SahhaService.getInstance();
      if (profileData?.profileToken && profileData?.refreshToken) {
        console.log("Attempting login with tokens...");
        await sahhaService.login(
          profileData.profileToken,
          profileData.refreshToken
        );
        const authStatus = await Sahha.isAuthenticated();
        console.log("Auth status after login:", authStatus);
        setIsDeviceAuthorised(authStatus.success);
      } else {
        console.warn("Missing required tokens:");
      }
    } catch (error) {
      console.error("Failed to authorise device:", error);
      // Add more detailed error logging
      if (error instanceof Error) {
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
        });
      }
    } finally {
      setIsAuthorisingDevice(false);
    }
  };

  const handleEnableSensors = async () => {
    try {
      setIsEnablingSensors(true);
      console.log("Starting sensor enablement process...");
      const sahhaService = SahhaService.getInstance();

      // Check if authenticated first
      const authStatus = await Sahha.isAuthenticated();
      console.log("Initial authentication status:", authStatus);

      if (
        !authStatus.success &&
        profileData?.profileToken &&
        profileData?.refreshToken
      ) {
        console.log("Not authenticated, attempting login with tokens...");
        await sahhaService.login(
          profileData.profileToken,
          profileData.refreshToken
        );
        const newAuthStatus = await Sahha.isAuthenticated();
        console.log("Authentication status after login:", newAuthStatus);
      }

      // Now try to enable sensors
      console.log("Attempting to enable Sahha sensors...");
      const enabled = await sahhaService.enableSahhaSensors();
      console.log("Sensor enablement result:", enabled);

      if (enabled) {
        const newStatus = await sahhaService.getSahhaSensorStatus();
        console.log("New sensor status after enablement:", newStatus);
        setSensorStatus(newStatus);
        dispatch(healthScoresApi.util.invalidateTags(["HealthScores"]));
      } else {
        console.log("Sensor enablement failed, opening app settings...");
        await SahhaService.openAppSettings();
      }
    } catch (error) {
      console.error("Failed to enable sensors:", error);
      if (error instanceof Error) {
        console.error("Error details:", {
          name: error.name,
          message: error.message,
          stack: error.stack,
        });
      }
    } finally {
      setIsEnablingSensors(false);
      console.log("Sensor enablement process completed");
    }
  };

  const completed =
    isApiKeyAuthorised && isDeviceAuthorised && sensorStatus === "enabled";

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            Health Permissions
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
            To access health insights, we need your permission to collect and
            analyse your health data.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            {/* API Key Authorization */}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleActivateApiKey}
              startIcon={<Icon>key</Icon>}
              endIcon={isApiKeyAuthorised ? <Icon>done</Icon> : null}
              loading={isActivatingApiKey}
              disabled={isApiKeyAuthorised}
              color={isApiKeyAuthorised ? "success" : "primary"}
              sx={{ textTransform: "none" }}
            >
              {isApiKeyAuthorised ? "Profile Created" : "Create Profile"}
            </LoadingButton>

            {/* Device Authorization */}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleAuthoriseDevice}
              startIcon={<Icon>phone_iphone</Icon>}
              endIcon={isDeviceAuthorised ? <Icon>done</Icon> : null}
              loading={isAuthorisingDevice}
              disabled={isDeviceAuthorised || !isApiKeyAuthorised}
              color={isDeviceAuthorised ? "success" : "primary"}
              sx={{ textTransform: "none" }}
            >
              {isDeviceAuthorised ? "Device Authorised" : "Authorise Device"}
            </LoadingButton>

            {/* Sensor Status */}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleEnableSensors}
              startIcon={<SensorsIcon />}
              loading={isEnablingSensors}
              endIcon={sensorStatus === "enabled" ? <Icon>done</Icon> : null}
              disabled={
                sensorStatus === "enabled" ||
                !isDeviceAuthorised ||
                !isApiKeyAuthorised
              }
              color={sensorStatus === "enabled" ? "success" : "primary"}
              sx={{ textTransform: "none" }}
            >
              {sensorStatus === "enabled"
                ? "Sensors Enabled"
                : sensorStatus === "pending"
                ? "Sensors Pending"
                : sensorStatus === "unavailable"
                ? "Sensors Unavailable"
                : "Enable Sensors"}
            </LoadingButton>
          </Box>

          {!completed && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Complete all steps above to enable health tracking
            </Typography>
          )}

          {completed && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Congratulations! You&apos;re all set to start tracking your health
              data.
              <OutlinedIcon iconName="done" />
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
