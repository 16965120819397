import { ClientResponse } from "../user/models";

type TimelineAthleteMetadata = Pick<
  ClientResponse,
  "id" | "firstName" | "lastName" | "profileImageUrl"
>;

export interface WrappedItemResponse {
  imageUrl: string;
  audioUrl: string;
  order: number;
}

export interface WrappedContainerResponse {
  title: string;
  description: string;
  imageUrl: string;
  audioUrl: string;
  wrappedItems: WrappedItemResponse[];
}

export interface WrappedData {
  title: string;
  description: string;
  imageUrl: string;
  audioUrl: string;
  wrappedItems: Array<{
    imageUrl: string;
    audioUrl: string;
    order: number;
  }>;
}

export interface Timeline {
  id: number;
  dateTimeCreated: string;
  dateTimeUpdated: string;
  name: string;
  profileImageUrl: string;
  timelineStatus: string;
  timelineType: "Private" | "Group";
  unreadCount: number;
  userIds: number[];
  athleteMetadata: TimelineAthleteMetadata[];
  // This appears when fetching all timelines
  mostRecentTimelineItemDate?: string;
}

export interface TimelineItem {
  id: number;
  dateTime: string;
  timelineItemType: string;
  title: string;
  name: string;
  profileImageUrl: string;
  mediaUrl: string;
  content: string;
  userId?: number;
  entityId?: number;
  entityType: string;
  timelineItemParentId?: number;
  timelineItemChildren?: TimelineItem[];
  timelineItemLikes?: TimelineItemLike[];
}

export type TimelineItemLike = {
  id: number;
  userId: number;
  timelineItemId: number;
  dateTime: string;
  firstName: string;
  lastName: string;
};

export enum TimelineItemType {
  Undefined = "Undefined",
  Message = "Message",
  WorkoutProgramClientFeedback = "WorkoutProgramClientFeedback",
  Media = "Media",
}

export enum TimelineItemEntityType {
  Workout = "Workout",
  WorkoutProgramNote = "WorkoutProgramNote",
}

export interface TimelineItemsListParams {
  count?: number;
  page?: number;
  orderByDescending?: boolean;
  userId?: number;
  timelineId?: number;
}

export interface TimelineParams {
  page?: number;
  count?: number;
  countOnly?: boolean;
  orderByDescending?: boolean;
  orderByUnreadItems?: boolean;
  userId?: number;
  queryString?: string;
  timelineType?: string;
}

export interface CreateTimeLineItemParams {
  timelineItemType: string;
  title: string;
  content: string;
  userId?: number;
  entityId?: number;
  timelineItemParentId?: number;
  timelineId?: number;
  file?: File;
}

export interface UploadMediaParams {
  timelineId?: number;
  uploadFile: File;
}

export interface CreateTimelineParams {
  userIds: number[];
  name: string;
}

export interface UserOnboardingCompletedRequest {
  isOnboardingCompleted: boolean;
  onboardingUrl: string;
  userId: number;
}
