/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Container, Typography } from "@mui/material";
import { FormContainer } from "components/Form/FormContainer";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { LogoLink } from "components/Form/LogoLink";
import { Routes } from "shared/routers/Routes";

export const FitnessProfessionalSignupSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        <LogoLink />
        <Typography sx={sharedStyles.headings.signIn}>
          {t("signup.fitness-professionals.successTitle")}
        </Typography>
        <Typography sx={sharedStyles.body.signIn}>
          {t("signup.fitness-professionals.successDescription")}
        </Typography>
        <Link to={Routes.signIn.url}>
          <PrimaryButton
            value={t("signup.fitness-professionals.buttons.login")}
            size="large"
            variant="contained"
            color="primary"
            fullWidth
          />
        </Link>
      </Container>
    </FormContainer>
  );
};
