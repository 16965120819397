/* eslint-disable */

import dayjs from "dayjs";
import {
  AppBar,
  Avatar,
  Box,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  Card,
  CardContent,
  CardMedia,
  Tabs,
  Tab,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, {
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  ChallengeDashboardResponse,
  ChallengeLeaderboardItem,
  useGetChallengeLeaderboardQuery,
  WrappedData,
} from "shared/api";
import { WhatIsTheChallengeCard } from "./WhatIsTheChallengeCard";
import { ChallengeHelpDetailPage } from "./ChallengeHelpDetailPage";
import { WrappedChallengeCard } from "../../Wrapped/WrappedChallengeCard";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<unknown> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface EmployeeChallengeContainerProps {
  open: boolean;
  onClose: () => void;
  activeChallenge: ChallengeDashboardResponse;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`leaderboard-tabpanel-${index}`}
      aria-labelledby={`leaderboard-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

export const EmployeeChallengeContainer: FC<
  EmployeeChallengeContainerProps
> = ({ open, onClose, activeChallenge }) => {
  const [whatIsTheChallengeDialogOpen, setWhatIsTheChallengeDialogOpen] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const { activeChallenge: challenge } = activeChallenge || {};

  const { data: leaderboard, isLoading: isLeaderboardLoading } =
    useGetChallengeLeaderboardQuery(challenge?.id);

  const isTeamChallenge = challenge?.challengeCategory?.toString() === "Team";
  const [expandedTeams, setExpandedTeams] = useState<Record<string, boolean>>(
    {}
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setPage(0); // Reset pagination when changing tabs
  };

  const toggleTeamExpansion = (teamId: string) => {
    setExpandedTeams((prev) => ({ ...prev, [teamId]: !prev[teamId] }));
  };

  // Determine which challenge types are available
  const hasSecondaryChallenge =
    challenge?.challengeTargetTypeSecondary !== undefined &&
    challenge?.challengeTargetTypeSecondary.toString() !== "Undefined" &&
    challenge?.challengeTargetTypeSecondary.toString() !== "0";

  const hasTertiaryChallenge =
    challenge?.challengeTargetTypeTertiary !== undefined &&
    challenge?.challengeTargetTypeTertiary.toString() !== "Undefined" &&
    challenge?.challengeTargetTypeTertiary.toString() !== "0";

  // Helper function to get the formatted display name for the challenge type
  const getChallengeTypeDisplay = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return (
          activeChallenge.activeChallenge.challengeTargetTypeFormatted.toString() ||
          "Points"
        );
      case 1:
        return (
          activeChallenge.activeChallenge.challengeTargetTypeSecondaryFormatted.toString() ||
          "Secondary"
        );
      case 2:
        return (
          activeChallenge.activeChallenge.challengeTargetTypeTertiaryFormatted.toString() ||
          "Tertiary"
        );
      default:
        return "Points";
    }
  };

  if (!challenge) {
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            pt: 6,
            background: `linear-gradient(126.67deg, #8B44AD 0%, #6C5CE7 50.52%, #4834D4 100%)`,
          }}
        >
          <Toolbar sx={{ px: 3 }}>
            <Typography sx={{ flex: 1 }} variant="h6">
              Challenge
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );
  }

  const renderLeaderboard = (leaderboardType: number) => {
    if (isLeaderboardLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} align="center">
              <Typography variant="body2">Loading leaderboard...</Typography>
              <CircularProgress />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (!leaderboard || leaderboard.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} align="center">
              No leaderboard data available.
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    // Sort the leaderboard based on the current tab/leaderboard type
    const sortedLeaderboard = [...leaderboard].sort((a, b) => {
      if (leaderboardType === 0) {
        return (a.rank ?? Number.MAX_VALUE) - (b.rank ?? Number.MAX_VALUE);
      } else if (leaderboardType === 1) {
        return (
          (a.secondaryRank ?? Number.MAX_VALUE) -
          (b.secondaryRank ?? Number.MAX_VALUE)
        );
      } else {
        return (
          (a.tertiaryRank ?? Number.MAX_VALUE) -
          (b.tertiaryRank ?? Number.MAX_VALUE)
        );
      }
    });

    const paginatedLeaderboard = sortedLeaderboard.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    if (!isTeamChallenge) {
      // **Individual Leaderboard**
      return (
        <TableBody>
          {paginatedLeaderboard.map(
            (participant: ChallengeLeaderboardItem, index) => (
              <TableRow key={participant.userId}>
                <TableCell>
                  {leaderboardType === 0
                    ? participant.rank ?? index + 1
                    : leaderboardType === 1
                    ? participant.secondaryRank ?? index + 1
                    : participant.tertiaryRank ?? index + 1}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={participant.profileImageUrl} sx={{ mr: 2 }} />
                    {participant.firstName} {participant.lastName}
                  </Box>
                </TableCell>
                <TableCell>
                  {leaderboardType === 0
                    ? participant.value?.toLocaleString() ?? 0
                    : leaderboardType === 1
                    ? participant.secondaryValue?.toLocaleString() ?? 0
                    : participant.tertiaryValue?.toLocaleString() ?? 0}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      );
    }

    // **Team Leaderboard with Expandable Rows**
    const teamScores: Record<
      number,
      { name: string; totalScore: number; members: ChallengeLeaderboardItem[] }
    > = {};

    challenge.challengeTeams.forEach((team) => {
      teamScores[team.id] = { name: team.name, totalScore: 0, members: [] };
    });

    sortedLeaderboard.forEach((entry) => {
      const userTeam = challenge.challengeUsers.find(
        (user) => user.userId === entry.userId
      )?.teamId;
      if (userTeam && teamScores[userTeam]) {
        // Add the appropriate score to the team total based on leaderboard type
        if (leaderboardType === 0) {
          teamScores[userTeam].totalScore += entry.value ?? 0;
        } else if (leaderboardType === 1) {
          teamScores[userTeam].totalScore += entry.secondaryValue ?? 0;
        } else {
          teamScores[userTeam].totalScore += entry.tertiaryValue ?? 0;
        }
        teamScores[userTeam].members.push(entry);
      }
    });

    // Sort each team's members by the appropriate rank
    Object.values(teamScores).forEach((team) => {
      team.members.sort((a, b) => {
        if (leaderboardType === 0) {
          return (a.rank ?? Number.MAX_VALUE) - (b.rank ?? Number.MAX_VALUE);
        } else if (leaderboardType === 1) {
          return (
            (a.secondaryRank ?? Number.MAX_VALUE) -
            (b.secondaryRank ?? Number.MAX_VALUE)
          );
        } else {
          return (
            (a.tertiaryRank ?? Number.MAX_VALUE) -
            (b.tertiaryRank ?? Number.MAX_VALUE)
          );
        }
      });
    });

    // Sort teams based on total score
    const sortedTeams = Object.values(teamScores).sort(
      (a, b) => b.totalScore - a.totalScore
    );

    const paginatedTeams = sortedTeams.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <TableBody>
        {paginatedTeams.map((team, index) => (
          <React.Fragment key={team.name}>
            <TableRow>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{team.name}</TableCell>
              <TableCell>{team.totalScore.toLocaleString()}</TableCell>
              <TableCell>
                <IconButton onClick={() => toggleTeamExpansion(team.name)}>
                  {expandedTeams[team.name] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} sx={{ padding: 0 }}>
                <Collapse
                  in={expandedTeams[team.name]}
                  timeout="auto"
                  unmountOnExit
                >
                  <TableContainer>
                    <Table
                      size="small"
                      sx={{ margin: "8px 16px", width: "95%" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                            Rank
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold", width: "60%" }}>
                            Name
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                            {getChallengeTypeDisplay(leaderboardType)}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {team.members.map((member, memberIndex) => (
                          <TableRow key={member.userId}>
                            <TableCell>
                              {leaderboardType === 0
                                ? member.rank ?? memberIndex + 1
                                : leaderboardType === 1
                                ? member.secondaryRank ?? memberIndex + 1
                                : member.tertiaryRank ?? memberIndex + 1}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Avatar
                                  src={member.profileImageUrl}
                                  sx={{ mr: 2 }}
                                />
                                {member.firstName} {member.lastName}
                              </Box>
                            </TableCell>
                            <TableCell>
                              {leaderboardType === 0
                                ? member.value?.toLocaleString()
                                : leaderboardType === 1
                                ? member.secondaryValue?.toLocaleString()
                                : member.tertiaryValue?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    );
  };

  // Helper function to get user's rank and score for the current tab
  const getUserRankAndScore = () => {
    switch (currentTab) {
      case 0:
        return {
          rank: activeChallenge.userRank,
          score: activeChallenge.userScore,
        };
      case 1:
        return {
          rank: activeChallenge.userSecondaryRank,
          score: activeChallenge.userSecondaryScore,
        };
      case 2:
        return {
          rank: activeChallenge.userTertiaryRank,
          score: activeChallenge.userTertiaryScore,
        };
      default:
        return { rank: undefined, score: undefined };
    }
  };

  const [wrappedData, setWrappedData] = useState<WrappedData | null>(null);

  useEffect(() => {
    try {
      // Clean up the JSON string
      let jsonString = activeChallenge.activeChallenge.wrappedContent;

      // 1. Remove surrounding quotes if present
      if (jsonString.startsWith('"') && jsonString.endsWith('"')) {
        jsonString = jsonString.slice(1, -1);
      }

      // 2. Remove any query string parameters (everything after '?')
      const questionMarkIndex = jsonString.indexOf("?");
      if (questionMarkIndex !== -1) {
        jsonString = jsonString.substring(0, questionMarkIndex);
      }

      // 3. Replace escaped quotes with regular quotes
      jsonString = jsonString.replace(/\\"/g, '"');

      // 4. Parse the cleaned JSON
      const parsedData = JSON.parse(jsonString) as WrappedData;

      // 5. Map lowercase properties to the expected case if needed
      const normalizedData: WrappedData = {
        title: parsedData.title,
        description: parsedData.description,
        imageUrl: parsedData.imageUrl,
        audioUrl: parsedData.audioUrl,
        wrappedItems: parsedData.wrappedItems.map((item) => ({
          imageUrl: item.imageUrl,
          audioUrl: item.audioUrl,
          order: item.order,
        })),
      };

      setWrappedData(normalizedData);
    } catch (error) {
      console.error("Error parsing wrapped data: ", error);
    }
  }, [activeChallenge.activeChallenge.wrappedContent]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(126.67deg, #8B44AD 0%, #6C5CE7 50.52%, #4834D4 100%)`,
        }}
      >
        <Toolbar sx={{ px: 3 }}>
          <Typography sx={{ flex: 1 }} variant="h6">
            {activeChallenge
              ? activeChallenge.activeChallenge.name
              : "Challenge"}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {`${dayjs(activeChallenge.activeChallenge.startDate).format(
                "MMM D, YYYY"
              )} - ${dayjs(activeChallenge.activeChallenge.endDate).format(
                "MMM D, YYYY"
              )}`}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {activeChallenge.activeChallenge.description}
            </Typography>
          </Grid>
          {activeChallenge &&
            activeChallenge.activeChallenge &&
            activeChallenge.activeChallenge.helpText && (
              <Grid item xs={12}>
                <WhatIsTheChallengeCard
                  setDialogOpen={setWhatIsTheChallengeDialogOpen}
                  title={activeChallenge.activeChallenge.name}
                />
              </Grid>
            )}
          {activeChallenge &&
            activeChallenge.activeChallenge &&
            activeChallenge.activeChallenge.wrappedContent &&
            wrappedData && (
              <Grid item xs={12}>
                <WrappedChallengeCard
                  imageUrl="https://fitfocusmedia.blob.core.windows.net/wrapped/WrappedBackground.png"
                  wrappedData={wrappedData}
                />
              </Grid>
            )}
          {activeChallenge && activeChallenge.activeChallenge && (
            <Grid item xs={12}>
              <Box sx={{ mt: 3 }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="challenge leaderboard tabs"
                  >
                    <Tab
                      label={getChallengeTypeDisplay(0)}
                      id="leaderboard-tab-0"
                      aria-controls="leaderboard-tabpanel-0"
                      sx={{ textTransform: "none" }}
                    />
                    {hasSecondaryChallenge && (
                      <Tab
                        label={getChallengeTypeDisplay(1)}
                        id="leaderboard-tab-1"
                        aria-controls="leaderboard-tabpanel-1"
                        sx={{ textTransform: "none" }}
                      />
                    )}
                    {hasTertiaryChallenge && (
                      <Tab
                        label={getChallengeTypeDisplay(2)}
                        id="leaderboard-tab-2"
                        aria-controls="leaderboard-tabpanel-2"
                        sx={{ textTransform: "none" }}
                      />
                    )}
                  </Tabs>
                </Box>

                <TabPanel value={currentTab} index={0}>
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Rank</TableCell>
                          <TableCell>
                            {isTeamChallenge ? "Team" : "Name"}
                          </TableCell>
                          <TableCell>{getChallengeTypeDisplay(0)}</TableCell>
                          {isTeamChallenge && <TableCell>Expand</TableCell>}
                        </TableRow>
                      </TableHead>
                      {renderLeaderboard(0)}
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={leaderboard?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </TabPanel>

                {hasSecondaryChallenge && (
                  <TabPanel value={currentTab} index={1}>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>
                              {isTeamChallenge ? "Team" : "Name"}
                            </TableCell>
                            <TableCell>{getChallengeTypeDisplay(1)}</TableCell>
                            {isTeamChallenge && <TableCell>Expand</TableCell>}
                          </TableRow>
                        </TableHead>
                        {renderLeaderboard(1)}
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={leaderboard?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </TabPanel>
                )}

                {hasTertiaryChallenge && (
                  <TabPanel value={currentTab} index={2}>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>
                              {isTeamChallenge ? "Team" : "Name"}
                            </TableCell>
                            <TableCell>{getChallengeTypeDisplay(2)}</TableCell>
                            {isTeamChallenge && <TableCell>Expand</TableCell>}
                          </TableRow>
                        </TableHead>
                        {renderLeaderboard(2)}
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={leaderboard?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </TabPanel>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {activeChallenge && (
        <ChallengeHelpDetailPage
          open={whatIsTheChallengeDialogOpen}
          onClose={() => setWhatIsTheChallengeDialogOpen(false)}
          challenge={activeChallenge.activeChallenge}
        />
      )}
    </Dialog>
  );
};
