import React from "react";
import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ReactPlayer from "react-player/lazy";
import { Challenge } from "shared/api";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ChallengeHelpDetailPageProps {
  open: boolean;
  onClose: () => void;
  challenge: Challenge;
}

export const ChallengeHelpDetailPage: React.FC<
  ChallengeHelpDetailPageProps
> = ({ open, onClose, challenge }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            {challenge.name}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: "#f8f9ff",
            border: "1px solid rgba(108, 92, 231, 0.1)",
            boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          }}
        >
          {challenge.helpVideoUrl && (
            <Box>
              <ReactPlayer
                url={challenge.helpVideoUrl}
                // eslint-disable-next-line
                controls={true}
                // eslint-disable-next-line
                pip={true}
                width="100%"
                height="auto"
                light
                style={{ aspectRatio: "16/9" }}
              />
            </Box>
          )}
          <Box dangerouslySetInnerHTML={{ __html: challenge.helpText }} />
          <Link
            href="https://learn.sahha.ai/c-5-science"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#6C5CE7",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "0.85rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Learn more about the science behind the {challenge.name} challenge
            by clicking here. Powered by Sahha.ai
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
