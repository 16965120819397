import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  Typography,
  Collapse,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "shared/themes";
import { useTypedSelector } from "shared/stores";
import {
  WorkoutProgramStatus,
  useChangeWorkoutStatusMutation,
  useGetWorkoutQuery,
  useSkipWorkoutMutation,
} from "shared/api";
import DOMPurify from "dompurify";
import { WorkoutList } from "./ui";
import { FinishWorkoutModal } from "./ui/FinishWorkoutModal";
import { Feedback } from "./ui/Feedback";
import { SkipWorkoutModal } from "./ui/SkipWorkoutModal";
import { WorkoutDetailsSessionNotes } from "./ui/WorkoutDetailsSessionNotes";

const { IN_PROGRESS, COMPLETED, SKIPPED } = WorkoutProgramStatus;
const EXERCISE_FEEDBACK_SUFFIX = "<br><b>Exercise Feedback:</b><br>";

const WorkoutDetails: FC = () => {
  const currentWorkout = useTypedSelector((state) => state.workout);

  const [showFeedback, setShowFeedback] = useState(false);
  const [isShowFinishWorkoutModal, setIsShowFinishWorkoutModal] =
    useState(false);
  const [isShowSkipWorkoutModal, setIsShowSkipWorkoutModal] = useState(false);

  const params = useParams();

  const workoutId = (currentWorkout.id || params?.userId) as number;
  const isCompletedWorkoutProgram = !!params?.completed;

  const [changeWorkoutStatus, { isLoading }] = useChangeWorkoutStatusMutation();
  const [skipWorkout, { isLoading: isLoadingSkip }] = useSkipWorkoutMutation();
  const { data: workout } = useGetWorkoutQuery(workoutId);

  const isInProgress = workout?.workoutStatus === IN_PROGRESS;

  const workoutSkipped = workout?.workoutStatus === SKIPPED;

  const onProgressWorkout = async () => {
    try {
      await changeWorkoutStatus({
        ...workout,
        description: "",
        workoutStatus: COMPLETED,
      }).unwrap();
    } catch (error) {
      console.error("Error progressing workout");
    }
  };

  const handleFinishWorkoutModal = () => {
    setIsShowFinishWorkoutModal(!isShowFinishWorkoutModal);
  };

  const handleSkipWorkoutModal = () => {
    setIsShowSkipWorkoutModal(!isShowSkipWorkoutModal);
  };

  const handleCheckStatusBeforeRequest = () => {
    handleFinishWorkoutModal();
  };

  const handleSkippedWorkoutRequest = async () => {
    await changeWorkoutStatus({
      ...workout,
      description: "",
      workoutStatus: SKIPPED,
    }).unwrap();

    await skipWorkout({
      workoutId: workout!.id,
      workoutProgramId: workout!.workoutProgramId,
    }).unwrap();
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showPreviousSessionDialog, setShowPreviousSessionDialog] =
    useState(false);

  const sanitisedPreviousWorkoutSession = (() => {
    let sanitised = DOMPurify.sanitize(workout?.previousWorkoutNotes ?? "");
    if (sanitised.endsWith(EXERCISE_FEEDBACK_SUFFIX)) {
      sanitised = sanitised.slice(0, -EXERCISE_FEEDBACK_SUFFIX.length);
    }
    return sanitised;
  })();

  const [totalExercises, setTotalExercises] = useState(0);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FinishWorkoutModal
        isShowFinishWorkoutModal={isShowFinishWorkoutModal}
        onProgressWorkout={onProgressWorkout}
        setShowFeedback={setShowFeedback}
        setIsShowFinishWorkoutModal={setIsShowFinishWorkoutModal}
      />

      <SkipWorkoutModal
        isShowSkipWorkoutModal={isShowSkipWorkoutModal}
        onSkip={handleSkippedWorkoutRequest}
        setIsShowSkipWorkoutModal={setIsShowSkipWorkoutModal}
      />

      <Card
        sx={{
          width: "100%",
          position: "relative",
          borderRadius: 0,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
          pt: 7,
          pb: 3,
          pl: 3,
          pr: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            type="button"
            onClick={goBack}
            sx={{
              minWidth: "auto",
              padding: 0,
              color: Colors.gray[100],
              "&:hover": {
                background: "none",
              },
              textTransform: "none",
            }}
          >
            <Icon
              sx={{
                fontSize: "16px !important",
                width: "16px",
                height: "16px",
              }}
            >
              arrow_back_ios
            </Icon>
            <Typography
              sx={{
                fontSize: "14px",
                ml: 0.5,
                color: Colors.gray[100],
                fontWeight: 500,
              }}
            >
              Return
            </Typography>
          </Button>

          <Box sx={{ display: "flex", gap: 1 }}>
            {sanitisedPreviousWorkoutSession && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    minWidth: "auto",
                    p: 1,
                    borderRadius: 1,
                  }}
                  onClick={() => setShowPreviousSessionDialog(true)}
                >
                  <Icon sx={{ color: Colors.gray[100], fontSize: "16px" }}>
                    history
                  </Icon>
                </Button>
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: Colors.gray[100],
                    mt: -0.5,
                    userSelect: "none",
                    textAlign: "center",
                    lineHeight: 1.2,
                  }}
                >
                  Last Session
                  <br />
                  Feedback
                </Typography>
              </Box>
            )}
            {workout?.description && (
              <Button
                sx={{
                  minWidth: "auto",
                  p: 1,
                  borderRadius: 1,
                }}
                onClick={() => setShowNotesDialog(true)}
              >
                <Icon sx={{ color: Colors.gray[100], fontSize: "16px" }}>
                  info
                </Icon>
              </Button>
            )}
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          {!!currentWorkout.dayNumber && (
            <Typography
              variant="h5"
              sx={{
                color: Colors.gray[100],
                fontWeight: 600,
                mb: 1,
              }}
            >
              Week {currentWorkout.weekNumber} - Session{" "}
              {currentWorkout.dayNumber}
            </Typography>
          )}
          {totalExercises > 0 && (
            <Typography
              sx={{
                color: Colors.gray[100],
                fontSize: 14,
              }}
            >
              {totalExercises} exercises
            </Typography>
          )}
        </Box>
      </Card>

      {workout && (
        <Feedback
          showFeedback={showFeedback}
          finishedWorkout={workout}
          workoutId={workoutId}
        />
      )}

      {workout?.description && (
        <Dialog
          open={showNotesDialog}
          onClose={() => setShowNotesDialog(false)}
          fullScreen
        >
          <DialogContent
            sx={{
              padding: 0,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flex: 1, overflow: "auto" }}>
              <WorkoutDetailsSessionNotes
                content={workout.description}
                onClose={() => setShowNotesDialog(false)}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {sanitisedPreviousWorkoutSession && (
        <Dialog
          open={showPreviousSessionDialog}
          onClose={() => setShowPreviousSessionDialog(false)}
          fullScreen
        >
          <DialogContent
            sx={{
              padding: 0,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flex: 1, overflow: "auto" }}>
              <WorkoutDetailsSessionNotes
                previousSession={sanitisedPreviousWorkoutSession}
                onClose={() => setShowPreviousSessionDialog(false)}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {workout && (
        <WorkoutList
          workout={workout}
          handleCheckStatusBeforeRequest={handleCheckStatusBeforeRequest}
          handleSkipWorkoutModal={handleSkipWorkoutModal}
          isLoadingSkip={isLoadingSkip}
          isInProgress={isInProgress}
          workoutSkipped={workoutSkipped}
          isCompletedWorkoutProgram={isCompletedWorkoutProgram}
          setTotalExercises={setTotalExercises}
        />
      )}
    </Box>
  );
};

export { WorkoutDetails };
